import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
// import { BASE_URL } from 'src/app/config/config';
import { map, catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2';
import { Tae } from '../../models/tae.model';
import * as uuid from 'uuid';
import { CurrencyPipe } from '@angular/common';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TaeService {

  API_URL: any = environment.ENDPOINTS;

  // adjustment: Tae;
  token: string;

  constructor(public http: HttpClient, public router: Router) {}

  cargar() {
    const url = `${this.API_URL.SALES}/taeproducts`; // BASE_URL + '/taeproducts';
    console.log('url: ', url);
    return this.http.get(url).pipe(
      map((res: any) => {
        console.log('res: ', res);
        return res;
      }),
      catchError(this.handleError<Tae[]>('Tiempo Aire', []))
    );
  }

  confirmarTransaccion(ajuste: Tae) {
    // console.log('confirmarTransaccion: ajuste: ' + JSON.stringify(ajuste));
    const url = `${this.API_URL.SALES}/adjustment/confirmation`; // BASE_URL + '/adjustment/confirmation';
    return this.http.post(url, ajuste).pipe(
      map((res: any) => {
        // console.log('confirmarTransaccion: res: ' + JSON.stringify(res));
        return res;
      }),
      catchError(this.handleError<Tae[]>('Tiempo Aire', []))
    );
  }

  realizarAjuste(ajuste: Tae) {
    // console.log('realizarAjuste: ajuste: ', ajuste);
    const url = `${this.API_URL.SALES}/adjustment`; // BASE_URL + '/adjustment';
    return this.http.post(url, ajuste).pipe(
      map((res: any) => {
        Swal.fire({
          title: 'Tiempo Aire!',
          text: 'El ajuste ha sido realizado.',
          type: 'success',
          confirmButtonColor: '#FF0000',
          confirmButtonText: 'Aceptar!'
        });
        return res;
      }),
      catchError(this.handleError<Tae[]>('Tiempo Aire', []))
    );
  }

  realizarAjuste2(ajuste: Tae) {
    // console.log('realizarAjuste: ajuste: ', ajuste);
    const url = `${this.API_URL.SALES}/adjustment`; // BASE_URL + '/adjustment';
    return new Promise((resolve, reject) => {
      this.http.post(url, ajuste).subscribe(
        data => {
          Swal.fire({
            title: 'Tiempo Aire!',
            text: 'El ajuste ha sido realizado.',
            type: 'success',
            confirmButtonColor: '#FF0000',
            confirmButtonText: 'Aceptar!'
          });
          resolve(data);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  realizarVentaTAE(tae: Tae) {
    console.log('realizarVentaTAE: tae: ', tae);
    const amount = tae.amount / 100;
    console.log('realizarVentaTAE: amount: ', amount);
    const url = `${this.API_URL.SALES}/tae-sale`; // BASE_URL + '/tae-sale';

    return new Promise((resolve, reject) => {
      // const headers = new HttpHeaders().set('Content-Type', 'application/json');
      this.http
        .post(url, {
          amountCents: tae.amount,
          phone: tae.phone,
          productTxnId: tae.txnId,
          uuid: uuid.v4(),
          clientVersion: 'V5'
        })
        .subscribe(
          res => {
            console.log('realizarVentaTAE: res: ', res);
            resolve(res);
          },
          err => {
            console.log('realizarVentaTAE 2: err: ', err);
            reject(err);
          }
        );
    });
  }

  realizarVentaTAE_(tae: Tae) {
    console.log('realizarVentaTAE: tae: ', tae);
    const amount = tae.amount / 100;
    console.log('realizarVentaTAE: amount: ', amount);
    const url = `${this.API_URL.SALES}/tae-sale`; // BASE_URL + '/tae-sale';

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'my-button btn-lg btn-block'
        // ,
        // cancelButton: 'btn btn-danger'
      },
      buttonsStyling: false
    });

    return this.http
      .post(url, {
        amountCents: tae.amount,
        phone: tae.phone,
        productTxnId: tae.txnId,
        uuid: uuid.v4(),
        clientVersion: 'V5'
      })
      .pipe(
        map((recarga: any) => {
          console.log('realizarVentaTAE: recarga: ', recarga);

          swalWithBootstrapButtons
            .fire({
              title: ``,
              text: ``,
              // type: "error",
              width: '430px',
              html: `
            <div class="modal-recarga" *ngIf="showModalSuccess">
              <div class="form-recarga-exitosa">
                <div class="row" style="border-bottom: 1px solid #333333;">
                      <div class="col-md-12">
                          <div class="avatar text-center">
                              <img src="assets/images/websale/succsess-icon.png" alt="Avatar">
                          </div>
                      </div>
                      <div class="col-md-12">
                          <h2 class="text-center login-title "
                              style="color: #FF0000; font-size: 40px;margin: 10px 0 25px; font-family: 'Dom Diagonal BT';">¡Tu
                              recarga está lista!</h2>
                      </div>
                      <div class="col-md-12">
                          <div class="row" style="border-bottom: 1px solid #333333;">
                              <div class="col">
                                  <div class="form-group" style="margin-bottom: 10px; font-family: 'Roboto Light'; font-size: 14px;">
                                      <p class="label-input text-center title-sction" style="margin: 0.5rem 0px;">Aprobación</p>
                                  </div>
                              </div>
                              <div class="col">
                                  <div class="form-group" style="margin-bottom: 10px; font-family: 'Roboto Light'; font-size: 14px;">
                                      <p class="label-input text-center title-sction" style="margin: 0.5rem 0px;">${recarga.approvalCode}</p>
                                  </div>
                              </div>
                          </div>
                          <div class="row" style="border-bottom: 1px solid #333333;">
                              <div class="col">
                                  <div class="form-group" style="margin-bottom: 10px; font-family: 'Roboto Light'; font-size: 14px;">
                                      <p class="label-input text-center title-sction" style="margin: 0.5rem 0px;">Referencia</p>
                                  </div>
                              </div>
                              <div class="col">
                                  <div class="form-group" style="margin-bottom: 10px; font-family: 'Roboto Light'; font-size: 14px;">
                                      <p class="label-input text-center title-sction" style="margin: 0.5rem 0px;">${recarga.janoRetrievalId}</p>
                                  </div>
                              </div>
                          </div>
                          <div class="row" style="border-bottom: 1px solid #333333;">
                              <div class="col">
                                  <div class="form-group" style="margin-bottom: 10px; font-family: 'Roboto Light'; font-size: 14px;">
                                      <p class="label-input text-center title-sction" style="margin: 0.5rem 0px;">Número Telefónico</p>
                                  </div>
                              </div>
                              <div class="col">
                                  <div class="form-group" style="margin-bottom: 10px; font-family: 'Roboto Light'; font-size: 14px;">
                                      <p class="label-input text-center title-sction" style="margin: 0.5rem 0px;">${recarga.phone}</p>
                                  </div>
                              </div>
                          </div>
                          <div class="row" style="border-bottom: 1px solid #333333;">
                              <div class="col">
                                  <div class="form-group" style="margin-bottom: 10px; font-family: 'Roboto Light'; font-size: 14px;">
                                      <p class="label-input text-center title-sction" style="margin: 0.5rem 0px;">Producto</p>
                                  </div>
                              </div>
                              <div class="col">
                                  <div class="form-group" style="margin-bottom: 10px; font-family: 'Roboto Light'; font-size: 14px;">
                                      <p class="label-input text-center title-sction" style="margin: 0.5rem 0px;">${recarga.productName}</p>
                                  </div>
                              </div>
                          </div>
                          <!-- div class="row" style="border-bottom: 1px solid #333333;">
                              <div class="col">
                                  <div class="form-group" style="margin-bottom: 10px; font-family: 'Roboto Light'; font-size: 14px;">
                                      <p class="label-input text-center title-sction" style="margin: 0.5rem 0px;">Saldo</p>
                                  </div>
                              </div>
                              <div class="col">
                                  <div class="form-group" style="margin-bottom: 10px; font-family: 'Roboto Light'; font-size: 14px;">
                                      <p class="label-input text-center title-sction" style="margin: 0.5rem 0px;">${recarga.taeBalanceLabel}</p>
                                  </div>
                              </div>
                          </div -->
                          <div class="row" style="border-bottom: 1px solid #333333;">
                              <div class="col">
                                  <div class="form-group" style="margin-bottom: 10px; font-family: 'Roboto Light'; font-size: 14px;">
                                      <p class="label-input text-center title-sction" style="margin: 0.5rem 0px;">Monto</p>
                                  </div>
                              </div>
                              <div class="col">
                                  <div class="form-group" style="margin-bottom: 10px; font-family: 'Roboto Light'; font-size: 14px;">
                                      <p class="label-input text-center title-sction" style="margin: 0.5rem 0px;">$ ${amount}</p>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
            </div>
                  `,
              confirmButtonColor: '#FF0000',
              showCancelButton: false,
              confirmButtonText: 'Realizar otra Recarga!!',
              cancelButtonText: 'No, cancel!',
              reverseButtons: true
            })
            .then(result => {
              if (result.value) {
                this.router.navigate(['/tae']);
              }
            });
          return recarga;
        }),
        catchError(this.handleError<Tae[]>('Tiempo Aire', []))
      );
  }

  /**
   * Maneja la operación Http que falla.
   * Permite a una operación continuar.
   * @param operation - Nombre de la operación que falló (Texto descriptivo)
   * @param result - Valor opciones que regresa el resultado como un Observable
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      // console.error('ESTE ES EL ERROR: ', error.error.message); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} Falló: ${error.error.message}`);
      // <small><b>Message: ${error.error.message}</b></small><br>
      // <small><b>AuthorizerResponseCode: ${error.error.authorizerResponseCode}</b></small><br>
      // <small><b>AuthorizerResponseMessage: ${error.error.authorizerResponseMessage}</b></small><br>

      Swal.fire({
        title: `${operation} Falló`,
        text: `${error.error.message}`,
        type: 'error',
        html: `<small><b>Error: ${error.error.error}</b></small><br>
               <small><b>Mensaje: ${error.error.message}</b></small><br>
              `,
        confirmButtonColor: '#FF0000',
        confirmButtonText: 'Aceptar!'
      });

      // Let the app keep running by returning an empty result.
      // return of(result as T);
      return null;
    };
  }
}
