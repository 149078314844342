<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<div id="main-wrapper">
  <jano-header></jano-header>

  <jano-sidebar></jano-sidebar>

  <!-- ============================================================== -->
  <!-- Page wrapper  -->
  <!-- ============================================================== -->
  <div class="page-wrapper">
    <!-- ============================================================== -->
    <!-- Container fluid  -->
    <!-- ============================================================== -->
    <div
      class="container-fluid"
      style="padding: 0px; margin-top: 5px; margin-left: 9px;"
    >
      <!--<jano-breadcrumbs></jano-breadcrumbs>-->

      <!-- ============================================================== -->
      <!-- Start Page Content -->
      <!-- ============================================================== -->

      <router-outlet></router-outlet>
      <!-- <session-expiration-alert [alertAt]="alertAt" [startTimer]="startTimer">
            </session-expiration-alert> -->

      <!-- <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-body">
                            <!- This is some text within a card block. ->
                            <button (click)="increase()">change alert at</button> {{alertAt}}
                            <br>
                            <button (click)="toggletimer()">toggle timer</button> {{startTimer}}
                            <h3 *ngIf="sessionTimer.remainSeconds$ | async; let time">Tiempo de Sesión Restante:
                                {{time}} segundos.</h3>
                        </div>
                    </div>
                </div>
            </div> -->
      <!-- ============================================================== -->
      <!-- End Page Content -->
      <!-- ============================================================== -->
    </div>
    <!-- ============================================================== -->
    <!-- End Container fluid  -->
    <!-- ============================================================== -->
  </div>
  <!-- ============================================================== -->
  <!-- End Page wrapper  -->
  <!-- ============================================================== -->
</div>

<div
  bsModal
  #childModal="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="dialog-child-name"
>
  <div class="modal-dialog modal-md">
    <div class="modal-content">
      <div class="modal-header">
        <h4 id="dialog-child-name" class="modal-title pull-left">
          Su sesión vá a expirar pronto!
        </h4>
        <button
          type="button"
          class="close pull-right"
          aria-label="Close"
          (click)="hideChildModal()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div
          class="idleMessage text-center m-3 p-3"
          style="font-size: 20px; font-weight: bold;"
        >
          {{ idleState }}
        </div>
        <div class="row mrgbtm">
          <div class="col-md-6 text-center">
            <button
              type="button"
              (click)="logout()"
              class="btn btn-danger btn-block text-uppercase btn-rounded"
            >
              Salir
            </button>
          </div>
          <div class="col-md-6 text-center">
            <button
              type="button"
              (click)="stay()"
              class="btn btn-inverse btn-block text-uppercase btn-rounded"
            >
              Continuar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
