import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
// import { Observable } from 'rxjs/Observable';
import { catchError, map } from 'rxjs/operators';
import { Customer } from '../../models/customer.model';
import { Item } from '../../models/catalogItems.model';
// import { BASE_URL } from 'src/app/config/config';
import Swal from 'sweetalert2';
import { CustomerSearch } from 'src/app/models/customerSearch';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CustomerService {

  API_URL: any = environment.ENDPOINTS;

  customer: Customer;
  token: string;

  constructor(public http: HttpClient, public router: Router) {
  }

  cargar(cliente: Customer) {
    const url = `${this.API_URL.SALES}/customer/filter`; // BASE_URL + '/customer/filter';
    return this.http.post(url, cliente).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(this.handleError<Customer[]>('Búsqueda de Clientes', [])),
    );
  }

  borrar(id: number): any {
    const url = `${this.API_URL.SALES}/customer/${id}`; // BASE_URL + '/customer/' + id;
    return this.http.delete(url).pipe(
      map(res => {
        // Swal.fire('Borrado!', 'El cliente ha sido borrado.', 'success');
        Swal.fire({
          title: 'Borrado!',
          text: 'El cliente ha sido borrado.',
          type: 'success',
          confirmButtonColor: '#FF0000',
          confirmButtonText: 'Aceptar!',
        });
        return res;
      }),
      catchError(this.handleError<Customer[]>('Eliminar Clientes', [])),
    );
  }

  guardar(cliente: Customer) {
    console.log('CustomerService -> guardar -> cliente', cliente);

    let url = `${this.API_URL.SALES}/customer`; // BASE_URL + '/customer';
    if (cliente.id > 0) {
      // actualizar

      url += '/' + cliente.id;
      console.log('CustomerService -> guardar -> url', url);
      return this.http.put(url, cliente).pipe(
        map((res: any) => {
          Swal.fire({
            title: 'Actualizado!',
            text: 'El cliente ' + cliente.name + ' ha sido actualizado.',
            type: 'success',
            confirmButtonColor: '#FF0000',
            confirmButtonText: 'Aceptar!',
          });
          return res;
        }),
        catchError(this.handleError<Customer[]>('Actualizar Cliente', [])),
      );
    } else {
      // crear

      return this.http.post(url, cliente).pipe(
        map((res: any) => {
          console.log('CustomerService -> guardar -> res', res);
          Swal.fire({
            title: 'Creado!',
            text: 'El cliente ' + cliente.name + ' ha sido creado.',
            type: 'success',
            confirmButtonColor: '#FF0000',
            confirmButtonText: 'Aceptar!',
          });
          return res;
        }),
        catchError(this.handleError<Customer[]>('Nuevo Cliente', [])),
      );
    }
  }

  obtener(id: number) {
    console.log('CustomerService: obtener: id: ', id);
    const url = `${this.API_URL.SALES}/customer/${id}`; // BASE_URL + '/customer/' + id;
    return this.http.get(url).pipe(
      map((res: any) => {
        console.log('CustomerService: obtener: cliente: ', res);
        return res;
      }),
      catchError(this.handleError<Customer[]>('Obtener Cliente', [])),
    );
  }

  actualizar(cliente: Customer) {
    const url = `${this.API_URL.SALES}/customer/${cliente.id}`; // BASE_URL + '/customer/' + cliente.id;
    return this.http.put(url, cliente).pipe(
      map((res: any) => {
        // Swal.fire(
        //   'Actualizado!',
        //   'El cliente ' + res.name + ' ha sido actualizado.',
        //   'success'
        // );
        Swal.fire({
          title: 'Actualizado!',
          text: 'El cliente ' + cliente.name + ' ha sido actualizado.',
          type: 'success',
          confirmButtonColor: '#FF0000',
          confirmButtonText: 'Aceptar!',
        });
        return res;
      }),
      catchError(this.handleError<Customer[]>('Actualizar Cliente', [])),
    );
  }

  buscar(customerSearch: CustomerSearch) {
    console.log('buscar: customerSearch: ', customerSearch);
    const url = `${this.API_URL.SALES}/customer/filter`; // BASE_URL + '/customer/filter';
    return this.http.post(url, customerSearch).pipe(
      map((res: any) => {
        console.log('buscar: res: ', res);
        return res;
      }),
      catchError(this.handleError<Customer[]>('Buscar Cliente', [])),
    );
  }

  buscarPorRedID(customerSearch: CustomerSearch) {
    console.log('buscarPorRedID: REdID: ', customerSearch.redId);
    const url = `${this.API_URL.SALES}/red-customer/${customerSearch.redId}`; // BASE_URL + '/red-customer/' + customerSearch.redId;
    return this.http.get(url).pipe(
      map((res: any) => {
        console.log('buscarPorRedID: Cliente: ', res);
        return res;
      }),
      catchError(this.handleError<Customer[]>('Buscar Cliente por RedID', [])),
    );
  }

  estatusDisponible() {
    const url = `${this.API_URL.SALES}/customer/available-status`; // BASE_URL + '/customer/available-status';
    return this.http.get(url).pipe(
      map((res: any) => {
        return res;
      }),
    );
  }

  estados(): Observable<Item[]> {
    const url = `${this.API_URL.SALES}/states`; // BASE_URL + '/states';
    return this.http.get(url).pipe(
      map((res: any) => {
        return res;
      }),
    );
  }

  municipios(id: number) {
    const url = `${this.API_URL.SALES}/state/${id}`; // BASE_URL + '/state/' + id;
    return this.http.get(url).pipe(
      map((res: any) => {
        return res;
      }),
    );
  }

  lineasDeNegocio() {
    const url = `${this.API_URL.SALES}/lineOfBusiness`; // BASE_URL + '/lineOfBusiness';
    return this.http.get(url).pipe(
      map((res: any) => {
        return res;
      }),
    );
  }

  obtenerCliente() {
    console.log('CustomerService: obtenerCliente');
    const url = `${this.API_URL.SALES}/customer`; // BASE_URL + '/customer';
    console.log('CustomerService: obtenerCliente: url: ', url);
    /*return this.http.get(url).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(this.handleError<Customer[]>('Obtener Cliente', []))
    );*/

    return new Promise((resolve, reject) => {
      // console.log(`getIndex: URL: ${this.APIURL.TASK}/getAuthors`);
      this.http.get(url).subscribe(
        customer => {
          resolve(customer);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  /**
   * Maneja la operación Http que falla.
   * Permite a una operación continuar.
   * @param operation - Nombre de la operación que falló (Texto descriptivo)
   * @param result - Valor opciones que regresa el resultado como un Observable
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      // console.error('ESTE ES EL ERROR: ', error.error.message); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} Falló: ${error.error.message}`);

      Swal.fire({
        title: `${operation} Falló`,
        text: `${error.error.message}`,
        type: 'error',
        confirmButtonColor: '#FF0000',
        confirmButtonText: 'Aceptar!',
      });

      // Let the app keep running by returning an empty result.
      // return of(result as T);
      return null;
    };
  }
}
