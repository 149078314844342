<!-- ============================================================== -->
<!-- Preloader - style you can find in spinners.css                 -->
<!-- ============================================================== -->

<div class="preloader" *ngIf="cargando">
  <div class="loader">
    <div class="loader__figure"></div>
    <p class="loader__label">Cargando...</p>
  </div>
</div>

<!-- <div class="row animated">
  <div class="col-12">
    <div class="card">
      <form #f="ngForm" ngNativeValidate (ngSubmit)="buscarPorRangoFecha()">
        <div class="card-body">
          <h4 class="card-title">Búsqueda TAE</h4>
          <h6 class="card-subtitle">Elija la <code>Fecha Inicial</code> y <code>Fecha Final</code></h6>
          <br>
          <div class="row">
            <div class="col-md-5">
              <div class="form-group">
                <div class="input-group">
                  <input class="form-control" placeholder="dd-mm-yyyy" id="fechaInicial" name="fechaInicial"
                    [(ngModel)]="fechaInicial" ngbDatepicker #fi="ngbDatepicker">
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary no-shadow" (click)="fi.toggle()" type="button">
                      <i class="fa fa-calendar"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="input-group">
                  <input class="form-control" placeholder="dd-mm-yyyy" id="fechaFinal" name="fechaFinal"
                    [(ngModel)]="fechaFinal" ngbDatepicker #ff="ngbDatepicker">
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary no-shadow" (click)="ff.toggle()" type="button">
                      <i class="fa fa-calendar"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div class="text-left">
                <button type="submit" class="btn waves-effect waves-light btn-rounded btn-danger"><i
                    class="fa fa-search">&nbsp;</i>Buscar</button>
              </div>
            </div>
          </div>
          <!- <div class="col-md-5">
            <div class="form-group">
              <div class="input-group">
                <input class="form-control" placeholder="dd-mm-yyyy" id="fechaFinal" name="fechaFinal"
                  [(ngModel)]="fechaFinal" ngbDatepicker #ff="ngbDatepicker">
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary no-shadow" (click)="ff.toggle()" type="button">
                    <i class="fa fa-calendar"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-2">
            <div class="text-left">
              <button type="submit" class="btn waves-effect waves-light btn-rounded btn-danger"><i
                  class="fa fa-search">&nbsp;</i>Buscar</button>
            </div>
          </div> ->
        </div>
      </form>
    </div>
  </div>
</div> -->
<div class="row animated" *ngIf="!cargando">
  <div class="col-12">
    <div class="card">
      <form #f="ngForm" ngNativeValidate (ngSubmit)="buscarPorRangoFecha()">
        <div class="card-body">
          <h4 class="card-title">Búsqueda TAE</h4>
          <h6 class="card-subtitle">
            Elija la <code>Fecha Inicial</code> y <code>Fecha Final</code>
          </h6>
          <br />
          <div class="row">
            <div class="col-md-4">
              <label for="fechaFinal">Fecha Inicial</label>
              <div class="btn-group bootstrap-select">
                <input
                  type="text"
                  class="form-control"
                  placeholder="dd/mm/yyyy"
                  id="fechaInicial"
                  name="fechaInicial"
                  [(ngModel)]="fechaInicial"
                  ngbDatepicker
                  #fi="ngbDatepicker"
                  required
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-outline-secondary no-shadow"
                    (click)="fi.toggle()"
                    type="button"
                  >
                    <i class="fa fa-calendar"></i>
                  </button>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <label for="fechaFinal">Fecha Final</label>
              <div class="btn-group bootstrap-select">
                <input
                  class="form-control"
                  placeholder="dd/mm/yyyy"
                  id="fechaFinal"
                  name="fechaFinal"
                  [(ngModel)]="fechaFinal"
                  ngbDatepicker
                  #ff="ngbDatepicker"
                  required
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-outline-secondary no-shadow"
                    (click)="ff.toggle()"
                    type="button"
                  >
                    <i class="fa fa-calendar"></i>
                  </button>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <label for="buscar">&nbsp;</label>
              <div class="text-left">
                <button
                  type="submit"
                  class="btn waves-effect waves-light btn-rounded btn-danger"
                >
                  <i class="fa fa-search">&nbsp;</i>Buscar
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<div class="row animated fadeIn" *ngIf="!cargando">
  <div class="col-12">
    <div class="card">
      <div class="card-body" *ngIf="taerArr.length > 0">
        <div class="row" style="width: 100%;">
          <div class="col-6 d-inline-block">
            <h3 class=" card-titil">
              Usuarios ( <small>{{ taerArr.length }}</small> )
            </h3>
          </div>
          <div class="col-6 text-right">
            <button
              type="button"
              (click)="descargarReportePorFiltros()"
              class="btn waves-effect waves-light btn-rounded btn-danger ml-2"
            >
              <i class="fa fa-file-pdf-o">&nbsp;</i>Descargar
            </button>
          </div>
        </div>

        <table class="table table-hover">
          <thead>
            <tr>
              <th class="col-md-auto">Fecha</th>
              <th class="col-md-auto">Autorización</th>
              <th class="col-md-auto">Fecha</th>
              <th class="col-md-auto">Producto</th>
              <th class="col-md-auto">Número Celular</th>
              <th class="col-md-auto">Monto</th>
              <th class="col-md-auto">Tipo de Operación</th>
              <th class="col-md-auto">Nombre cliente</th>
              <th class="col-md-auto">Tienda</th>
              <th class="col-md-auto">Estatus</th>
            </tr>
          </thead>
          <tbody>
            <tr
              [ngClass]="[!taer.authorizerRM ? 'red' : '']"
              *ngFor="let taer of taerArr | paginate: pageSize:pageNumber"
            >
              <td>{{ taer.createdAt }}</td>
              <td>{{ taer.approvalCode }}</td>
              <td>{{ taer.txnDateId }}</td>
              <td>{{ taer.productName }}</td>
              <td>{{ taer.account }}</td>
              <td>{{ taer.amount | currency }}</td>
              <td class="text-uppercase">{{ taer.operationTypeLabel }}</td>
              <td>{{ taer.customerName }}</td>
              <td>{{ taer.storeName }}</td>
              <td>{{ taer.txnStatusLabel }}</td>
            </tr>
          </tbody>
        </table>
        <mat-paginator
          showFirstLastButtons="true"
          [length]="taerArr.length"
          [pageSize]="pageSize"
          [pageSizeOptions]="pageSizeOptions"
          (page)="handlePage($event)"
        >
        </mat-paginator>
      </div>
    </div>
  </div>
</div>
