import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
// import { BASE_URL } from '../../config/config';
import { map, catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2';
import { Taer } from '../../models/taer.model';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TaerService {

  API_URL: any = environment.ENDPOINTS;

  constructor(public http: HttpClient, public router: Router) {}

  buscarPorRangoFecha(fInicial: string, fFinal: string) {
    console.log(
      'buscarPorRangoFecha: fInicial: ',
      fInicial,
      ', fFinal: ',
      fFinal
    );

    // const url = BASE_URL + '/billpocket/transactionsByDate/01102019/30102019';
    const url = `${this.API_URL.SALES}/transactionsByDate/${fInicial}/${fFinal}`; // BASE_URL + '/transactionsByDate/' + fInicial + '/' + fFinal;
    return this.http.get(url).pipe(
      map((res: any) => {
        console.log('buscarPorRangoFecha: res: ', res);
        return res;
      }),
      catchError(this.handleError<Taer[]>('Reporte TAE', []))
    );
  }

  descargarReportePorFiltros(fInicial: string, fFinal: string) {
    let urlArchivo = `${this.API_URL.SALES}/reports/file/`; // BASE_URL + '/reports/file/';
    console.log('descargarReportePorFiltros: urlArchivo: ', urlArchivo);

    const url = `${this.API_URL.SALES}/transactionsByDate/excel/${fInicial}/${fFinal}`; // BASE_URL + '/transactionsByDate/excel/' + fInicial + '/' + fFinal;
    console.log('descargarReportePorFiltros: url: ', url);
    return this.http.post(url, null).pipe(
      map((res: any) => {
        urlArchivo = urlArchivo + res.fileId;
        return urlArchivo;
      }),
      catchError(this.handleError<Taer[]>('Reporte TAE', []))
    );
  }

  /**
   * Maneja la operación Http que falla.
   * Permite a una operación continuar.
   * @param operation - Nombre de la operación que falló (Texto descriptivo)
   * @param result - Valor opciones que regresa el resultado como un Observable
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      // console.error('ESTE ES EL ERROR: ', error.error.message); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} Falló: ${error.error.message}`);

      Swal.fire({
        title: `${operation} Falló`,
        text: `${error.error.message}`,
        type: 'error',
        confirmButtonColor: '#FF0000',
        confirmButtonText: 'Aceptar!'
      });

      // Let the app keep running by returning an empty result.
      // return of(result as T);
      return null;
    };
  }
}
