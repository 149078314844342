import { NgModule } from '@angular/core';
import { NgbDateParserFormatter, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { DashboardComponent } from './dashboard/dashboard.component';
import { PagesComponent } from './pages.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSliderModule } from '@angular/material/slider';
import { MatChipsModule } from '@angular/material/chips';

// Rutas
import { PAGES_ROUTES } from './pages.routes';

// Módulos
import { SharedModule } from '../shared/shared.module';
import { MatDialogModule } from '@angular/material/dialog';
import { NgxPrintModule } from 'ngx-print';

// ng2-charts
import { ChartsModule } from 'ng2-charts';

// Interceptores
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptorService } from '../services/interceptors/auth-interceptor.service';

// Pipes
import { PaginatePipe } from '../pipes/paginate.pipe';

// Paginador
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatFormFieldModule, MatIconModule, MatInputModule, MatPaginatorIntl } from '@angular/material';
import { JanoMatPaginatorIntl } from '../pipes/JanoMatPaginatorIntl-es';

import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { MomentModule } from 'angular2-moment'; // optional, provides moment-style pipes for date formatting
import { ModalModule } from 'ngx-bootstrap/modal';

// Perfiles
import { NgxPermissionsModule } from 'ngx-permissions';

// Mantenimiento
import { UsersComponent } from './users/users.component';
import { UserComponent } from './users/user.component';
import { TddComponent } from './reports/tdd.component';
import { TaeComponent } from './sales/tae.component';
import { TaerComponent } from './reports/taer.component';
import { NgbDateParserFormatterEsMX } from '../config/dateformat';
import { PasrComponent } from './reports/pasr.component';
import { PasComponent } from './sales/pas.component';
import { TermsComponent } from './terms/terms/terms.component';
import { NoticeOfPrivacyComponent } from './terms/notice-of-privacy/notice-of-privacy.component';
import { PdsTermsComponent } from './terms/pds-terms/pds-terms.component';

@NgModule({
  declarations: [
    PagesComponent,
    PaginatePipe,
    DashboardComponent,
    UsersComponent,
    UserComponent,
    TddComponent,
    TaeComponent,
    TaerComponent,
    PasComponent,
    PasrComponent,
    TermsComponent,
    NoticeOfPrivacyComponent,
    PdsTermsComponent
  ],
  entryComponents: [],
  exports: [
    PagesComponent,
    DashboardComponent,
    MatFormFieldModule,
    MatIconModule,
    NgxPermissionsModule,
    MatInputModule
  ],
  imports: [
    SharedModule,
    BrowserModule,
    MatPaginatorModule,
    PAGES_ROUTES,
    FormsModule,
    ChartsModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatSliderModule,
    MatChipsModule,
    MatFormFieldModule,
    MatIconModule,
    NgbModule,
    // SessionExpirationAlert.forRoot({ totalMinutes: 0.5 })
    // SessionExpirationAlert.forRoot(),
    NgxPermissionsModule.forRoot(),
    NgIdleKeepaliveModule.forRoot(),
    MomentModule,
    ModalModule.forRoot(),
    MatDialogModule,
    NgxPrintModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true
    },
    {
      provide: MatPaginatorIntl,
      useClass: JanoMatPaginatorIntl
    },
    // {
    //   provide: SessionInteruptService,
    //   useClass: AppSessionInteruptService
    // },
    // { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n },
    {provide: NgbDateParserFormatter, useClass: NgbDateParserFormatterEsMX}
  ]
})
export class PagesModule {
}
