import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, map } from 'rxjs/operators';
import { Balance } from '../../models/balance.model';
// import { BASE_URL } from '../../config/config';
import Swal from 'sweetalert2';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BalanceService {

  API_URL: any = environment.ENDPOINTS;

  balance: Balance;
  token: string;

  constructor(public http: HttpClient, public router: Router) {
  }

  cargar(customerId: number) {
    const url = `${this.API_URL.SALES}/customer/${customerId}/balance`; // BASE_URL + '/customer/' + customerId + '/balance';
    return this.http.get(url).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(this.handleError<Balance[]>('Obtener Bolsas de Saldo', []))
    );
  }

  obtener() {
    // console.log('BalanceService: obtener: customerId: ', customerId);
    // console.log('BalanceService: obtener: balanceId: ', balanceId);
    const url = `${this.API_URL.SALES}/store/balance`; // BASE_URL + '/store/balance';
    /*return this.http.get(url).pipe(
      map((res: any) => {
        console.log('BalanceService: obtener: tienda: ', res);
        return res;
      }),
      catchError(this.handleError<Balance[]>('Obtener Bolsa de Saldo', []))
    );*/

    return new Promise((resolve, reject) => {
      // console.log(`getIndex: URL: ${this.APIURL.TASK}/getAuthors`);
      this.http.get(url).subscribe(
        balance => {
          resolve(balance);
        },
        err => {
          reject(err);
        }
      );
    });

  }

  /**
   * Maneja la operación Http que falla.
   * Permite a una operación continuar.
   * @param operation - Nombre de la operación que falló (Texto descriptivo)
   * @param result - Valor opciones que regresa el resultado como un Observable
   */
  private handleError<T>(operation = 'operation', result?: T) {

    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      // console.error('ESTE ES EL ERROR: ', error.error.message); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} Falló: ${error.error.message}`);

      Swal.fire({
        title: `${operation} Falló`,
        text: `${error.error.message}`,
        type: 'error',
        confirmButtonColor: '#FF0000',
        confirmButtonText: 'Aceptar!'
      });

      // Let the app keep running by returning an empty result.
      // return of(result as T);
      return null;
    };

  }
}
