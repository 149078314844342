<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<section id="wrapper" class="error-page">
    <div class="error-box">
        <div class="error-body text-center">
            <h1>404</h1>
            <h3 class="text-uppercase">Página no encontrada !</h3>
            <p class="text-muted m-t-30 m-b-30">PARECES ESTAR TRATANDO DE ENCONTRAR TU CAMINO A CASA</p>
            <a routerLink="/dashboard" class="btn btn-info btn-rounded waves-effect waves-light m-b-40">Regresar</a> </div>
        <footer class="footer text-center">© {{ anio }} Admin Pro.</footer>
    </div>
</section>
<!-- ============================================================== -->
<!-- End Wrapper -->
<!-- ============================================================== -->