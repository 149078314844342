<!-- ============================================================== -->
<!-- Start Page Content -->
<!-- ============================================================== -->
<a id="top-page"></a>
<form #f="ngForm" ngNativeValidate (keydown.enter)="$event.preventDefault()">
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <div class="d-flex">
            <div>
              <div
                class="card-title"
                style=" font-family: 'Dom Diagonal BT'; font-size: 40px;color: #333333"
              >
                Tiempo Aire
              </div>
            </div>
            <div class="ml-auto saldo" style="color: #FF0000; font-size: 30px">
              Mi Saldo: {{ saldo.balanceLabel }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <!--      <form #f="ngForm" ngNativeValidate (keydown.enter)="$event.preventDefault()">-->
        <div class="card-body">
          <div class="titulo_recarga" style="margin-left: 35px;">
            <div class="recarga" style="width: 20%; text-align: center;">
              Realizar una Recarga
            </div>
            <div>
              <hr style="margin-left: 0%;width: 20%;background-color: red;"/>
            </div>
          </div>
          <hr style="background-color: #F2F2F2;"/>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <span class="round">1</span>
                <label class="p-l-10 title-sction" for="numInt"
                >Selecciona una compañía telefónica</label
                >
              </div>
            </div>
            <div class="col-md-12">
              <div class="row">
                <div class="col" *ngFor="let ta of taes">
                  <div class="ribbon-wrapper card" style="margin-bottom: 0px;">
                    <div class="p-l-10-no">
                      <p class="text-center" style="height: 70px;">
                        <a
                          href="javascript:void(0)"
                          (click)="seleccionarRadio(ta)"
                        >
                          <img
                            src="{{ ta.image }}"
                            width="105px"
                            alt="{{ ta.image }}"
                            (click)="cargarMontos(ta)"
                          />
                        </a>
                      </p>
                      <p class="icheck-list text-center">
                        <input
                          type="radio"
                          class="check"
                          id="mn-{{ ta.txnId }}"
                          name="minimal-radio"
                          value="{{ ta.txnId }}"
                          [(ngModel)]="tae.txnId"
                          (click)="cargarMontos(ta)"
                          checked="checked"
                        />
                        <label for="mn-{{ ta.txnId }}">&nbsp;</label>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr/>
          <div class="row">
            <div class="col-md-5">
              <div class="form-group">
                <span class="round">2</span>
                <label class="p-l-10 title-sction" for="numInt"
                >Ingresa el número telefónico (10 dígitos):</label
                >
              </div>
            </div>
            <div class="col-md-1 text-center">
              <span class="lstick" style=""></span>
            </div>
            <div class="col-md-5">
              <div class="form-group">
                <span class="round0"></span>
                <label class="p-10 title-sction" for="numInt"
                >Confirma el número telefónico (10 dígitos):</label
                >
              </div>
            </div>
            <div class="col-md-1"></div>
          </div>
          <div class="row">
            <div class="col-md-5">
              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <input
                      type="text"
                      [(ngModel)]="tae.phone1"
                      class="tel-number-field"
                      name="tel_no_1"
                      value=""
                      maxlength="10"
                      required
                      autofocus
                      (paste)="onPaste($event)"
                      (copy)="onPaste($event)"
                      (blur)="validaTextos()"
                      (keyup)="validaTextos()"
                      style="width: 100%;font-size: 1.5em; stroke: 1px #333333; border-radius: 5px;"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-1 text-center">
              <span class="lstick"></span>
            </div>
            <div class="col-md-5">
              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <input
                      type="text"
                      [(ngModel)]="tae.phone2"
                      class="tel-number-field"
                      name="tel_no_11"
                      value=""
                      maxlength="10"
                      (paste)="onPaste($event)"
                      (copy)="onPaste($event)"
                      (blur)="validaTextos()"
                      (keyup)="validaTextos()"
                      style="width: 100%;font-size: 1.5em; stroke: 1px #333333; border-radius: 5px;"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-1">
              <img
                *ngIf="showImgCorrecto"
                src="../../../assets/images/websale/ok-icon.png"
              />
            </div>
          </div>
          <hr/>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <span class="round">3</span>
                <label class="p-l-10 title-sction" for="numInt"
                >Selecciona el monto a recargar</label
                >
              </div>
            </div>
            <div class="col-md-12">
              <div class="card">
                <div class="card-body">
                  <!-- <h4 class="card-title">Radio Buttons(Reactive Forms)</h4> -->
                  <form [formGroup]="radioGroupForm">
                    <div
                      class="btn-group btn-group-toggle"
                      ngbRadioGroup
                      name="radioBasic"
                      formControlName="model"
                      style="flex-wrap: wrap"
                    >
                      <div *ngFor="let aList of filteredTae.amountList">
                        <label
                          ngbButtonLabel
                          class="btn btn-outline-primary waves-effect waves-light monto"
                          style="border-color: #000000; margin-right: 6px;"
                        >
                          <input
                            ngbButton
                            type="radio"
                            [value]="aList"
                            autocomplete="off"
                          />
                          <span style="color: #000000; font-size: 1.5em;">{{
                            aList
                            }}</span>
                        </label>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <hr/>
          <div class="col-md-12 text-right">
            <div class="row">
              <div class="col-9"></div>
              <div class="col-3">
                <button
                  class="my-button btn-lg btn-block"
                  style="font-family: 'Dom Diagonal BT';  font-size: 30px; cursor: pointer;"
                  [disabled]="disabledmode ? 'disabled' : ''"
                  (click)="realizarVentaTAE(f)"
                >
                  Recargar
                </button>
              </div>
            </div>
          </div>
        </div>
        <!--      </form>-->
      </div>
    </div>
  </div>
</form>

<div id="demo" class="modal-recarga2" style="display: none;">
  <div class="form-recarga-exitosa">
    <div class="row" style="border-bottom: 1px solid #333333;">
      <div class="col-md-12">
        <div class="avatar text-center">
          <img src="assets/images/websale/succsess-icon.png" alt="Avatar"/>
        </div>
      </div>
      <div class="col-md-12">
        <h2
          class="text-center login-title "
          style="color: #FF0000; font-size: 40px;margin: 10px 0 25px; font-family: 'Dom Diagonal BT';"
        >
          ¡Tu recarga está lista!
        </h2>
      </div>
      <div class="col-md-12">
        <div class="row" style="border-bottom: 1px solid #333333;">
          <div class="col">
            <div
              class="form-group"
              style="margin-bottom: 10px; font-family: 'Roboto Light'; font-size: 14px;"
            >
              <p
                class="label-input text-center title-sction"
                style="margin: 0.5rem 0px;"
              >
                Aprobación
              </p>
            </div>
          </div>
          <div class="col">
            <div
              class="form-group"
              style="margin-bottom: 10px; font-family: 'Roboto Light'; font-size: 14px;"
            >
              <p
                class="label-input text-center title-sction"
                style="margin: 0.5rem 0px;"
              >
                {{ recarga.approvalCode }}
              </p>
            </div>
          </div>
        </div>
        <div class="row" style="border-bottom: 1px solid #333333;">
          <div class="col">
            <div
              class="form-group"
              style="margin-bottom: 10px; font-family: 'Roboto Light'; font-size: 14px;"
            >
              <p
                class="label-input text-center title-sction"
                style="margin: 0.5rem 0px;"
              >
                Referencia
              </p>
            </div>
          </div>
          <div class="col">
            <div
              class="form-group"
              style="margin-bottom: 10px; font-family: 'Roboto Light'; font-size: 14px;"
            >
              <p
                class="label-input text-center title-sction"
                style="margin: 0.5rem 0px;"
              >
                {{ recarga.janoRetrievalId }}
              </p>
            </div>
          </div>
        </div>
        <div class="row" style="border-bottom: 1px solid #333333;">
          <div class="col">
            <div
              class="form-group"
              style="margin-bottom: 10px; font-family: 'Roboto Light'; font-size: 14px;"
            >
              <p
                class="label-input text-center title-sction"
                style="margin: 0.5rem 0px;"
              >
                Número Telefónico
              </p>
            </div>
          </div>
          <div class="col">
            <div
              class="form-group"
              style="margin-bottom: 10px; font-family: 'Roboto Light'; font-size: 14px;"
            >
              <p
                class="label-input text-center title-sction"
                style="margin: 0.5rem 0px;"
              >
                {{ recarga.phone }}
              </p>
            </div>
          </div>
        </div>
        <div class="row" style="border-bottom: 1px solid #333333;">
          <div class="col">
            <div
              class="form-group"
              style="margin-bottom: 10px; font-family: 'Roboto Light'; font-size: 14px;"
            >
              <p
                class="label-input text-center title-sction"
                style="margin: 0.5rem 0px;"
              >
                Producto
              </p>
            </div>
          </div>
          <div class="col">
            <div
              class="form-group"
              style="margin-bottom: 10px; font-family: 'Roboto Light'; font-size: 14px;"
            >
              <p
                class="label-input text-center title-sction"
                style="margin: 0.5rem 0px;"
              >
                {{ recarga.productName }}
              </p>
            </div>
          </div>
        </div>
        <div class="row" style="border-bottom: 1px solid #333333;">
          <div class="col">
            <div
              class="form-group"
              style="margin-bottom: 10px; font-family: 'Roboto Light'; font-size: 14px;"
            >
              <p
                class="label-input text-center title-sction"
                style="margin: 0.5rem 0px;"
              >
                Monto
              </p>
            </div>
          </div>
          <div class="col">
            <div
              class="form-group"
              style="margin-bottom: 10px; font-family: 'Roboto Light'; font-size: 14px;"
            >
              <p
                class="label-input text-center title-sction"
                style="margin: 0.5rem 0px;"
              >
                $ {{ amount }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <button
    id="printRecarga"
    class="my-button btn-lg btn-block"
    style="font-family: 'Dom Diagonal BT'; font-size: 30px; cursor: pointer;"
    printSectionId="demo"
    ngxPrint
  ></button>
</div>
