import { Component, OnInit } from '@angular/core';
// import { Observable } from 'rxjs/Observable';
import { User } from 'src/app/models/user.model';
import { Clock } from '../../models/clock.model';
import { ClockService } from '../../services/clock/clock.service';
import { UserService } from '../../services/user/user.service';
import { Observable } from 'rxjs';
import { CustomerService } from '../../services/customer/customer.service';
import { Customer } from '../../models/customer.model';

//import {DashboardComponent} from '../../pages/dashboard/dashboard.component';

@Component({
  selector: 'jano-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  user: User;
  // FECHA ACTUAL
  data$: Observable<Clock>;
  hour: string;
  minutes: string;
  day: string;
  date: string;
  ampm: string;
  seconds: string;
  showMiniLogo: boolean = false;
  showLargeLogo: boolean = true;
  cliente: Customer;

  now: string;

  constructor(
    public service: UserService,
    public clockService: ClockService,
    public customerService: CustomerService
  ) {
    /*this.data$ = this.clockService.getClockData();
    this.data$.subscribe(clock => {
      this.hour = clock.hour;
      this.minutes = clock.minutes;
      this.day = clock.dayofweek;
      this.date = clock.dayteandmonth;
      this.ampm = clock.ampm;
      this.seconds = clock.second;
    });*/

    /*setInterval(() => {
      this.now = new Date();
    }, 1);*/

    console.log('ngOnInit: Ingresando A HEADER...');
    // const permissions = NgxPermissionsService..getPermissions();
    // setInterval(this.myTimer, 1000);
  }

  async ngOnInit() {
    //this.cliente = this.dasboard.cliente;
    this.user = this.service.user; // debugger;
    console.log('HeaderComponent: ngOnInit: user: ', this.user);

    await this.obtener();
    console.log('HeaderComponent: ngOnInit: cliente: ', this.cliente);

  }

  /*myTimer() {
    var months = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio",
      "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
    let d = new Date();

    let dd = d.getDate();

    let year = d.getFullYear();
    //let mm = d.getMonth() + 1; //January is 0!

    let mm = months[d.getMonth()];
    let yyyy = d.getFullYear();

    let dia = (dd.toString());
    if (dd < 10) {
      dia = '0' + dd.toString();
    }
    let t = d.toLocaleTimeString();

    this.now = dia + ' de ' + mm + ' de ' + yyyy + ' ' + t;
    console.log('HeaderComponent: ngOnInit: now: ', this.now);

    // document.getElementById("timer-header").innerHTML = fullDate;
  }*/

  async obtener() {
    this.cliente = await this.customerService.obtenerCliente();
    console.log('HeaderComponent: obtener: cliente: ', this.cliente);

    /*return this.customerService.obtenerCliente().subscribe(cliente => {
      this.cliente = cliente;
      // this.obtenerSaldo();
    });*/
  }

  hamburguesaClick() {
    this.showLargeLogo = !this.showLargeLogo;
    this.showMiniLogo = !this.showMiniLogo;
  }
}
