import { Component, OnInit } from '@angular/core';
import Swal from "sweetalert2";
import { Router } from '@angular/router';

@Component({
  selector: 'jano-notice-of-privacy',
  templateUrl: './notice-of-privacy.component.html',
  styleUrls: ['./notice-of-privacy.component.css']
})
export class NoticeOfPrivacyComponent implements OnInit {

  constructor(
    public router: Router
  ) {
  }

  ngOnInit() {
    this.avisoPrivacidad().then(() => console.log('Aviso de privacidad'));
  }

  async avisoPrivacidad() {
    // const respuesta: any = await this.service.avisoPrivacidad();

    /*if (respuesta.accepted === false) {*/
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'my-button btn-lg btn-block',
        cancelButton: 'my-button btn-lg btn-block',
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: ``,
        text: ``,
        // type: "error",
        width: '992px',
        html: `
          <div class="modal-pago" *ngIf="showModalSuccess">
            <div class="form-pago-exitosa">
              <div class="row" style="border-bottom: 1px solid #333333;">
                <div class="col-md-12">
                  <div class="avatar text-center">
                    <img src="assets/images/websale/succsess-icon.png" alt="Avatar">
                  </div>
                </div>
                <div class="col-md-12">
                  <h2 class="text-center login-title"
                    style="color: #FF0000; font-size: 40px; margin: 10px 0 0px; font-family: 'Dom Diagonal BT';">¡AVISO DE PRIVACIDAD INTEGRAL!</h2>
                    <h2 class="text-center login-title"
                    style="color: #FF0000; font-size: 40px; margin: 10px 0 0px; font-family: 'Dom Diagonal BT';">SÍ COBRO TOTAL</h2>
                </div>
                <!--<div class="col-md-12"><div class="row"></div></div>-->
                <div class="row" style="font-weight: 200;">
                  <div class="col-12">
                    <div class="card">
                      <div class="card-body">
                        <p style="font-size: 11px; text-align: justify">
                            En cumplimiento de la Ley Federal de Protección de Datos Personales en Posesión de Particulares (en adelante
                            la “Ley”), su Reglamento y otras disposiciones aplicables, y con el objeto de que pueda decidir de manera
                            informada sobre el tratamiento que podemos dar a sus datos personales, ponemos a su disposición este
                            documento que proporciona información sobre el uso de sus datos personales cuando utiliza Sí Cobro Total.
                        </p>
                        <h4 class="card-title" style="font-size: 11px; text-align: justify;" id="2">¿Quién es el responsable de sus datos personales?</h4>
                        <p style="font-size: 11px; text-align: justify">
                            Las siguientes entidades son responsables individuales de los datos personales de sus respectivos usuarios de
                            los servicios de Sí Cobro Total:<br>
                          <ul style="font-size: 11px; text-align: left;">
                            <li>Grupo Zorro Abarrotero, S. de R.L. de C.V</li>
                            <li>Flecha Abarrotera, S.A. de C.V</li>
                            <li>El Cazador Comercial Abarrotero, S.A. de C.V.</li>
                          </ul>
                        </p>
                        <p style="font-size: 11px; text-align: justify">
                            La entidad específica que tratará sus datos personales (en adelante, el “Responsable” o “Grupo Zorro”), depende
                            de la tienda en la que haya solicitado su acceso al cobro con tarjeta a través de un tercero, compra de bolsa de
                            saldo en las diferentes tiendas de Grupo Zorro para el pago de servicios y venta para recargas telefónicas en la
                            aplicación de Sí Cobro Total. Nuestra dirección para oír y recibir notificaciones es Avenida Vialidad de la Barranca,
                            Número 6, Colonia Ex Hacienda Jesús del Monte, Huixquilucan, C.P. 52787, Estado de México, México.
                        </p>
                        <h4 class="card-title" style="font-size: 11px; text-align: justify;" id="4">¿Qué datos usamos y podemos solicitar?</h4>
                        <p style="font-size: 11px; text-align: justify">
                            Para cumplir con las finalidades descritas en este aviso, Grupo Zorro recaba o puede recabar las siguientes categorías de datos personales:
                            <ol style="font-size: 11px; text-align: left;">
                                <li>Datos de identificación y de contacto;</li>
                                <li>Datos financieros.</li>
                            </ol>
                        </p>
                        <h4 class="card-title" style="font-size: 11px; text-align: justify;" id="5">¿Solicitamos datos sensibles?:</h4>
                        <p style="font-size: 11px; text-align: justify">
                            Grupo Zorro no solicita datos personales sensibles para las finalidades enumeradas en el siguiente apartado.
                            Como usuario de los servicios de Sí Cobro Total, usted debe abstenerse de proporcionar ese tipo de información
                            a Grupo Zorro
                        </p>
                        <p style="font-size: 11px; text-align: justify">
                            Finalidades del tratamiento de datos personales
                        </p>
                        <p style="font-size: 11px; text-align: justify">
                            Grupo Zorro podrá tratar sus datos personales para las siguientes finalidades:
                        </p>
                        <h4 class="card-title" style="font-size: 11px; text-align: justify;" id="6"><u>Finalidades Primarias:</u></h4>
                        <p style="font-size: 11px; text-align: justify">
                            <ol style="font-size: 11px; text-align: left;">
                                <li>Gestión de altas de usuarios en el sistema de Sí Cobro Total.</li>
                                <li>Control y actualización de cuentas de usuarios en el sistema de Sí Cobro Total.</li>
                                <li>Permitirle los servicios de Sí Cobro Total: el pago de servicios, recarga de tiempo aire y cobro con tarjeta.
                                    Otorgarle la prestación de servicio de cobro por medio de tarjeta electrónica de vales de despensa.</li>
                            </ol>
                        </p>
                        <h4 class="card-title" style="font-size: 11px; text-align: justify;" id="6"><u>Finalidades Secundarias:</u></h4>
                        <p style="font-size: 11px; text-align: justify">
                            No existen.
                        </p>
                        <h4 class="card-title" style="font-size: 11px; text-align: justify;" id="7">Transferencias de datos personales</h4>
                        <p style="font-size: 11px; text-align: justify">
                            Sus datos personales podrán ser transferidos dentro de los Estados Unidos Mexicanos (“<u>México</u>”) o hacia el
                            extranjero, a las siguientes categorías de destinatarios y para las finalidades identificadas:</p>
                        <p style="font-size: 11px; text-align: justify">
                            <ol style="font-size: 11px; text-align: left;">
                                <li><b>Hacia nuestra sociedad matriz y/o sociedades controladoras, subsidiarias y/o afiliadas, y/o cualquier
                                    sociedad del mismo grupo de Grupo Zorro que opere bajo los mismos procesos y políticas internas,</b> con finalidades de
                                     gestión centralizada de la información. Usted puede solicitar más información a través de
                                    la siguiente cuenta de correo electrónico: <b>datospersonales@asezor.com</b></li>
                                <li><b>Terceros prestadores de servicios,</b>para el cumplimiento y mantenimiento de la relación jurídica existente
                                    entre el Titular y el Responsable,</li>
                            </ol>
                        </p>
                        <p style="font-size: 11px; text-align: justify">
                            Sus datos personales no serán transferidos a terceros sin su consentimiento, salvo las excepciones previstas en
                            el artículo 37 de la Ley y en todo caso cumpliendo las condiciones previstas por en el artículo 17 del Reglamento
                            de la Ley.
                        </p>

                        <h4 class="card-title" style="font-size: 11px; text-align: justify;" id="7">Derechos ARCO</h4>
                        <p style="font-size: 11px; text-align: justify">
                            La Ley regula los Derechos ARCO con que usted cuenta como titular de datos personales. Estos derechos
                            comprenden:</p>
                        <p style="font-size: 11px; text-align: justify">
                            <ol style="font-size: 11px; text-align: left;">
                                <li><b>Acceso:</b> el derecho de conocer qué datos personales tratamos sobre Usted, así como información relativa a
                                    las condiciones y generalidades del tratamiento.</li>
                                <li><b>Rectificación:</b> el derecho de solicitar en todo momento la rectificación de sus datos que resulten inexactos o
                                    incompletos.</li>
                                <li><b>Cancelación:</b> el derecho a que Grupo Zorro cese el tratamiento de sus datos personales, a partir de un
                                    bloqueo y su posterior supresión. </li>
                                <li><b>Oposición:</b> el derecho a oponerse, por causa legítima, al tratamiento de sus datos personales.</li>
                            </ol>
                        </p>
                        <p style="font-size: 11px; text-align: justify">
                         Para ejercer cualquiera de los Derechos ARCO, Usted deberá presentar una solicitud dirigida a nuestro
                         Departamento de Datos Personales, a través de cualquiera de los siguientes canales:</p>
                            <ol style="font-size: 11px; text-align: left;" type="a">
                                <li>Enviando una solicitud al correo electrónico <b>datospersonales@asezor.com</b>, o</li>
                                <li>Enviando una solicitud debidamente firmada a la dirección Avenida Vialidad de la Barranca, Número 6,
                                    Colonia Ex Hacienda Jesús del Monte, Huixquilucan, C.P. 52787, Estado de México, México.</li>
                            </ol>
                        <p style="font-size: 11px; text-align: justify">
                        La solicitud deberá contener o estar acompañada de:</p>        
                                <ol style="font-size: 11px; text-align: left;" type="a">
                                    <li>Su nombre y domicilio completos, u otro medio para comunicar la respuesta a su solicitud,</li>
                                    <li>Copia de un documento que acredite su identidad y en su caso, la representación legal si alguien ejerce
                                        el derecho en su nombre,</li>
                                    <li>Una descripción clara y precisa del Derecho ARCO que desea ejercer y de los datos personales
                                        relacionados con su solicitud; y</li>
                                    <li>En su caso, cualquier otra información o documento que nos ayude a localizar sus datos personales</li>
                                </ol>
                        <p style="font-size: 11px; text-align: justify">
                            Grupo Zorro responderá a su solicitud dentro de los 20 (veinte) días hábiles siguientes a la fecha en que sea
                            enviada y recibida. Si la solicitud resulta procedente, Grupo Zorro la hará efectiva dentro de los 15 (quince) días
                            hábiles siguientes a la fecha en que comunique la respuesta. En caso de que la información y/o documentación
                            proporcionados en su solicitud resulten incompletos, erróneos y/o insuficientes, o bien, no se acompañen los
                            documentos necesarios para acreditar su identidad o la representación legal correspondiente, Grupo Zorro
                            solicitará la corrección y subsanación de las deficiencias para poder dar trámite a su solicitud. Usted contará con
                            10 (diez) días hábiles para atender el requerimiento y corrección de la solicitud; en caso contrario ésta se tendrá
                            por no presentada.</p>  
                        <p style="font-size: 11px; text-align: justify">
                            El uso de medios electrónicos para el ejercicio de los Derechos ARCO autoriza a Grupo Zorro para dar respuesta
                            a la solicitud correspondiente a través del mismo medio, salvo que Usted indique otro medio de contacto en su
                            solicitud, de forma clara y expresa.</p>  
                        <p style="font-size: 11px; text-align: justify">
                            El derecho de cancelación no es absoluto. Por favor tome en cuenta que Grupo Zorro debe conservar información
                            para cumplir con diversas obligaciones legales y que para hacerlo puede compartir sus datos personales con
                            otras entidades u organismos. En tales casos, es posible que el derecho de cancelación deba solicitarse ante la
                            entidad que recibió sus datos personales.</p>  
                        <h4 class="card-title" style="font-size: 11px; text-align: justify;" id="7">¿Quiere revocar su consentimiento o limitar el uso de sus datos?</h4>
                            <p style="font-size: 11px; text-align: justify">
                                En algunos casos, Usted puede revocar el consentimiento para el tratamiento de sus datos personales; sin
                            embargo, esta revocación no puede tener efectos retroactivos, es decir, no puede afectar a situaciones, trámites
                            o transferencias realizadas antes de la revocación de su consentimiento; así como tampoco en los casos en que
                            dicha revocación suponga la imposibilidad de cumplir con obligaciones derivadas de una relación jurídica vigente
                            entre Usted y Grupo Zorro, o suponga el incumplimiento de disposiciones generales de orden público que
                            establezcan la obligación de mantener el tratamiento de sus datos personales durante determinado período.</p>
                        <p style="font-size: 11px; text-align: justify">
                            Si usted revoca su consentimiento y dicha revocación es procedente, Grupo Zorro dejará de tratar sus datos
                            personales para las finalidades indicadas en este Aviso de Privacidad que resulten procedentes o expresamente
                            solicitadas. Sin perjuicio de lo anterior, la revocación de su consentimiento no podrá tener por objeto garantizado
                            que los terceros que han tenido acceso a sus datos personales dejen de tratarlos de inmediato, ni en el futuro,
                            porque ellos pueden usar su información para finalidades propias y bajo sus propios avisos de privacidad.</p>  
                         <p style="font-size: 11px; text-align: justify">
                            Usted podrá enviar su solicitud de revocación de consentimiento al correo electrónico
                            <b>datospersonales@asezor.com</b> siguiendo las instrucciones aplicables al ejercicio de los Derechos ARCO.</p>  
                        <h4 class="card-title" style="font-size: 11px; text-align: justify;" id="7">¿Cambios a nuestro Aviso de Privacidad?</h4>
                            <p style="font-size: 11px; text-align: justify">
                                Grupo Zorro podrá modificar, actualizar, extender o de cualquier otra forma cambiar el contenido y alcance de
                            este Aviso de Privacidad, en cualquier momento y bajo nuestra completa discreción. En todo momento, podrá
                            solicitar la última versión de este Aviso de Privacidad a nuestro Departamento de Datos Personales, a través de
                            la dirección electrónica: <b>datospersonales@asezor.com</b> Cuando resulte legalmente procedente y contemos con
                            su correo electrónico, Grupo Zorro podrá comunicar cambios al presente Aviso de Privacidad a través de ese
                            medio de comunicación o a través de la aplicación mediante la cual se puede acceder a los servicios de Sí Cobro
                            Total.</p>
                        <p style="font-size: 11px; text-align: justify">
                            El Aviso de Privacidad que regule el tratamiento de sus datos personales será el que aparezca publicado en
                            nuestra página web e identifique el tipo de relación que usted tiene con Grupo Zorro. Podrá acceder a la última
                            versión de este Aviso de Privacidad a través de https://zorroabarrotero.com.mx/, sección Avisos de Privacidad.</p>
                        <p style="font-size: 11px; text-align: justify">
                            Si ha tenido acceso a este Aviso de Privacidad por medios electrónicos o si desea comunicar su negativa para el
                            tratamiento de tus datos para finalidades secundarias, puede comunicar esta decisión en cualquier momento a
                            través de los medios establecidos para el ejercicio de sus derechos ARCO.</p>
                        <h4 class="card-title" style="font-size: 11px; text-align: justify;" id="7">Dudas o aclaraciones</h4>
                            <p style="font-size: 11px; text-align: justify">
                                En caso de tener dudas o aclaraciones sobre el presente Aviso de Privacidad, puede contactar a nuestro
                            Departamento de Datos Personales a través del correo electrónico datospersonales@asezor.com.</p>                
                      </div>
                      <div style="font-size: 10px; text-align: justify; margin-left: 22px; font-weight: 200;">
                        Fecha de actualización: <b>7 de julio de 2022
                      </div>
                    </div>
                  </div>
              </div>
                <div class="col-md-12"><div class="row"></div></div>
              </div>
            </div>
          </div>
          `,
        confirmButtonColor: '#FF0000',
        showCancelButton: false,
        confirmButtonText: 'He leído y acepto!',
        cancelButtonText: 'Regresar!',
        reverseButtons: true,
        allowOutsideClick: false,
      })
      .then(async (result) => {
        // if (result.value) {
          // const respuesta: any = await this.service.aceptarTerminos();
          // } else {
          this.router.navigateByUrl('/tae');
        // }
      })
    /*}*/
  }

}
