import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// PERFILES
import { NgxPermissionsModule } from 'ngx-permissions';

// Rutas
import { APP_ROUTES } from './app.routes';

// Módulos
import { PagesModule } from './pages/pages.module';

// Servicios
import { ServiceModule } from './services/service.module';

// Componentes
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './login/register.component';
import { CreatePasswordComponent } from './login/create-password.component';
import { RecoverPasswordComponent } from './login/recover-password.component';
import { RecoverPasswordRequestComponent } from './login/recover-password-request.component';
import { ChangePasswordComponent } from './login/change-password.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoadingScreenService } from './services/loading-screen.service';
import { LoadingScreenComponent } from './loading-screen/loading-screen.component';
import { LoadingScreenInterceptor } from './helpers/loading.interceptor';
import { SpinnerComponent } from './spinner/spinner.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    CreatePasswordComponent,
    RecoverPasswordComponent,
    RecoverPasswordRequestComponent,
    ChangePasswordComponent,
    LoadingScreenComponent,
    SpinnerComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    APP_ROUTES,
    PagesModule,
    ServiceModule,
    BrowserAnimationsModule,
    NgxPermissionsModule.forRoot()
  ],
  exports: [NgxPermissionsModule],
  providers: [
    LoadingScreenService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingScreenInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
