import { RouterModule, Routes } from '@angular/router';
import { PagesComponent } from './pages.component';
import { LoginGuard } from '../services/guards/login-guard.guard';
import { UsersComponent } from './users/users.component';
import { UserComponent } from './users/user.component';
import { TddComponent } from './reports/tdd.component';
import { TaeComponent } from './sales/tae.component';
import { TaerComponent } from './reports/taer.component';
import { PasComponent } from './sales/pas.component';
import { PasrComponent } from './reports/pasr.component';
import { TermsComponent } from './terms/terms/terms.component';
import { NoticeOfPrivacyComponent } from './terms/notice-of-privacy/notice-of-privacy.component';
import { PdsTermsComponent } from './terms/pds-terms/pds-terms.component';

const pagesRoutes: Routes = [
  {
    path: '',
    component: PagesComponent,
    canActivate: [LoginGuard],
    children: [
      // {
      //   path: 'dashboard', component: DashboardComponent,
      //   data: {
      //     titulo: 'Dashboard',
      //     modulo: 'Dashboard',
      //     pagina: 'Información'
      //   },
      //   canActivate: [LoginGuard]
      // },
      {
        path: 'users',
        component: UsersComponent,
        data: {
          titulo: 'Mantenimiento de Usuarios',
          modulo: 'Configuración',
          pagina: 'Usuarios'
        },
        canActivate: [LoginGuard]
      },
      {
        path: 'user/:id',
        component: UserComponent,
        data: {
          titulo: 'Crear/Actualizar Usuario',
          modulo: 'Configuración',
          pagina: 'Usuarios'
        },
        canActivate: [LoginGuard]
      },
      {
        path: 'tdd',
        component: TddComponent,
        data: {
          titulo: 'TDD/TDC',
          modulo: 'Reportes',
          pagina: 'TDD/TDC'
        },
        canActivate: [LoginGuard]
      },
      {
        path: 'taer',
        component: TaerComponent,
        data: {
          titulo: 'TAE',
          modulo: 'Reportes',
          pagina: 'TAE'
        },
        canActivate: [LoginGuard]
      },
      {
        path: 'tae',
        component: TaeComponent,
        data: {
          titulo: 'Tiempo Aire',
          modulo: '  Mis Ventas',
          pagina: 'Tiempo Aire'
        },
        canActivate: [LoginGuard]
      },
      {
        path: 'pasr',
        component: PasrComponent,
        data: {
          titulo: 'Pago de Servicios',
          modulo: '  Mis Ventas',
          pagina: 'Pago de Servicios'
        },
        canActivate: [LoginGuard]
      },
      {
        path: 'pas',
        component: PasComponent,
        data: {
          titulo: 'Nuevo',
          modulo: 'Pago de Servicios',
          pagina: 'Pago de Servicios'
        },
        canActivate: [LoginGuard]
      },
      {path: 'terms', component: TermsComponent},
      {path: 'privacy', component: NoticeOfPrivacyComponent},
      {path: 'pds-terms', component: PdsTermsComponent},

      {path: '', redirectTo: '/taer', pathMatch: 'full'}
    ]
  },

];
export const PAGES_ROUTES = RouterModule.forChild(pagesRoutes);
