<!-- ============================================================== -->
<!-- Topbar header - style you can find in pages.scss -->
<!-- ============================================================== -->
<header class="topbar">
  <nav class="navbar top-navbar navbar-expand-md navbar-light">
    <!-- ============================================================== -->
    <!-- Logo -->
    <!-- ============================================================== -->
    <div class="navbar-header">
      <a class="navbar-brand logo" [routerLink]="['/tae']" style="width: 100%; text-align: center;">
        <b>
          <img *ngIf="showMiniLogo" src="assets/images/websale/logo-sicobro-menu-chico.png" alt="">
        </b>
        <span>
          <img *ngIf="showLargeLogo" src="assets/images/websale/logo70.png" height="70"/>
       </span>
      </a>
    </div>
    <!-- ============================================================== -->
    <!-- End Logo -->
    <!-- ============================================================== -->
    <div class="navbar-collapse">
      <!-- ============================================================== -->
      <!-- toggle and nav items -->
      <!-- ============================================================== -->
      <ul class="navbar-nav mr-auto">
        <!-- This is hamburguer icon -->
        <li class="nav-item">
          <a (click)="hamburguesaClick()"
             class="nav-link nav-toggler hidden-md-up waves-effect waves-dark">
            <i class="ti-menu"></i>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link wc" id="timer-header">
            <jano-clock></jano-clock>
          </a>
        </li>
        <li class="nav-item hidden-sm-down"></li>
      </ul>
      <ul class="navbar-nav my-lg-0 float-right">
        <!-- ============================================================== -->
        <!-- Profile -->
        <!-- ============================================================== -->
        <li class="nav-item dropdown welcome">
          <a class="nav-link dropdown-toggle waves-effect waves-dark" href="" data-toggle="dropdown"
             aria-haspopup="true" aria-expanded="false">
            <p class="m-0 wc">Bienvenido: {{ cliente && cliente?.name }}</p>
            <p class="m-0 text-right red-id"><b>REDID: {{ cliente && cliente?.redId }}</b></p>
          </a>
        </li>

        <li class="dropdown welcome" style="display: flex;align-items: center;">
          <!--          <span class="ini u-name">{{ cliente && cliente?.name.substring(1, 0).toUpperCase() + cliente?.lastname1.substring(1, 0).toUpperCase()}}</span>-->
          <span class="ini u-name">
            {{ cliente && cliente?.name.substring(1, 0).toUpperCase() + cliente?.lastname1.substring(1, 0).toUpperCase()}}
          </span>
        </li>

        <li class="nav-item dropdown" style="display: flex;align-items: center;">
          <a class="nav-link dropdown-toggle waves-effect waves-dark" href="" (click)="service.logout()">
            <b style="margin-right: 5px;" class="exit">Salir</b>

            <i class="fa fa-sign-out"></i>
          </a>
        </li>


      </ul>
    </div>
  </nav>
</header>
<!-- ============================================================== -->
<!-- End Topbar header -->
<!-- ============================================================== -->
