import { Component, OnInit } from '@angular/core';
import {
  NgbDateStruct,
  NgbDateAdapter,
  NgbDateNativeAdapter,
  NgbDate,
  NgbCalendar,
  NgbDateParserFormatter
} from '@ng-bootstrap/ng-bootstrap';
import { PageEvent } from '@angular/material/paginator';
import { Taer } from '../../models/taer.model';
import { TaerService } from '../../services/reports/taer.service';
import { NgbDateParserFormatterEsMX } from '../../config/dateformat';

@Component({
  selector: 'jano-taer',
  templateUrl: './taer.component.html',
  providers: [
    { provide: NgbDateParserFormatter, useClass: NgbDateParserFormatterEsMX }
  ],
  styles: [
    `
      .red {
        color: #ff0000;
      }
      .custom-day {
        text-align: center;
        padding: 0.185rem 0.25rem;
        display: inline-block;
        height: 2rem;
        width: 2rem;
      }
      .custom-day.focused {
        background-color: #e6e6e6;
      }
      .custom-day.range,
      .custom-day:hover {
        background-color: #0275d8;
        color: white;
      }
      .faded {
        opacity: 0.5;
      }
      .weekend {
        background-color: #242a33;
        border-radius: 1rem;
        color: white;
      }
      .hidden {
        display: none;
      }
    `
  ]
})
export class TaerComponent implements OnInit {
  // This is for the range date picker
  // hoveredDate: NgbDateStruct;

  // fromDate: NgbDateStruct;
  // toDate: NgbDateStruct;

  model: NgbDateStruct;

  cargando: boolean = true;
  fechaInicial: NgbDate;
  fechaFinal: NgbDate;
  taer: Taer; // = new Taer('', '');
  // taerArr: any[];
  taerArr: Taer[] = [];

  pageSize: number = 10;
  pageNumber: number = 1;
  pageSizeOptions = [1, 5, 10, 50, 100];

  // isToday = (date: NgbDate) => {
  //   return date.day == this.today.getDate() &&
  //       date.month == this.today.getMonth()+1 &&
  //       date.year == this.today.getFullYear();
  // }

  constructor(
    public service: TaerService,
    private calendar: NgbCalendar // ,
  ) // calendar: NgbCalendar,
  // private calendar1: NgbCalendar,
  // private calendar5: NgbCalendar
  {}

  // onDateChange(date: NgbDateStruct) {
  //   if (!this.fromDate && !this.toDate) {
  //     this.fromDate = date;
  //   } else if (this.fromDate && !this.toDate && after(date, this.fromDate)) {
  //     this.toDate = date;
  //   } else {
  //     this.toDate = null;
  //     this.fromDate = date;
  //   }
  // }

  // isHovered = date => this.fromDate && !this.toDate && this.hoveredDate && after(date, this.fromDate) && before(date, this.hoveredDate);
  // isInside = date => after(date, this.fromDate) && before(date, this.toDate);
  // isFrom = date => equals(date, this.fromDate);
  // isTo = date => equals(date, this.toDate);
  // isDisabled = (date: NgbDate, current: { month: number }) => date.month !== current.month;
  // isWeekend = (date: NgbDate) => this.calendar1.getWeekday(date) >= 6;

  ngOnInit() {
    this.cargando = false;
    this.fechaInicial = this.calendar.getToday();
    this.fechaFinal = this.calendar.getToday();
    this.buscarPorRangoFecha();
  }

  buscarPorRangoFecha() {
    console.log(
      'buscarPorRangoFecha: fechaInicial: ',
      this.fechaInicial,
      ', fechaFinal',
      this.fechaFinal
    );

    const fechaInicial =
      (this.fechaInicial.day <= 9
        ? '0' + this.fechaInicial.day
        : this.fechaInicial.day) +
      '' +
      (this.fechaInicial.month <= 9
        ? '0' + this.fechaInicial.month
        : this.fechaInicial.month) +
      '' +
      this.fechaInicial.year;
    const fechaFinal =
      (this.fechaFinal.day <= 9
        ? '0' + this.fechaFinal.day
        : this.fechaFinal.day) +
      '' +
      (this.fechaFinal.month <= 9
        ? '0' + this.fechaFinal.month
        : this.fechaFinal.month) +
      '' +
      this.fechaFinal.year;
    console.log(
      'buscarPorRangoFecha: (F) fechaInicial: ',
      fechaInicial,
      ', fechaFinal',
      fechaFinal
    );

    this.cargando = true;
    this.service
      .buscarPorRangoFecha(fechaInicial, fechaFinal)
      .subscribe((res: any) => {
        const taestmp: Taer[] = res;

        this.taerArr = taestmp.map(taer => {
          taer.amount = taer.amount / 100;
          return taer;
        });

        this.taerArr = res;
        console.log(this.taerArr);

        this.cargando = false;
      });
  }

  descargarReportePorFiltros() {
    // console.log('descargarReportePorFiltros: filtrosTelefonia: ', this.filtrosTelefonia);
    // console.log('descargarReportePorFiltros: fechaInicial: ', this.fechaInicial, ', fechaFinal', this.fechaFinal);

    const fechaInicial =
      (this.fechaInicial.day <= 9
        ? '0' + this.fechaInicial.day
        : this.fechaInicial.day) +
      '' +
      (this.fechaInicial.month <= 9
        ? '0' + this.fechaInicial.month
        : this.fechaInicial.month) +
      '' +
      this.fechaInicial.year;
    // this.filtrosTelefonia.startDate = fechaInicial;

    const fechaFinal =
      (this.fechaFinal.day <= 9
        ? '0' + this.fechaFinal.day
        : this.fechaFinal.day) +
      '' +
      (this.fechaFinal.month <= 9
        ? '0' + this.fechaFinal.month
        : this.fechaFinal.month) +
      '' +
      this.fechaFinal.year;
    // this.filtrosTelefonia.endDate = fechaFinal;

    // console.log('descargarReportePorFiltros: (F) filtrosTelefonia: ', this.filtrosTelefonia);
    // console.log('descargarReportePorFiltros: (F) fechaInicial: ', fechaInicial, ', fechaFinal', fechaFinal);

    this.cargando = true;
    this.service
      .descargarReportePorFiltros(fechaInicial, fechaFinal)
      .subscribe((res: any) => {
        // console.log('descargarReportePorFiltros: id archivo: ', res);

        window.open(res);
        this.cargando = false;
      });
  }

  handlePage(e: PageEvent) {
    this.pageSize = e.pageSize;
    this.pageNumber = e.pageIndex + 1;
  }
}
