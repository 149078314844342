import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ClockService } from '../../services/clock/clock.service';
import { Clock } from '../../models/clock.model';

@Component({
  selector: 'jano-clock',
  templateUrl: './clock.component.html',
  styleUrls: ['./clock.component.css']
})
export class ClockComponent implements OnInit {

  data$: Observable<Clock>;
  hour: string;
  minutes: string;
  dayofweek: string;
  dayandmonth: string;
  ampm: string;
  seconds: string;

  constructor(
    public clockService: ClockService,
  ) {
  }

  ngOnInit() {
    this.data$ = this.clockService.getClockData();
    this.data$.subscribe(clock => {
      this.hour = clock.hour;
      this.minutes = clock.minutes;
      this.dayofweek = clock.dayofweek;
      this.dayandmonth = clock.dayandmonth;
      this.ampm = clock.ampm;
      this.seconds = clock.seconds
    });
  }


}
