import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { UserService } from '../services/user/user.service';
import { Router } from '@angular/router';
import { ChangePasssword } from '../models/changePassword';

declare function init_plugins();

@Component({
  selector: 'jano-recover-password',
  templateUrl: './recover-password.component.html',
  styles: []
})
export class RecoverPasswordComponent implements OnInit {
  forma: FormGroup;

  constructor(public service: UserService, public router: Router) {}

  ngOnInit() {
    init_plugins();

    this.forma = new FormGroup(
      {
        userOrEmail: new FormControl(null, Validators.required),
        password: new FormControl(null, Validators.required),
        password2: new FormControl(null, Validators.required),
        validationCode: new FormControl(null, Validators.required)
      },
      { validators: this.sonIguales('password', 'password2') }
    );
  }

  sonIguales(campo1: string, campo2: string) {
    return (group: FormGroup) => {
      const pwd = group.controls[campo1].value;
      const pwd2 = group.controls[campo2].value;

      if (pwd === pwd2) {
        return null;
      }

      return {
        sonIguales: true
      };
    };
  }
  cambiarContrasena() {
    console.log('cambiarContrasena: forma valida', this.forma.valid);

    if (this.forma.invalid) {
      Swal.fire({
        title: 'Importante!',
        text: 'Datos incorrectos, intente nuevamente!',
        type: 'warning',
        confirmButtonColor: '#FF0000',
        confirmButtonText: 'Cool!'
      });

      return;
    }

    const changePwd = new ChangePasssword(
      this.forma.value.userOrEmail,
      this.forma.value.password,
      this.forma.value.validationCode
    );
    console.log(
      'RecoverPasswordComponent -> cambiarContrasena -> changePwd',
      changePwd
    );
    this.service.cambiarContrasena(changePwd).subscribe(res => {
      // console.log('UserComponent -> guardar -> changePwd', changePwd);
      this.router.navigate(['/login']);
    });
  }
}
