export class Pas {
  constructor(
    public amountCents?: number,
    public clientUUID?: string,
    public clientVersion?: string,
    public janoUUID?: number,
    public paymentReference?: string,
    public productTxnId?: number,

    public balanceDecrease?: number,
    public serviceAmount?: number,
    public commission?: number
  ) {}
}
