// export const BASE_API = 'http://3.89.55.28:8087/webadmin';
// export const BASE_API = 'http://3.15.231.19:8088/websales';
// export const BASE_API = 'http://3.15.231.19:8088/api/sales';
// export const BASE_CATALOGS_API = 'http://3.15.231.19:8091/api/servicepayment';
export const BASE_API = 'https://jano.jurinberlab.com/api';
export const BASE_CATALOGS_API = 'https://jano.jurinberlab.com/api';

export const environment = {
  env: 'DEV',
  production: false,
  ENDPOINTS: {
    SALES: BASE_API + '/sales',
    CATALOGS: BASE_CATALOGS_API + '/servicepayment',
  }
};


