import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/models/user.model';
import { AvailableStatus } from '../../models/availableStatus.model';
import { UserService } from 'src/app/services/service.index';
import { PageEvent } from '@angular/material/paginator';
import Swal from 'sweetalert2';

@Component({
  selector: 'jano-users',
  templateUrl: './users.component.html',
  styles: []
})
export class UsersComponent implements OnInit {
  usuarios: User[] = [];
  filteredUsers: User[] = [];
  availableStatus: AvailableStatus[] = [];
  cargando: boolean = true;
  private _buscarNombreUsuario = '';
  private _buscarNombre = '';
  private _buscarApellido = '';
  private _buscarStatus = '';
  private _buscarEmail = '';
  usuario: User = new User('', '', '', '');
  selectedStatus: string = 'ENABLED';

  pageSize: number = 10;
  pageNumber: number = 1;
  pageSizeOptions = [1, 5, 10, 50, 100];

  constructor(public service: UserService
    // , public roleService: RoleService
  ) { }

  ngOnInit() {
    this.cargar();
    this.estatusDisponible();
  }

  handlePage(e: PageEvent) {
    this.pageSize = e.pageSize;
    this.pageNumber = e.pageIndex + 1;
  }

  cargar() {
    this.cargando = true;
    this.service.cargar().subscribe((res: any) => {
      // console.log('UsersComponent -> cargar -> res', res);
      this.usuarios = res;
      this.filteredUsers = res;
      this.cargando = false;
    });
  }

  borrar(usuario: User) {
    // console.log('UsersComponent -> borrar -> id', usuario.id);
    if (usuario.id === 1) {
      Swal.fire({
        title: 'Importante!',
        text: 'No es posible eliminar el Usuario principal!',
        type: 'error',
        confirmButtonColor: '#FF0000',
        confirmButtonText: 'Aceptar!'
      });
      return;
    }

    Swal.fire({
      title: 'Estás seguro?',
      text: 'No será capaz de deshacer los cambios!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#FF0000',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, borrarlo!',
      cancelButtonText: 'No, cancelar!'
    }).then(result => {
      // console.log('UsersComponent -> borrar -> result.value', result.value);
      if (result.value) {
        this.service.borrar(usuario.id).subscribe(() => this.cargar());
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        // console.log('UsersComponent -> borrar -> result.dismiss', result.dismiss);
        Swal.fire({
          title: 'Cancelado!',
          text: 'Su Usuario está a salvo :)',
          type: 'error',
          confirmButtonColor: '#FF0000',
          confirmButtonText: 'Aceptar!'
        });
        // Swal.fire('Cancelado', 'Su Usuario está a salvo :)', 'error');
      }
    });
  }

  guardar(usuario: User) {
    this.service.actualizar(usuario).subscribe();
  }

  get buscarNombreUsuario(): string {
    return this._buscarNombreUsuario;
  }

  set buscarNombreUsuario(value: string) {

    this._buscarNombreUsuario = value.toLowerCase();
    this.filteredUsers = this.filter();

  }

  get buscarNombre(): string {
    return this._buscarNombre;
  }

  set buscarNombre(value: string) {

    this._buscarNombre = value.toLowerCase();
    // console.log('buscarNombre: _buscarNombre: ', value);
    this.filteredUsers = this.filter();

  }

  get buscarApellido(): string {
    return this._buscarApellido;
  }

  set buscarApellido(value: string) {

    this._buscarApellido = value.toLowerCase();
    // console.log('buscarApellido: _buscarNombreUsuario: ', value);
    this.filteredUsers = this.filter();

  }

  get buscarStatus(): string {
    return this._buscarStatus;
  }

  set buscarStatus(value: string) {

    this._buscarStatus = value.toLowerCase();
    console.log('buscarStatus: _buscarStatus: ', this._buscarStatus);
    this.filteredUsers = this.filter();

  }

  get buscarEmail(): string {
    return this._buscarEmail;
  }

  set buscarEmail(value: string) {

    this._buscarEmail = value.toLowerCase();
    console.log('buscarEmail: _buscarEmail: ', this._buscarEmail);
    this.filteredUsers = this.filter();

  }

  filter() {
    // console.log('filter: _buscarTermino: [', this._buscarTermino.trim(), ']');
    return this.usuarios.filter(usuario => this.filterField(usuario));
  }

  filterField(usuario: User) {
    // console.log('filterField: usuario: ', usuario);
    // console.log('filterField: this._buscarNombreUsuario: [', this._buscarNombreUsuario , ']');
    // console.log('filterField: this._buscarNombre: [', this._buscarNombre, ']');
    // console.log('filterField: this._buscarApellido: [', this._buscarApellido , ']');
    // console.log('filterField: this._buscarStatus: [', this._buscarStatus , ']');
    if (this._buscarNombreUsuario.trim() !== ' ') {

      if (usuario.storeUsername.toLowerCase().indexOf(this._buscarNombreUsuario) !== -1
        && usuario.name.toLowerCase().indexOf(this._buscarNombre) !== -1
        && usuario.lastname1.toLowerCase().indexOf(this._buscarApellido) !== -1
        && usuario.email.toLowerCase().indexOf(this._buscarEmail) !== -1
        && usuario.status.toLowerCase().indexOf(this._buscarStatus) !== -1
      ) {
        return true;
      }
    }
  }

  estatusDisponible() {
    this.service.estatusDisponible()
      .subscribe((res: any) => {
        console.log(res);
        this.availableStatus = res;
      });
  }
}
