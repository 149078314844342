<!-- ============================================================== -->
<!-- Start Page Content -->
<!-- ============================================================== -->
<a id="top-page"></a>
<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-body">
        <div class="d-flex">
          <div>
            <div
              class="card-title"
              style="font-family: 'Dom Diagonal BT'; font-size: 40px;color: #333333"
            >
              Pago de Servicios
            </div>
          </div>
          <div class="ml-auto saldo" style="color: #FF0000; font-size: 30px">
            Mi Saldo: {{ saldo.balanceLabel }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-md-12">
    <div class="card">
      <form #f="ngForm" ngNativeValidate (keydown.enter)="$event.preventDefault()">
        <div class="card-body">
          <div class="titulo_recarga" style="margin-left: 35px;">
            <div class="recarga" style="width: 20%; text-align: center;">
              Realizar un Pago
            </div>
            <div>
              <hr style="margin-left: 0%; width: 20%; background-color: red;"/>
            </div>
          </div>

          <hr style="background-color: #F2F2F2;"/>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <span class="round">1</span>
                <label class="p-l-10 title-sction" for="numInt">Selecciona servicio</label>
              </div>
            </div>
            <div class="col-md-12">
              <div class="row">
                <div class="col" *ngFor="let segment of segments">
                  <div class="ribbon-wrapper card" style="margin-bottom: 0px;">
                    <div class="p-l-50-no">
                      <p
                        class="text-center"
                        style="height: 30px; background-color: black; font-family: 'Roboto Medium'; margin-bottom: 5px; font-size: 20px; color: #ffffff"
                      >
                        <a
                          style="color:#ffffff;"
                          href="javascript:void(0)"
                          (click)="seleccionarRadio(segment)"
                        >
                          <!-- <img src="assets/images/websale/{{ segment.image }}" alt="{{ segment.image }}"
                                                        (click)="cargarProductos(segment.id)"> -->
                          {{ segment.name | uppercase }}
                        </a>
                      </p>
                      <p
                        class="text-center"
                        style="margin-bottom: 0px; font-family: 'Roboto Light'; font-size: 14px;"
                      >
                        {{ segment.text | uppercase }}
                      </p>
                      <p class="radioOptions text-center">
                        <img
                          src="{{ segment.imageUrl }}"
                          width="50px"
                          height="50px"
                          alt="Icono"
                        />
                        <input
                          type="radio"
                          id="mn-{{ segment.id }}"
                          name="minimal-radio"
                          value="{{ segment.id }}"
                          [(ngModel)]="segment.id"
                          (click)="cargarProductos(segment.id)"
                          checked="checked"
                          required
                        />
                        <label for="mn-{{ segment.id }}">&nbsp;</label>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr/>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <span class="round">2</span>
                <label class="p-l-10 title-sction" for="productTxnId">SERVICIO</label>
              </div>
            </div>
            <div class="col-md-6">
              <div class="row">
                <div class="col form-group d-inline p-10">
                  <select
                    [(ngModel)]="pas.productTxnId"
                    id="productTxnId"
                    name="productTxnId"
                    class="form-control"
                    (change)="cargarServicio($event.target.value)"
                    required
                  >
                    <option value="0">Elija un Servicio</option>
                    <option
                      *ngFor="let product of products"
                      [value]="product.txnId"
                    >{{ product.name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12" style="padding-top: 15px;">
              <div class="form-group">
                <span class="round">3</span>
                <label class="p-l-10 title-sction" for="paymentReference"
                >REFERENCIA</label
                >
              </div>
            </div>
            <div class="col-md-6">
              <div class="row">
                <div class="col form-group d-inline p-10">
                  <input
                    type="text"
                    [(ngModel)]="pas.paymentReference"
                    class="input-text-field"
                    id="paymentReference"
                    name="paymentReference"
                    value=""
                    maxlength="50"
                    required
                    autofocus
                    style="width: 100%; font-size: 1.5em; stroke: 1px #333333; border-radius: 5px;"
                  />
                  <!-- (paste)="onPaste($event)"
                    (copy)="onPaste($event)" -->
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="row">
                <div class="d-inline pt-3" style="cursor: pointer;">
                  <!-- (mouseover)="openDialog()" -->
                  <span class="inquiry" (mouseover)="openDialog(content)">
                    ?
                  </span>
                  <ng-template #content let-modal>
                    <div class="modal-header">
                      <h4 class="modal-title">Referencia de Pago</h4>
                      <!-- <button
                        type="button"
                        class="close"
                        aria-label="Close"
                        (click)="modal.dismiss('Cross click')"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button> -->
                    </div>
                    <div class="modal-body">
                      <!-- <p>One fine body&hellip;</p> -->
                      <img src="{{ imageRef }}"/>
                    </div>
                    <div class="modal-footer">
                      <!-- <button
                        type="button"
                        class="btn btn-light"
                        (click)="modal.close('Close click')"
                      >
                        Close
                      </button> -->
                    </div>
                  </ng-template>
                </div>
              </div>
            </div>
          </div>
          <hr/>
          <div class="row">
            <!-- IMPORTES FIJOS -->
            <div class="col-md-3" *ngIf="!freeAmounts">
              <label class="p-l-10 title-sction" for="amountCents">
                IMPORTE DEL SERVICIO
              </label>
              <div class="form-group d-inline">
                <input
                  type="text"
                  [(ngModel)]="pas.amountCents"
                  class="input-text-field"
                  id="amountCents"
                  name="amountCents"
                  value="{{ pas.amountCents }}"
                  maxlength="10"
                  required
                  autofocus
                  (paste)="onPaste($event)"
                  (copy)="onPaste($event)"
                  (blur)="obtenerComisionPas(pas)"
                  placeholder="$"
                  style="width: 100%; font-size: 1.5em; stroke: 1px #333333; border-radius: 5px;"
                />
              </div>
            </div>

            <!-- IMPORTES FIJOS -->
            <div class="col-md-12" *ngIf="freeAmounts">
              <label class="p-l-10 title-sction" for="radioBasic">
                IMPORTE DEL SERVICIO
              </label>
              <div class="card">
                <div class="card-body">
                  <!-- <h4 class="card-title">Radio Buttons(Reactive Forms)</h4> -->
                  <form [formGroup]="radioGroupForm">
                    <div
                      class="btn-group btn-group-toggle"
                      ngbRadioGroup
                      id="radioBasic"
                      name="radioBasic"
                      formControlName="model"
                      style="flex-wrap: wrap"
                    >
                      <div *ngFor="let aList of amountsList">
                        <label
                          ngbButtonLabel
                          class="btn btn-outline-primary waves-effect waves-light monto"
                          style="border-color: #000000; margin-right: 6px;"
                        >
                          <input
                            ngbButton
                            type="radio"
                            name="amountCents"
                            [value]="aList"
                            autocomplete="off"
                            (click)="obtenerComision(aList)"
                            autofocus
                            required
                          />
                          <span style="color: #000000; font-size: 1.5em;">{{
                            aList
                            }}</span>
                        </label>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <!-- COMISIÓN -->
            <div class="col-md-3">
              <label class="p-l-10 title-sction" for="commission">
                COMISIÓN
              </label>
              <div class="form-group d-inline">
                <input
                  type="text"
                  [(ngModel)]="pas.commission"
                  class="input-text-field"
                  id="commission"
                  name="commission"
                  value="{{ commission.commissionLabel }}"
                  placeholder="$"
                  maxlength="10"
                  autofocus
                  (paste)="onPaste($event)"
                  (copy)="onPaste($event)"
                  style="width: 100%; font-size: 1.5em; stroke: 1px #333333; border-radius: 5px;"
                  disabled
                />
              </div>
            </div>

            <!-- IMPORTE A COBRAR -->
            <div class="col-md-3">
              <label class="p-l-10 title-sction" for="serviceAmount">
                IMPORTE A COBRAR
              </label>
              <div class="form-group d-inline">
                <input
                  type="text"
                  [(ngModel)]="pas.serviceAmount"
                  class="input-text-field"
                  id="serviceAmount"
                  name="serviceAmount"
                  value="{{ commission.totalAmountLabel }}"
                  placeholder="$"
                  maxlength="10"
                  autofocus
                  (paste)="onPaste($event)"
                  (copy)="onPaste($event)"
                  style="width: 100%; font-size: 1.5em; stroke: 1px #333333; border-radius: 5px;"
                  disabled
                />
              </div>
            </div>

            <!-- DISMINUCIÓN DE SALDO -->
            <div class="col-md-3">
              <label class="p-l-10 title-sction" for="balanceDecrease">
                DISMINUCIÓN DE SALDO
              </label>
              <div class="form-group d-inline">
                <input
                  type="text"
                  [(ngModel)]="pas.balanceDecrease"
                  class="input-text-field"
                  id="balanceDecrease"
                  name="balanceDecrease"
                  value="{{ commission.balanceDecreaseLabel }}"
                  maxlength="10"
                  autofocus
                  (paste)="onPaste($event)"
                  (copy)="onPaste($event)"
                  placeholder="$"
                  style="width: 100%; font-size: 1.5em; stroke: 1px #333333; border-radius: 5px;"
                  disabled
                />
              </div>
            </div>
          </div>
          <hr/>
          <div class="col-md-12 text-right">
            <div class="row">
              <div class="col-8"></div>
              <div class="col-4">
                <button
				  (click)="realizarPagoServicio(f)" 
                  class="my-button btn-lg btn-block"
                  style="font-family: 'Dom Diagonal BT';  font-size: 30px; cursor: pointer;"
                  [disabled]="disabledmode ? 'disabled' : ''"
                >
                  Cobrar
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<div id="demo" class="modal-pago" style="display: none;">
  <div class="form-pago-exitosa">
    <div class="row" style="border-bottom: 1px solid #333333;">
      <div class="col-md-12">
        <div class="avatar text-center">
          <img src="assets/images/websale/succsess-icon.png" alt="Avatar"/>
        </div>
      </div>
      <div class="col-md-12">
        <h2
          class="text-center login-title "
          style="color: #FF0000; font-size: 40px;margin: 10px 0 25px; font-family: 'Dom Diagonal BT';"
        >
          ¡Tu cobro fué realizado!
        </h2>
      </div>
      <div class="col-md-12">
        <div class="row" style="border-bottom: 1px solid #333333;">
          <div class="col">
            <div
              class="form-group"
              style="margin-bottom: 10px; font-family: 'Roboto Light'; font-size: 14px;">
              <p class="label-input text-center title-sction" style="margin: 0.5rem 0px;">
                Aprobación
              </p>
            </div>
          </div>
          <div class="col">
            <div
              class="form-group"
              style="margin-bottom: 10px; font-family: 'Roboto Light'; font-size: 14px;">
              <p class="label-input text-center title-sction" style="margin: 0.5rem 0px;">
                {{ pago.approvalCode }}
              </p>
            </div>
          </div>
        </div>
        <div class="row" style="border-bottom: 1px solid #333333;">
          <div class="col">
            <div
              class="form-group"
              style="margin-bottom: 10px; font-family: 'Roboto Light'; font-size: 14px;">
              <p class="label-input text-center title-sction" style="margin: 0.5rem 0px;">
                Referencia
              </p>
            </div>
          </div>
          <div class="col">
            <div
              class="form-group"
              style="margin-bottom: 10px; font-family: 'Roboto Light'; font-size: 14px;">
              <p class="label-input text-center title-sction" style="margin: 0.5rem 0px;">
                {{ pago.paymentReference }}
              </p>
            </div>
          </div>
        </div>
        <div class="row" style="border-bottom: 1px solid #333333;">
          <div class="col">
            <div
              class="form-group"
              style="margin-bottom: 10px; font-family: 'Roboto Light'; font-size: 14px;">
              <p class="label-input text-center title-sction" style="margin: 0.5rem 0px;">
                Servicio
              </p>
            </div>
          </div>
          <div class="col">
            <div
              class="form-group"
              style="margin-bottom: 10px; font-family: 'Roboto Light'; font-size: 14px;"
            >
              <p
                class="label-input text-center title-sction"
                style="margin: 0.5rem 0px;"
              >
                {{ pago.productName }}
              </p>
            </div>
          </div>
        </div>
        <div class="row" style="border-bottom: 1px solid #333333;">
          <div class="col">
            <div
              class="form-group"
              style="margin-bottom: 10px; font-family: 'Roboto Light'; font-size: 14px;"
            >
              <p
                class="label-input text-center title-sction"
                style="margin: 0.5rem 0px;"
              >
                Importe del Servicio
              </p>
            </div>
          </div>
          <div class="col">
            <div
              class="form-group"
              style="margin-bottom: 10px; font-family: 'Roboto Light'; font-size: 14px;"
            >
              <p
                class="label-input text-center title-sction"
                style="margin: 0.5rem 0px;"
              >
                {{ pago.amountLabel }}
              </p>
            </div>
          </div>
        </div>
        <div class="row" style="border-bottom: 1px solid #333333;">
          <div class="col">
            <div
              class="form-group"
              style="margin-bottom: 10px; font-family: 'Roboto Light'; font-size: 14px;"
            >
              <p
                class="label-input text-center title-sction"
                style="margin: 0.5rem 0px;"
              >
                Referencias adicionales
              </p>
            </div>
          </div>
          <div class="col">
            <div
              class="form-group"
              style="margin-bottom: 10px; font-family: 'Roboto Light'; font-size: 14px;"
            >
              <p
                class="label-input text-center title-sction"
                style="margin: 0.5rem 0px;"
              >
                {{ pago.additionalReferences }}
              </p>
            </div>
          </div>
        </div>
        <div class="row" style="border-bottom: 1px solid #333333;">
          <div class="col">
            <div
              class="form-group"
              style="margin-bottom: 10px; font-family: 'Roboto Light'; font-size: 14px;"
            >
              <p
                class="label-input text-center title-sction"
                style="margin: 0.5rem 0px;"
              >
                Referencias adicionales
              </p>
            </div>
          </div>
          <div class="col">
            <div
              class="form-group"
              style="margin-bottom: 10px; font-family: 'Roboto Light'; font-size: 14px;"
            >
              <p
                class="label-input text-center title-sction"
                style="margin: 0.5rem 0px;"
              >
                {{ pago.additionalReferences }}
              </p>
            </div>
          </div>
        </div>
        <div class="row" style="border-bottom: 1px solid #333333;">
          <div class="col">
            <div
              class="form-group"
              style="margin-bottom: 10px; font-family: 'Roboto Light'; font-size: 14px;"
            >
              <p
                class="label-input text-center title-sction"
                style="margin: 0.5rem 0px;"
              >
                Fecha
              </p>
            </div>
          </div>
          <div class="col">
            <div
              class="form-group"
              style="margin-bottom: 10px; font-family: 'Roboto Light'; font-size: 14px;"
            >
              <p
                class="label-input text-center title-sction"
                style="margin: 0.5rem 0px;"
              >
                {{ pago.creationDateLabel }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <button
    id="printButton"
    class="my-button btn-lg btn-block"
    style="font-family: 'Dom Diagonal BT'; font-size: 30px; cursor: pointer;"
    printSectionId="demo"
    ngxPrint
  ></button>
</div>
