<!-- ============================================================== -->
<!-- Left Sidebar - style you can find in sidebar.scss  -->
<!-- ============================================================== -->
<aside class="left-sidebar">
  <!-- Sidebar scroll-->
  <div class="scroll-sidebar">
    <!-- Sidebar navigation-->
    <nav class="sidebar-nav">
      <ul id="sidebarnav">
        <!--<li class="user-profile">
           <a class="has-arrow waves-effect waves-dark" href="#" aria-expanded="false"><img src="../assets/images/users/profile.png" alt="user" /><span class="hide-menu">Steave Jobs </span></a>
           <ul aria-expanded="false" class="collapse">
               <li><a href="javascript:void()">My Profile </a></li>
               <li><a href="javascript:void()">My Balance</a></li>
               <li><a href="javascript:void()">Inbox</a></li>
               <li><a routerLinkActive="active" routerLink="/account-settings">Account Setting</a></li>
               <li><a (click)="service.logout()">Salir</a></li>
           </ul>
       </li>-->
        <li class="nav-devider"></li>
        <li class="nav-small-cap">Mis Opciones</li>
        <li *ngFor="let menu of _sidebar.menu">
          <a class="has-arrow waves-effect waves-dark" href="#" aria-expanded="false"><i [class]="menu.icono"></i>
            <span class="hide-menu">{{ menu.titulo }}
              <!-- <span class="label label-rouded label-themecolor pull-right"> {{ menu.submenu.length }} </span> -->
            </span>
          </a>
          <ul aria-expanded="false" class="collapse">

            <li *ngFor="let submenu of menu.submenu">
              <a routerLinkActive="active" [routerLink]="[ submenu.url ]">{{ submenu.titulo }} </a>
            </li>

          </ul>
        </li>
        <div class="sidebar-terms-container">
          <div class="sidebar-terms-items">
            <li>
            <span class="waves-effect waves-dark sidebar-nav-custom" aria-expanded="false">
              <!--<i [class]="'mdi mdi-shopping mdi-shopping-custom'"></i>-->
              <!--<i class="ico-terms"></i>-->

              <a [routerLink]="['/terms']" class="terminos">
                <i class="icon-mano"></i>
                <span class="hide-menu">Términos y Condiciones</span>
              </a>
            </span>
            </li>
            <li>
            <span class="waves-effect waves-dark sidebar-nav-custom" aria-expanded="false">
              <!--<i [class]="'mdi mdi-shopping mdi-shopping-custom'"></i>-->
              <!--<i class="ico-privacy"></i>-->

              <a [routerLink]="['/privacy']" class="terminos">
              <span class="icon-candado"></span>
                <span class="hide-menu">Aviso de Privacidad</span>
              </a>
            </span>
            </li>
          </div>

        </div>

      </ul>
    </nav>
    <!-- End Sidebar navigation -->
  </div>
  <!-- End Sidebar scroll-->
  <div class="sidebar-footer">
    Versión 1.0.1
  </div>
</aside>
<!-- ============================================================== -->
<!-- End Left Sidebar - style you can find in sidebar.scss  -->
<!-- ============================================================== -->
