import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PageEvent } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, NgForm } from '@angular/forms';
import { Pas } from '../../models/pas.model';
import { Item } from '../../models/catalogItems.model';
import { Product } from '../../models/product.model';
import { Balance } from '../../models/balance.model';
import { PasService } from '../../services/sales/pas.service';
import { BalanceService } from '../../services/balance/balance.service';
import Swal from 'sweetalert2';
import { DOCUMENT } from '@angular/common';
import { environment } from '../../../environments/environment';

// import { BASE_CATALOGS_URL } from 'src/app/config/config';

declare var $: any;

@Component({
  selector: 'jano-pas',
  templateUrl: './pas.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./pas.component.css'],
})
export class PasComponent implements OnInit {

  API_URL: any = environment.ENDPOINTS;

  pass: Pas[] = [];
  element: HTMLElement;
  amountList: string[] = [];
  defaultChoice = '';

  showImgCorrecto: boolean = false;
  showModalSuccess: boolean = false;
  phoneSuccess: string = '';
  saldoSuccess: string = '';
  referenciaSuccess: string = '';
  aprovacionSuccess: string = '';
  productoSuccess: string = '';

  radioGroupForm: FormGroup;
  pas: Pas = new Pas(0, '', '', 0, '', 0);
  pago: any = []; //new Pas(0, '', '', 0, '', 0);
  filteredPas: Pas = new Pas(0, '', '', 0, '0', 0);
  txnIdSelected: string;
  saldo: Balance = new Balance(0, '', 0, 0, '', '', '', 0, '', '');
  selectedStatus = 'ENABLED';

  segments: Item[] = [];
  // product: Product = new Product(0, '', '', '', '', false, 0, '');
  imageRef: string = '';
  products: Product[] = [];
  commission: any = {}; // new Commission('', '', 0, 0, 0, 0, 0);

  pageSize = 10;
  pageNumber = 1;
  pageSizeOptions = [1, 5, 10, 50, 100];

  model = {
    left: true,
    middle: false,
    right: false,
  };

  model1 = 1;
  radioSelected: string;
  disabledmode: boolean = false;
  freeAmounts: boolean = false;
  amountsList: string[] = [];

  closeResult: string;

  constructor(
    public service: PasService,
    public serviceBalance: BalanceService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    @Inject(DOCUMENT) document,
    private modalService: NgbModal
  ) {
    this.cargarSegmentos();
    this.obtenerSaldo().then(() => {
      console.log('PasComponent: ngOnInit: Se obtuvo el saldo!!!')
    });

    this.radioGroupForm = this.formBuilder.group({
      model: 1,
    });

    this.validarTerminos().then(() => console.log('Términos y condiciones'));
  }

  ngOnInit() {
  }

  async validarTerminos() {
    const respuesta: any = await this.service.validarTerminos();

    if (respuesta.accepted === false) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: 'my-button btn-lg btn-block',
          cancelButton: 'my-button btn-lg btn-block',
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: ``,
          text: ``,
          // type: "error",
          width: '992px',
          html: `
          <div class="modal-pago" *ngIf="showModalSuccess">
            <div class="form-pago-exitosa">
              <div class="row" style="border-bottom: 1px solid #333333;">
                <div class="col-md-12">
                  <div class="avatar text-center">
                    <img src="assets/images/websale/succsess-icon.png" alt="Avatar">
                  </div>
                </div>
                <div class="col-md-12">
                  <h2 class="text-center login-title "
                    style="color: #FF0000; font-size: 40px; margin: 10px 0 0px; font-family: 'Dom Diagonal BT';">¡Términos y Condiciones!</h2>
                </div>
                <!--<div class="col-md-12"><div class="row"></div></div>-->
                <div class="row" style="font-weight: 200;">
                  <div class="col-12">
                    <div class="card">
                      <div class="card-body">
                        <h4 class="card-title" style="font-size: 13px; text-align: justify;" id="1">1.- Aceptación de los Términos</h4>
                        <p style="font-size: 13px; text-align: justify">
                        Al contratar usted el módulo de “pago de servicios” (el “Servicio”) ofrecido por (“Grupo Zorro"),
                        en la aplicación móvil “Zorro” o en la página web https://sicobrototal.com.mx/#/login (los “Sitios”)
                        usted acepta y está sujeto a los siguientes “Términos de Servicio”.

                        Grupo Zorro se reserva el derecho de actualizar y cambiar los Términos de Servicio en cualquier momento.
                        Si usted continúa usando el Servicio después de una actualización o modificación, significa que usted
                        está de acuerdo con los mismos.
                        </p>

                        <h4 class="card-title" style="font-size: 13px; text-align: justify;" id="1">2.- Condiciones para hacer uso del Servicio</h4>
                        <p style="font-size: 13px; text-align: justify">
                        Usted debe ser mayor de 18 años; debe ser parte de la “Comunidad Red” de Grupo Zorro; tener acceso
                        a internet y contar con saldo en la bolsa del módulo de “pago de servicios” previamente adquirido
                        en cualquier sucursal Zorro.
                        </p>

                        <h4 class="card-title" style="font-size: 13px; text-align: justify;" id="1">3.- Características del Servicio</h4>
                        <p style="font-size: 13px; text-align: justify">
                        Usted deberá seleccionar el Servicio, capturar la referencia e importe correcto en los Sitios.
                            Si usted captura incorrectamente alguno de los datos, al aceptar la transacción, se realizará
                            el cargo sin posibilidad de cancelar la transacción y hacer devolución.
                            El importe y comisión del Servicio debe ser en efectivo.
                            Verificar que el Servicio se encuentre vigente a más tardar 72 horas antes de su vencimiento.
                            Cualquier reclamación respecto a un pago, deberá llevarse a cabo directamente con el proveedor del servicio que se intentó pagar.
                            Grupo Zorro no hace devoluciones de dinero en ningún caso.
                            Grupo Zorro no es responsable por las determinaciones de cada proveedor de servicio ni por las del proveedor que procesa directamente los pagos de servicio.
                            La compra de saldo y los Servicios cobrados no se facturan.
                            Para cada pago de Servicio, usted podrá generar su comprobante de pago digital al correo que señale.
                            Para los pagos de Servicio, específicamente de CFE, el comprobante de pago no refleja ningún folio de autorización.
                        </p>

                        <h4 class="card-title" style="font-size: 13px; text-align: justify;" id="1">4.- Comisiones</h4>
                        <p style="font-size: 13px; text-align: justify">
                        Cuando usted realice el pago de un servicio a través de los Sitios, deberá cobrar al cliente final
                        una comisión de la cual, una parte será para usted y la otra, le será retenida de su saldo por Grupo Zorro
                        al momento de realizar el pago de Servicio por concepto de uso de la plataforma.
                        </p>

                        <h4 class="card-title" style="font-size: 13px; text-align: justify;" id="1">
                        5.- Terminación, Cancelación y Suspensión del Servicio
                        </h4>
                        <p style="font-size: 13px; text-align: justify">
                        Mientras usted use el Servicio, el mismo continuará vigente. En caso de que Grupo Zorro decida cancelar
                        el Servicio, le notificará dicha situación para que usted consuma el saldo pendiente.
                        </p>

                        <h4 class="card-title" style="font-size: 13px; text-align: justify;" id="1">
                        6.- Seguridad y Contraseña de Acceso
                        </h4>
                        <p style="font-size: 13px; text-align: justify">
                        Usted es responsable del resguardo y discreción de sus claves de acceso y, por lo tanto, deslinda a
                        Grupo Zorro de cualquier responsabilidad.
                        </p>

                        <h4 class="card-title" style="font-size: 13px; text-align: justify;" id="1">
                        7.- Legislación y Jurisdicción Aplicables
                        </h4>
                        <p style="font-size: 13px; text-align: justify">
                        Para la interpretación, ejecución y cumplimiento los presentes Términos de Servicio, las Partes
                        se someten a la jurisdicción de los Tribunales y Legislación Federal de los Estados Unidos Mexicanos
                        con sede en la Ciudad de México.
                        </p>

                        <h4 class="card-title" style="font-size: 13px; text-align: justify;" id="8">
                        8.- Acuerdo total
                        </h4>
                        <p style="font-size: 13px; text-align: justify">
                        Las Partes aceptan y suscriben el presente Contrato mediante su aceptación electrónica, al dar click
                        en la casilla de “He leído y acepto”, o bien, mediante la selección de cualquier otro ícono o cuadro
                        de diálogo de los Sitios que a criterio de cualquier persona se entienda que son aceptados los presentes Términos de Servicio.
                        Se entiende que a través de la ACEPTACIÓN ELECTRÓNICA usted de manera expresa ha consentido dar cumplimiento
                        y obligarse en términos de este contrato a partir de la fecha de aceptación del presente.
                        </p>
                      </div>
                      <div style="font-size: 12px; text-align: justify; margin-left: 22px; font-weight: 900;">
                       Contrato de Pago de Servicios.
                      </div>
                      <div style="font-size: 12px; text-align: justify; margin-left: 22px; font-weight: 900;">
                        Versión 1.0 | 18 de noviembre de 2020.
                      </div>
                    </div>
                  </div>
              </div>
                <div class="col-md-12"><div class="row"></div></div>
              </div>
            </div>
          </div>
          `,
          confirmButtonColor: '#FF0000',
          showCancelButton: true,
          confirmButtonText: 'He leído y acepto!',
          cancelButtonText: 'Regresar!',
          reverseButtons: true,
          allowOutsideClick: false,
        })
        .then(async (result) => {
          if (result.value) {
            const respuesta: any = await this.service.aceptarTerminos();
          } else {
            this.router.navigateByUrl('/tae');
          }
        })
    }
  }

  openDialog(content) {
    this.modalService.open(content, {size: 'sm'});
  }

  onPaste(event: ClipboardEvent) {
    return false;
  }

  handlePage(e: PageEvent) {
    this.pageSize = e.pageSize;
    this.pageNumber = e.pageIndex + 1;
  }

  cargarSegmentos() {
    this.service.cargarSegmentos().subscribe((res: any) => {
      res.forEach((item, index, array) => {
        switch (item.id) {
          case 1: // Catálogos
            res[index].imageUrl = 'assets/images/pas/Catalogos.png';
            res[index].text = 'AVON - JAFRA - FULLER';
            break;
          case 2: // Servicios
            res[index].imageUrl = 'assets/images/pas/Servicios.png';
            res[index].text = 'CFE - INFONAVIT - CDMX';
            break;
          case 3: // Telefonía
            res[index].imageUrl = 'assets/images/pas/Telefonia.png';
            res[index].text = 'TELMEX - MAXCOM - AXTEL';
            break;
          case 4: // Televisión
            res[index].imageUrl = 'assets/images/pas/Television.png';
            res[index].text = 'TOTALPLAY - IZZI - DISH';
            break;
          case 5: // Transporte
            res[index].imageUrl = 'assets/images/pas/Transporte.png';
            res[index].text = 'TELEVÍA - IAVE - OMNIBUS';
            break;
          case 6: // Digitales
            res[index].imageUrl = 'assets/images/pas/Digitales.png';
            res[index].text = 'SPOTIFY - NETFLIX - XBOX';
            break;
          case 7: // Digitales
            res[index].imageUrl = 'assets/images/pas/Digitales.png';
            res[index].text = 'SPOTIFY - NETFLIX - XBOX';
            break;
          default:
            break;
        }
      });
      this.segments = res;
    });
  }

  cargarProductos(id: number) {
    this.service.cargarProductos(id).subscribe((res: any) => {
      this.products = res;
    });
  }

  cargarServicio(selectedValue: string) {
    const product = this.products.filter((item) => {
      return item.txnId === selectedValue;
    });
    this.freeAmounts = product[0].fixedAmountsEnabled;
    let image = product[0].referenceImage.split("/");
    let image2 = image[image.length-1];
    this.imageRef = 'assets/images/references/' + image2;
    console.log(image2);
    if (this.freeAmounts) {
      this.amountsList = product[0].fixedAmounts
        .replace('[', '')
        .replace(']', '')
        .split(',');
    }
  }

  async obtenerComision(amount: number) {
    this.disabledmode = true;
    this.pas.amountCents = amount;
    try {
      this.commission = await this.service.obtenerComision(this.pas); // .subscribe(pago => {
    } catch (error) {
      console.log(error);
      Swal.fire({
        title: `Realizar Pago de Servicio Falló`,
        text: `${error.error.statusText}`,
        type: 'error',
        html: `
          <div id="demo">
            <small><b>Error: ${error.error.status}</b></small><br>
              <small><b>Mensaje: ${error.error.message}</b></small><br>
          </div>
        `,
        confirmButtonColor: '#FF0000',
        confirmButtonText: 'Aceptar!',
      });
    }

    this.disabledmode = false;
  }

  async obtenerComisionPas(pas: Pas) {
    this.disabledmode = true;
    try {
      this.commission = await this.service.obtenerComision(this.pas); // .subscribe(pago => {
    } catch (error) {
      console.log(error);
      Swal.fire({
        title: `Realizar Pago de Servicio Falló`,
        text: `${error.error.statusText}`,
        type: 'error',
        html: `
          <div id="demo">
            <small><b>Error: ${error.error.status}</b></small><br>
              <small><b>Mensaje: ${error.error.message}</b></small><br>
          </div>
        `,
        confirmButtonColor: '#FF0000',
        confirmButtonText: 'Aceptar!',
      });
    }

    this.disabledmode = false;
  }

  async obtenerSaldo() {
    const balance: any = await this.serviceBalance.obtener();
    console.log('PasComponent -> obtenerSaldo -> balance', balance);

    const tmp: Balance[] = balance.filter((sl) => sl.productType === 'SERVICE_PAYMENT');
    this.saldo = tmp[0];
    console.log('PasComponent: obtenerSaldo: saldo: ', this.saldo);

    /*return this.serviceBalance.obtener().subscribe((saldo) => {
      const tmp: Balance[] = saldo.filter(
        (sl) => sl.productType === 'SERVICE_PAYMENT'
      );
      this.saldo = tmp[0];
    });*/
  }

  async realizarPagoServicio(f: NgForm) {
    console.log(f.value, "form");
    console.log(this.pas.productTxnId, "form");
    this.disabledmode = true;
    if (f.invalid) {
      Swal.fire({
        title: `Pago de Servicio`,
        html: `Verifique la información ingresada`,
        type: 'error',
        confirmButtonColor: '#FF0000',
        confirmButtonText: 'Aceptar!',
      });
      this.disabledmode = false;
      return;
    } else {
      const valid =
        f.value.productTxnId === 0 || f.value.amountCents === 0 ? false : true;

      if (!valid) {
        Swal.fire({
          title: `Pago de Servicio`,
          html: `Verifique la información ingresada`,
          type: 'error',
          confirmButtonColor: '#FF0000',
          confirmButtonText: 'Aceptar!',
        });
        this.disabledmode = false;
        return;
      }
      this.disabledmode = true;
    }

    if (this.freeAmounts) {
      this.pas.amountCents = this.radioGroupForm.value.model;
    } else {
      this.pas.amountCents = this.pas.amountCents;
    }
    this.pas.janoUUID = this.commission.janoUUID;
    const tmpPas = this.pas;

    this.pas = new Pas(0, '', '', 0, '', 0, 0, 0, 0);
    this.filteredPas = new Pas(0, '', '', 0, '', 0, 0, 0, 0);

    try {
      this.pago = await this.service.realizarPagoServicio(tmpPas); // .subscribe(pago => {

      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: 'my-button btn-lg btn-block',
          cancelButton: 'my-button btn-lg btn-block',
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: ``,
          text: ``,
          // type: "error",
          width: '430px',
          html: `
          <div class="modal-pago" *ngIf="showModalSuccess">
            <div class="form-pago-exitosa">
              <div class="row" style="border-bottom: 1px solid #333333;">
                    <div class="col-md-12">
                        <div class="avatar text-center">
                            <img src="assets/images/websale/succsess-icon.png" alt="Avatar">
                        </div>
                    </div>
                    <div class="col-md-12">
                        <h2 class="text-center login-title "
                            style="color: #FF0000; font-size: 40px;margin: 10px 0 25px; font-family: 'Dom Diagonal BT';">¡Tu
                            cobro fué realizado!</h2>
                    </div>
                    <div class="col-md-12">
                        <div class="row" style="border-bottom: 1px solid #333333;">
                            <div class="col">
                                <div class="form-group" style="margin-bottom: 10px; font-family: 'Roboto Light'; font-size: 14px;">
                                    <p class="label-input text-center title-sction" style="margin: 0.5rem 0px;">Aprobación</p>
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-group" style="margin-bottom: 10px; font-family: 'Roboto Light'; font-size: 14px;">
                                    <p class="label-input text-center title-sction" style="margin: 0.5rem 0px;">${this.pago.approvalCode}</p>
                                </div>
                            </div>
                        </div>
                        <div class="row" style="border-bottom: 1px solid #333333;">
                            <div class="col">
                                <div class="form-group" style="margin-bottom: 10px; font-family: 'Roboto Light'; font-size: 14px;">
                                    <p class="label-input text-center title-sction" style="margin: 0.5rem 0px;">Referencias adicionales</p>
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-group" style="margin-bottom: 10px; font-family: 'Roboto Light'; font-size: 14px;">
                                    <p class="label-input text-center title-sction" style="margin: 0.5rem 0px;">${this.pago.additionalReferences}</p>
                                </div>
                            </div>
                        </div>
                        <div class="row" style="border-bottom: 1px solid #333333;">
                            <div class="col">
                                <div class="form-group" style="margin-bottom: 10px; font-family: 'Roboto Light'; font-size: 14px;">
                                    <p class="label-input text-center title-sction" style="margin: 0.5rem 0px;">Referencia</p>
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-group" style="margin-bottom: 10px; font-family: 'Roboto Light'; font-size: 14px;">
                                    <p class="label-input text-center title-sction" style="margin: 0.5rem 0px;">${this.pago.paymentReference}</p>
                                </div>
                            </div>
                        </div>
                        <div class="row" style="border-bottom: 1px solid #333333;">
                            <div class="col">
                                <div class="form-group" style="margin-bottom: 10px; font-family: 'Roboto Light'; font-size: 14px;">
                                    <p class="label-input text-center title-sction" style="margin: 0.5rem 0px;">Servicio</p>
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-group" style="margin-bottom: 10px; font-family: 'Roboto Light'; font-size: 14px;">
                                    <p class="label-input text-center title-sction" style="margin: 0.5rem 0px;">${this.pago.productName}</p>
                                </div>
                            </div>
                        </div>
                        <div class="row" style="border-bottom: 1px solid #333333;">
                            <div class="col">
                                <div class="form-group" style="margin-bottom: 10px; font-family: 'Roboto Light'; font-size: 14px;">
                                    <p class="label-input text-center title-sction" style="margin: 0.5rem 0px;">Importe del Servicio</p>
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-group" style="margin-bottom: 10px; font-family: 'Roboto Light'; font-size: 14px;">
                                    <p class="label-input text-center title-sction" style="margin: 0.5rem 0px;">${this.pago.amountLabel}</p>
                                </div>
                            </div>
                        </div>
                        <div class="row" style="border-bottom: 1px solid #333333;">
                            <div class="col">
                                <div class="form-group" style="margin-bottom: 10px; font-family: 'Roboto Light'; font-size: 14px;">
                                    <p class="label-input text-center title-sction" style="margin: 0.5rem 0px;">Comisión</p>
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-group" style="margin-bottom: 10px; font-family: 'Roboto Light'; font-size: 14px;">
                                    <p class="label-input text-center title-sction" style="margin: 0.5rem 0px;">${this.pago.commissionLabel}</p>
                                </div>
                            </div>
                        </div>
                        <div class="row" style="border-bottom: 1px solid #333333;">
                            <div class="col">
                                <div class="form-group" style="margin-bottom: 10px; font-family: 'Roboto Light'; font-size: 14px;">
                                    <p class="label-input text-center title-sction" style="margin: 0.5rem 0px;">Referencias adicionales</p>
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-group" style="margin-bottom: 10px; font-family: 'Roboto Light'; font-size: 14px;">
                                    <p class="label-input text-center title-sction" style="margin: 0.5rem 0px;">${this.pago.additionalReferences}</p>
                                </div>
                            </div>
                        </div>
                        <div class="row" style="border-bottom: 1px solid #333333;">
                            <div class="col">
                                <div class="form-group" style="margin-bottom: 10px; font-family: 'Roboto Light'; font-size: 14px;">
                                    <p class="label-input text-center title-sction" style="margin: 0.5rem 0px;">Fecha</p>
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-group" style="margin-bottom: 10px; font-family: 'Roboto Light'; font-size: 14px;">
                                    <p class="label-input text-center title-sction" style="margin: 0.5rem 0px;">${this.pago.creationDateLabel}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
          `,
          confirmButtonColor: '#FF0000',
          showCancelButton: false,
          confirmButtonText: 'Realizar otro Cobro!!',
          cancelButtonText: 'Voucher!',
          reverseButtons: true,
          allowOutsideClick: false,
        })
        .then((result) => {
          if (result.value) {
            document.getElementById('printButton').click();
            swalWithBootstrapButtons
              .fire({
                title: '',
                input: 'text',
                html: `
              <div class="modal-pago" *ngIf="showModalSuccess">
                <div class="form-pago-exitosa">
                  <div class="row" style="border-bottom: 1px solid #333333;">
                        <div class="col-md-12">
                            <div class="avatar text-center">
                                <img src="assets/images/websale/succsess-icon.png" alt="Avatar">
                            </div>
                        </div>
                        <div class="col-md-12">
                            <h2 class="text-center login-title "
                                style="color: #FF0000; font-size: 40px;margin: 10px 0 25px; font-family: 'Dom Diagonal BT';">¡Enviar Recibo!</h2>
                        </div>
                        <div class="col-md-12">
                            <div class="row">
                            </div>
                        </div>
                  </div>
                </div>
              </div>
            `,
                inputAttributes: {
                  autocapitalize: 'off',
                },
                showCancelButton: false,
                cancelButtonText: 'Cancelar',
                confirmButtonText: 'Enviar',
                showLoaderOnConfirm: true,
                preConfirm: (email) => {
                  const data = {
                    clientUUID: '',
                    clientVersion: '',
                    email: `${email}`,
                    janoUUID: `${tmpPas.janoUUID}`,
                  };
                  const token: string = localStorage.getItem('token');
                  return fetch(`${this.API_URL.CATALOGS}/send-customer-receipt`, {
                    method: 'POST',
                    headers: {
                      Authorization: `Bearer ${token}`,
                      sessionToken: `${token}`,
                      'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(data),
                  })
                    .then((response) => {
                      if (!response.ok) {
                        throw new Error(response.statusText);
                      }
                      return response;
                    })
                    .catch((error) => {
                      Swal.showValidationMessage(`Request failed: ${error}`);
                    });
                },
                allowOutsideClick: () => !Swal.isLoading(),
              })
              .then((result) => {
                if (result.value) {
                  swalWithBootstrapButtons.fire({
                    title: 'Correo enviado!',
                    confirmButtonColor: '#FF0000',
                    confirmButtonText: 'Aceptar!!',
                    allowOutsideClick: false,
                  });
                }
              });
            this.router.navigate(['/pas']);
          }
        });
    } catch (error) {
      console.log(error);
      Swal.fire({
        title: `Realizar Pago de Servicio Falló`,
        text: `${error.error.statusText}`,
        type: 'error',
        html: `
          <div id="demo">
            <small><b>Error: ${error.error.status}</b></small><br>
              <small><b>Mensaje: ${error.error.message}</b></small><br>
          </div>
        `,
        confirmButtonColor: '#FF0000',
        confirmButtonText: 'Aceptar!',
      });
    }
    this.pas = new Pas(0, '', '', 0, '', 0);
    this.commission = {};
    this.radioDeselect();
    this.obtenerSaldo();
    this.disabledmode = false;
  }

  seleccionarRadio(pas: Pas) {
    const controlName = 'mn-' + pas.productTxnId;
    const elemento = document.getElementById(controlName).click(); // ..checked = true;
  }

  radioDeselect() {
    const inputs = $('input');
    for (let i = inputs.length - 1; i >= 0; i--) {
      if (inputs[i].getAttribute('type') === 'radio') {
        inputs[i].checked = false;
      }
    }
  }
}
