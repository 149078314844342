import { HostBinding, OnInit, Component } from '@angular/core';
import { SettingsService } from './services/service.index';

// Session
// import { NgxPermissionsService } from 'ngx-permissions';

@Component({
  selector: 'jano-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'Jano Sales';

  @HostBinding('class.application') class = 'application';
  public constructor(
    public _ajustes: SettingsService // , private permissionsService: NgxPermissionsService
  ) {}

  ngOnInit(): void {}
}
