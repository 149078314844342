import { Component, OnInit } from '@angular/core';

declare function init_plugins();

@Component({
  selector: 'jano-pagenotfound',
  templateUrl: './pagenotfound.component.html',
  styles: []
})
export class PagenotfoundComponent implements OnInit {
  anio: number = new Date().getFullYear();
  constructor() {}

  ngOnInit() {
    init_plugins();
  }
}
