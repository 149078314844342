<div class="row animated fadeIn">
    <div class="col-md-9">
        <div class="card card-body">
            <h3 class="box-title m-b-0">Usuarios</h3>
            <p class="text-muted m-b-30 font-13"> {{ titulo }} </p>
            <div class="row">
                <div class="col-sm-12 col-xs-12">
                    <form #f="ngForm" ngNativeValidate (ngSubmit)="guardar(f)">
                        <!-- <div class="form-group" *ngIf="crear"> -->
                        <div class="form-group">
                            <label for="name">Username</label>
                            <input [(ngModel)]="usuario.storeId" id="username" name="username" type="text" class="form-control" placeholder="Ingrese nombre de usuario" [readOnly]="usernameDisabled" required>
                        </div>
                        <div class="form-group">
                            <label for="name">Nombre</label>
                            <input [(ngModel)]="usuario.name" id="name" name="name" type="text" class="form-control" placeholder="Ingrese nombre" required>
                        </div>
                        <div class="form-group">
                            <label for="name">Apellido paterno</label>
                            <input [(ngModel)]="usuario.lastname1" id="lastname1" name="lastname1" type="text" class="form-control" placeholder="Ingrese apellido paterno" required>
                        </div>
                        <div class="form-group">
                            <label for="name">Apellido materno</label>
                            <input [(ngModel)]="usuario.lastname2" id="lastname2" name="lastname2" type="text" class="form-control" placeholder="Ingrese apellido materno" required>
                        </div>
                        <!-- <div class="form-group" *ngIf="crear"> -->
                        <div class="form-group">
                            <label for="name">Email</label>
                            <input [(ngModel)]="usuario.email" id="email" name="email" type="email" class="form-control" placeholder="Ingrese email" [readOnly]="emailDisabled" required>
                        </div>
                        <!-- <div class="form-group">
                            <label for="admin">Rol</label>
                            <select [(ngModel)]="usuario.roleId" id="roleId" name="roleId" class="form-control" required>
                                <option value="">Elija una opción</option>
                                <option *ngFor="let rol of roles" [value]="rol.id">{{ rol.name }}</option>
                            </select>
                        </div> -->
                        <div class="form-group">
                            <label for="admin">Estatus</label>
                            <select [(ngModel)]="usuario.status" id="status" name="status" class="form-control" required>
                                <option value="">Elija una opción</option>
                                <option *ngFor="let status of availableStatus" [value]="status.key">{{ status.name }}
                                </option>
                                <!-- <option value="DISABLED">Inactivo</option> -->
                            </select>
                        </div>
                        <!-- <input type="hidden" [(ngModel)]="usuario.status" id="status" name="status" value="DISABLED"> -->
                        <button type="submit" class="btn btn-danger waves-effect waves-light m-r-10"><i
                                class="fa fa-edit">&nbsp;</i>Guardar</button>
                        <a routerLink="/users" class="btn btn-inverse waves-effect waves-light">Cancelar</a>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>