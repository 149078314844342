<!-- ============================================================== -->
<!-- Preloader - style you can find in spinners.css                 -->
<!-- ============================================================== -->

<div class="preloader" *ngIf="cargando">
  <div class="loader">
    <div class="loader__figure"></div>
    <p class="loader__label">Cargando...</p>
  </div>
</div>

<div class="row animated" *ngIf="!cargando">
  <div class="col-12">
    <div class="card">
      <form #f="ngForm" ngNativeValidate (ngSubmit)="buscarPorRangoFecha()">
        <div class="card-body">
          <h4 class="card-title">Búsqueda TDC/TDC</h4>
          <h6 class="card-subtitle">
            Elija la <code>Fecha Inicial</code> y <code>Fecha Final</code>
          </h6>
          <br />
          <div class="row">
            <div class="col-md-4">
              <label for="fechaFinal">Fecha Inicial</label>
              <div class="btn-group bootstrap-select">
                <input
                  type="text"
                  class="form-control"
                  placeholder="dd/mm/yyyy"
                  id="fechaInicial"
                  name="fechaInicial"
                  [(ngModel)]="fechaInicial"
                  ngbDatepicker
                  #fi="ngbDatepicker"
                  required
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-outline-secondary no-shadow"
                    (click)="fi.toggle()"
                    type="button"
                  >
                    <i class="fa fa-calendar"></i>
                  </button>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <label for="fechaFinal">Fecha Final</label>
              <div class="btn-group bootstrap-select">
                <input
                  class="form-control"
                  placeholder="dd/mm/yyyy"
                  id="fechaFinal"
                  name="fechaFinal"
                  [(ngModel)]="fechaFinal"
                  ngbDatepicker
                  #ff="ngbDatepicker"
                  required
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-outline-secondary no-shadow"
                    (click)="ff.toggle()"
                    type="button"
                  >
                    <i class="fa fa-calendar"></i>
                  </button>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <label for="buscar">&nbsp;</label>
              <div class="text-left">
                <button
                  type="submit"
                  class="btn waves-effect waves-light btn-rounded btn-danger"
                >
                  <i class="fa fa-search">&nbsp;</i>Buscar
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<div class="row animated fadeIn" *ngIf="!cargando">
  <div class="col-12">
    <div class="card">
      <div class="card-body" *ngIf="tddArr.length > 0">
        <!-- <h3 class=" card-titil">
          Usuarios ( <small>{{ tddArr.length }}</small> )
        </h3> -->

        <div class="row" style="width: 100%;">
          <div class="col-6 d-inline-block">
            <h3 class=" card-titil">
              Usuarios ( <small>{{ tddArr.length }}</small> )
            </h3>
          </div>
          <div class="col-6 text-right">
            <button
              type="button"
              (click)="descargarReportePorFiltros()"
              class="btn waves-effect waves-light btn-rounded btn-danger ml-2"
            >
              <i class="fa fa-file-pdf-o">&nbsp;</i>Descargar
            </button>
          </div>
        </div>

        <table class="table table-hover">
          <thead>
            <tr>
              <th class="col-md-auto">Id transacción</th>
              <th class="col-md-auto">Fecha</th>
              <th class="col-md-auto">Monto</th>
              <th class="col-md-auto">Meses(MSI</th>
              <th class="col-md-auto">Tipo de Cuenta</th>
              <th class="col-md-auto">Banco</th>
              <th class="col-md-auto">Estatus</th>
              <th class="col-md-auto">Observaciones</th>
            </tr>
          </thead>

          <tbody>
            <tr *ngFor="let tdd of tddArr | paginate: pageSize:pageNumber">
              <td>{{ tdd.janoReference }}</td>
              <td>{{ tdd.txnLocalDateTime }}</td>
              <td>{{ tdd.amount }}</td>
              <td>{{ tdd.msi }}</td>
              <td>{{ tdd.accountType }}</td>
              <td>{{ tdd.bank }}</td>
              <td>{{ tdd.result }}</td>
              <td>{{ tdd.statusinfo }}</td>
            </tr>
          </tbody>
        </table>
        <mat-paginator
          showFirstLastButtons="true"
          [length]="tddArr.length"
          [pageSize]="pageSize"
          [pageSizeOptions]="pageSizeOptions"
          (page)="handlePage($event)"
        >
        </mat-paginator>
      </div>
    </div>
  </div>
</div>
