export class User {
  constructor(
    public redId?: string,
    public storeId?: string,
    public storePassword?: string,
    public storeUsername?: string,
    public name?: string,
    public email?: string,
    public password2?: string,
    public lastname1?: string,
    public lastname2?: string,
    public condiciones?: string,
    public roleId?: number,
    public status?: string,
    public img?: string,
    public id?: number,
    public roleName?: string
  ) { }
}
