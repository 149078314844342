import { Component, OnInit } from '@angular/core';
import {
  NgbDateStruct,
  NgbDateAdapter,
  NgbDateNativeAdapter,
  NgbDate,
  NgbCalendar,
  NgbDateParserFormatter
} from '@ng-bootstrap/ng-bootstrap';
import { PageEvent } from '@angular/material/paginator';
import { Pasr } from '../../models/pasr.model';
import { PasrService } from '../../services/reports/pasr.service';
import { NgbDateParserFormatterEsMX } from '../../config/dateformat';
import { Item } from 'src/app/models/catalogItems.model';

@Component({
  selector: 'jano-pasr',
  templateUrl: './pasr.component.html',
  providers: [
    { provide: NgbDateParserFormatter, useClass: NgbDateParserFormatterEsMX }
  ],
  styleUrls: ['./pasr.component.css']
})
export class PasrComponent implements OnInit {
  model: NgbDateStruct;

  cargando: boolean = true;
  fechaInicial: NgbDate;
  fechaFinal: NgbDate;
  estatusKey: string = '0';
  statusArr: Item[] = [];
  pasr: Pasr;
  pasrArr: Pasr[] = [];

  pageSize: number = 10;
  pageNumber: number = 1;
  pageSizeOptions = [1, 5, 10, 50, 100];

  constructor(public service: PasrService, private calendar: NgbCalendar) {}

  ngOnInit() {
    this.cargando = false;
    this.fechaInicial = this.calendar.getToday();
    this.fechaFinal = this.calendar.getToday();
    this.buscarPorRangoFecha();
    this.obtenerFiltroStatus();
  }

  obtenerFiltroStatus() {
    this.cargando = true;
    this.service.obtenerFiltroStatus().subscribe((res: any) => {
      this.statusArr = res;
      console.log('obtenerFiltroStatus: statusArr: ', this.statusArr);
      this.cargando = false;
    });
  }

  buscarPorRangoFecha() {
    console.log(
      'buscarPorRangoFecha: fechaInicial: ',
      this.fechaInicial,
      ', fechaFinal',
      this.fechaFinal
    );

    const fechaInicial =
      (this.fechaInicial.day <= 9
        ? '0' + this.fechaInicial.day
        : this.fechaInicial.day) +
      '' +
      (this.fechaInicial.month <= 9
        ? '0' + this.fechaInicial.month
        : this.fechaInicial.month) +
      '' +
      this.fechaInicial.year;
    const fechaFinal =
      (this.fechaFinal.day <= 9
        ? '0' + this.fechaFinal.day
        : this.fechaFinal.day) +
      '' +
      (this.fechaFinal.month <= 9
        ? '0' + this.fechaFinal.month
        : this.fechaFinal.month) +
      '' +
      this.fechaFinal.year;
    console.log(
      'buscarPorRangoFecha: (F) fechaInicial: ',
      fechaInicial,
      ', fechaFinal: ',
      fechaFinal
    );

    this.cargando = true;
    this.service
      // .buscarPorRangoFecha(fechaInicial, fechaFinal, this.estatusKey)
      .buscarPorRangoFecha(fechaInicial, fechaFinal)
      .subscribe((res: any) => {
        // const pasrtmp: Pasr[] = res;
        // this.pasrArr = pasrtmp.map(pasr => {
        //   pasr.amount = pasr.amount / 100;
        //   return pasr;
        // });
        this.pasrArr = res;
        console.log(this.pasrArr);
        this.cargando = false;
      });
  }

  descargarReportePorFiltros() {
    // console.log('descargarReportePorFiltros: filtrosTelefonia: ', this.filtrosTelefonia);
    // console.log('descargarReportePorFiltros: fechaInicial: ', this.fechaInicial, ', fechaFinal', this.fechaFinal);

    const fechaInicial =
      (this.fechaInicial.day <= 9
        ? '0' + this.fechaInicial.day
        : this.fechaInicial.day) +
      '' +
      (this.fechaInicial.month <= 9
        ? '0' + this.fechaInicial.month
        : this.fechaInicial.month) +
      '' +
      this.fechaInicial.year;
    // this.filtrosTelefonia.startDate = fechaInicial;

    const fechaFinal =
      (this.fechaFinal.day <= 9
        ? '0' + this.fechaFinal.day
        : this.fechaFinal.day) +
      '' +
      (this.fechaFinal.month <= 9
        ? '0' + this.fechaFinal.month
        : this.fechaFinal.month) +
      '' +
      this.fechaFinal.year;
    // this.filtrosTelefonia.endDate = fechaFinal;

    // console.log('descargarReportePorFiltros: (F) filtrosTelefonia: ', this.filtrosTelefonia);
    // console.log('descargarReportePorFiltros: (F) fechaInicial: ', fechaInicial, ', fechaFinal', fechaFinal);

    this.cargando = true;
    this.service
      .descargarReportePorFiltros(fechaInicial, fechaFinal)
      .subscribe((res: any) => {
        console.log('descargarReportePorFiltros: id archivo: ', res);
        window.open(res);
        this.cargando = false;
      });
  }

  handlePage(e: PageEvent) {
    this.pageSize = e.pageSize;
    this.pageNumber = e.pageIndex + 1;
  }
}
