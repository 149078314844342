import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';

import { User } from 'src/app/models/user.model';
import { UserService } from 'src/app/services/service.index';
import { Router, ActivatedRoute } from '@angular/router';
import { AvailableStatus } from '../../models/availableStatus.model';

@Component({
  selector: 'jano-user',
  templateUrl: './user.component.html',
  styles: []
})
export class UserComponent implements OnInit {

  usuarios: User[] = [];
  // roles: Role[] = [];

  availableStatus: AvailableStatus[] = [];
  usuario: User = new User('', '', '', '');
  titulo: string;
  crear: boolean = false;
  usernameDisabled: boolean = false;
  emailDisabled: boolean = false;

  constructor(
    public service: UserService,
    public router: Router,
    public activatedRoute: ActivatedRoute
  ) {

    activatedRoute.params.subscribe(params => {

      const id = params.id;
      // console.log('constructor: id: ', id);

      if (id !== 'new') {
        this.titulo = 'Actualización de nuevo usuario';
        this.cargar(id);
        this.usernameDisabled = true;
        this.emailDisabled = true;
      } else {
        this.crear = true;
        this.titulo = 'Creación de nuevo usuario';
        this.usernameDisabled = false;
        this.emailDisabled = false;
      }
      this.estatusDisponible();

    });

  }

  ngOnInit() { }

  estatusDisponible() {
    this.service.estatusDisponible()
      .subscribe((res: any) => {
        this.availableStatus = res;
      });
  }

  guardar(f: NgForm) {
    // console.log('guardar: f: ', f);
    if (f.invalid) {
      return;
    }

    this.service.guardar(this.usuario)
      .subscribe(usuario => {

        console.log('UserComponent -> guardar -> usuario', usuario);
        this.router.navigate(['/users']);

      });

  }

  cargar(id: number) {
    this.service.obtener(id)
      .subscribe(usuario => this.usuario = usuario);
  }
}
