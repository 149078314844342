import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
// import { BASE_URL } from 'src/app/config/config';
// import { BASE_CATALOGS_URL } from 'src/app/config/config';
import { catchError, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2';
import { Pas } from '../../models/pas.model';
import * as uuid from 'uuid';
import { environment } from '../../../environments/environment';

declare var $: any;

// import { CurrencyPipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class PasService {

  API_URL: any = environment.ENDPOINTS;

  adjustment: Pas;
  token: string;
  isPrinting = false;

  constructor(public http: HttpClient, public router: Router) {
  }

  cargar() {
    const url = `${this.API_URL.SALES}/pasproducts`; // BASE_URL + '/pasproducts';
    console.log('url: ', url);
    return this.http.get(url).pipe(
      map((res: any) => {
        console.log('res: ', res);
        return res;
      }),
      catchError(this.handleError<Pas[]>('Tiempo Aire', []))
    );
  }

  cargarSegmentos() {
    const url = `${this.API_URL.CATALOGS}/segments`; // BASE_CATALOGS_URL + '/segments';
    console.log('url: ', url);
    return this.http.get(url).pipe(
      map((res: any) => {
        // console.log('cargarSegmentos: res: ', res);
        return res;
      }),
      catchError(this.handleError<Pas[]>('Cargar Segmentos', []))
    );
  }

  cargarProductos(id: number) {
    console.log('cargarProductos: id: ', id);
    const url = `${this.API_URL.CATALOGS}/segment/${id}/products`; // BASE_CATALOGS_URL + '/segment/' + id + '/products';
    console.log('cargarProductos: url: ', url);
    return this.http.get(url).pipe(
      map((res: any) => {
        // console.log('cargarProductos: res: ', res);
        return res;
      }),
      catchError(this.handleError<Pas[]>('Cargar Productos', []))
    );
  }

  obtenerComision(pas: Pas) {
    console.log('obtenerComision: pas: ', pas);
    // const url = BASE_CATALOGS_URL + '/prepareTransaction';
    // console.log('obtenerComision: url: ', url);
    const url = `${this.API_URL.CATALOGS}/prepareTransaction`;
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders().set('Content-Type', 'application/json');
      this.http
        .post(url, {
          amountDecimal: pas.amountCents,
          clientUUID: pas.clientUUID,
          clientVersion: pas.clientVersion,
          paymentReference: pas.paymentReference,
          productTxnId: pas.productTxnId
        })
        .subscribe(
          res => {
            console.log('save: res: ', res);
            resolve(res);
          },
          err => {
            console.log('save 2: err: ', err);
            reject(err);
          }
        );

      //   return this.http
      //     .post(url, {
      //       amountDecimal: pas.amountCents,
      //       clientUUID: pas.clientUUID,
      //       clientVersion: pas.clientVersion,
      //       paymentReference: pas.paymentReference,
      //       productTxnId: pas.productTxnId
      //     })
      //     .pipe(
      //       map((commission: any) => {
      //         console.log('obtenerComision: commission: ', commission);
      //         return commission;
      //       }),
      //       catchError(this.handleError<Pas[]>('Obtener Comisión', []))
      //     );
    });
  }

  confirmarTransaccion(ajuste: Pas) {
    // console.log('confirmarTransaccion: ajuste: ' + JSON.stringify(ajuste));
    const url = `${this.API_URL.SALES}/api/servicepayment/prepareTransaction`; // BASE_URL + '/api/servicepayment/prepareTransaction';
    return this.http.post(url, ajuste).pipe(
      map((res: any) => {
        // console.log('confirmarTransaccion: res: ' + JSON.stringify(res));
        return res;
      }),
      catchError(this.handleError<Pas[]>('Tiempo Aire', []))
    );
  }

  realizarAjuste(ajuste: Pas) {
    // console.log('realizarAjuste: ajuste: ', ajuste);
    const url = `${this.API_URL.SALES}/adjustment`; // BASE_URL + '/adjustment';
    return this.http.post(url, ajuste).pipe(
      map((res: any) => {
        Swal.fire({
          title: 'Tiempo Aire!',
          text: 'El ajuste ha sido realizado.',
          type: 'success',
          confirmButtonColor: '#FF0000',
          confirmButtonText: 'Aceptar!'
        });
        return res;
      }),
      catchError(this.handleError<Pas[]>('Tiempo Aire', []))
    );
  }

  realizarPagoServicio0(pas: Pas) {
    console.log('realizarPagoServicio: pas: ', pas);
    // const amount = pas.amountCents / 100;
    // console.log('realizarPagoServicio: amount: ', amount);
    // const url = `${this.API_URL.CATALOGS}/applyTransaction`; // BASE_CATALOGS_URL + '/applyTransaction';

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'my-button btn-lg btn-block'
        // ,
        // cancelButton: 'my-button btn-lg btn-block'
      },
      buttonsStyling: false
    });

    return this.http
      .post(`${this.API_URL.CATALOGS}/applyTransaction`, {
        amountDecimal: pas.amountCents,
        clientUUID: uuid.v4(),
        clientVersion: 'V7',
        janoUUID: pas.janoUUID,
        paymentReference: pas.paymentReference,
        productTxnId: pas.productTxnId
      })
      .pipe(
        map((pago: any) => {
          console.log('realizarPagoServicio: pago: ', pago);

          swalWithBootstrapButtons
            .fire({
              title: ``,
              text: ``,
              // type: "error",
              width: '430px',
              html: `
          <div class="modal-pago" *ngIf="showModalSuccess">
            <div class="form-pago-exitosa">
              <div class="row" style="border-bottom: 1px solid #333333;">
                    <div class="col-md-12">
                        <div class="avatar text-center">
                            <img src="assets/images/websale/succsess-icon.png" alt="Avatar">
                        </div>
                    </div>
                    <div class="col-md-12">
                        <h2 class="text-center login-title "
                            style="color: #FF0000; font-size: 40px;margin: 10px 0 25px; font-family: 'Dom Diagonal BT';">¡Tu
                            cobro fué realizado!</h2>
                    </div>
                    <div class="col-md-12">
                        <div class="row" style="border-bottom: 1px solid #333333;">
                            <div class="col">
                                <div class="form-group" style="margin-bottom: 10px; font-family: 'Roboto Light'; font-size: 14px;">
                                    <p class="label-input text-center title-sction" style="margin: 0.5rem 0px;">Aprobación</p>
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-group" style="margin-bottom: 10px; font-family: 'Roboto Light'; font-size: 14px;">
                                    <p class="label-input text-center title-sction" style="margin: 0.5rem 0px;">${pago.approvalCode}</p>
                                </div>
                            </div>
                        </div>
                        <div class="row" style="border-bottom: 1px solid #333333;">
                            <div class="col">
                                <div class="form-group" style="margin-bottom: 10px; font-family: 'Roboto Light'; font-size: 14px;">
                                    <p class="label-input text-center title-sction" style="margin: 0.5rem 0px;">Referencia</p>
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-group" style="margin-bottom: 10px; font-family: 'Roboto Light'; font-size: 14px;">
                                    <p class="label-input text-center title-sction" style="margin: 0.5rem 0px;">${pago.janoRetrievalId}</p>
                                </div>
                            </div>
                        </div>
                        <div class="row" style="border-bottom: 1px solid #333333;">
                            <div class="col">
                                <div class="form-group" style="margin-bottom: 10px; font-family: 'Roboto Light'; font-size: 14px;">
                                    <p class="label-input text-center title-sction" style="margin: 0.5rem 0px;">Servicio</p>
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-group" style="margin-bottom: 10px; font-family: 'Roboto Light'; font-size: 14px;">
                                    <p class="label-input text-center title-sction" style="margin: 0.5rem 0px;">${pago.amount}</p>
                                </div>
                            </div>
                        </div>
                        <div class="row" style="border-bottom: 1px solid #333333;">
                            <div class="col">
                                <div class="form-group" style="margin-bottom: 10px; font-family: 'Roboto Light'; font-size: 14px;">
                                    <p class="label-input text-center title-sction" style="margin: 0.5rem 0px;">Importe del Servicio</p>
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-group" style="margin-bottom: 10px; font-family: 'Roboto Light'; font-size: 14px;">
                                    <p class="label-input text-center title-sction" style="margin: 0.5rem 0px;">${pago.totalAmount}</p>
                                </div>
                            </div>
                        </div>
                        <div class="row" style="border-bottom: 1px solid #333333;">
                            <div class="col">
                                <div class="form-group" style="margin-bottom: 10px; font-family: 'Roboto Light'; font-size: 14px;">
                                    <p class="label-input text-center title-sction" style="margin: 0.5rem 0px;">Comisión</p>
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-group" style="margin-bottom: 10px; font-family: 'Roboto Light'; font-size: 14px;">
                                    <p class="label-input text-center title-sction" style="margin: 0.5rem 0px;">$ ${pago.commission}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
                `,
              confirmButtonColor: '#FF0000',
              showCancelButton: false,
              confirmButtonText: 'Realizar otro Cobro!!',
              cancelButtonText: 'Voucher!',
              reverseButtons: true
            })
            .then(result => {
              if (result.value) {
                // swalWithBootstrapButtons.fire(
                //   'Deleted!',
                //   'Your file has been deleted.',
                //   'success'
                // );
                // $(document).ready(() => {
                //   $('button').click(() => {
                //     // var div = $('div');
                //     // div.animate({ left: '100px' }, 'slow');
                //     // div.animate({ fontSize: '5em' }, 'slow');
                //   });
                // });
                this.router.navigate(['/pas']);
              }
              // else if (
              //   /* Read more about handling dismissals below */
              //   result.dismiss === Swal.DismissReason.cancel
              // ) {
              // //   swalWithBootstrapButtons.fire(
              // //     'Cancelled',
              // //     'Your imaginary file is safe :)',
              // //     'error'
              // //   );
              // }
            });
          // .then(result => {
          //   // console.log("realizarVentaTAE: clic botón");
          //   if (result.value) {
          //     this.router.navigate(['/tae']);
          //   }
          // });

          return pago;
        }),
        catchError(this.handleError<Pas[]>('Tiempo Aire', []))
      );
    // console.log('realizarAjuste: tae: ', tae);
  }

  realizarPagoServicio2(pas: Pas) {
    console.log('realizarPagoServicio: pas: ', pas);
    // const amount = pas.amountCents / 100;
    // console.log('realizarPagoServicio: amount: ', amount);
    const url = `${this.API_URL.CATALOGS}/applyTransaction`; // BASE_CATALOGS_URL + '/applyTransaction';
    console.log('realizarPagoServicio: url: ', url);

    return new Promise((resolve, reject) => {
      this.http.post(url, pas).subscribe(
        data => {
          console.log('realizarPagoServicio2: data: ', data);
          resolve(data);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  validarTerminos() {
    const url = `${this.API_URL.CATALOGS}/serviceContractAcceptance`;
    return new Promise((resolve, reject) => {
      this.http.get(url).subscribe(
        respuesta => {
          resolve(respuesta);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  aceptarTerminos() {
    const url = `${this.API_URL.CATALOGS}/serviceContractAcceptance`;
    return new Promise((resolve, reject) => {
      this.http.post(url, null).subscribe(
        respuesta => {
          resolve(respuesta);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  realizarPagoServicio(pas: Pas) {
    // console.log('realizarPagoServicio: pas: ', pas);
    // const url = `${this.API_URL.CATALOGS}/applyTransaction`; // BASE_CATALOGS_URL + '/applyTransaction';
    // console.log('realizarPagoServicio: url: ', url);
    return new Promise((resolve, reject) => {
      // const headers = new HttpHeaders().set('Content-Type', 'application/json');
      console.log(pas);
      this.http
        .post(`${this.API_URL.CATALOGS}/applyTransaction`, {
          amountDecimal: pas.amountCents,
          clientUUID: '',
          clientVersion: '',
          janoUUID: pas.janoUUID,
          paymentReference: pas.paymentReference,
          productTxnId: pas.productTxnId
        })
        .subscribe(
          res => {
            console.log('realizarPagoServicio: res: ', res);
            resolve(res);
          },
          err => {
            console.log('realizarPagoServicio: err: ', err);
            reject(err);
          }
        );
    });
  }

  /**
   * Maneja la operación Http que falla.
   * Permite a una operación continuar.
   * @param operation - Nombre de la operación que falló (Texto descriptivo)
   * @param result - Valor opciones que regresa el resultado como un Observable
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      // console.error('ESTE ES EL ERROR: ', error.error.message); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} Falló: ${error.error.message}`);
      // <small><b>Message: ${error.error.message}</b></small><br>
      // <small><b>AuthorizerResponseCode: ${error.error.authorizerResponseCode}</b></small><br>
      // <small><b>AuthorizerResponseMessage: ${error.error.authorizerResponseMessage}</b></small><br>

      Swal.fire({
        title: `${operation} Falló`,
        text: `${error.error.message}`,
        type: 'error',
        html: `
          <div id="demo">
            <small><b>Error: ${error.error.error}</b></small><br>
              <small><b>Mensaje: ${error.error.message}</b></small><br>
          </div>
              `,
        confirmButtonColor: '#FF0000',
        confirmButtonText: 'Aceptar!'
      });

      // Let the app keep running by returning an empty result.
      // return of(result as T);
      return null;
    };
  }
}
