<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<section id="wrapper">
        <div class="login-register" style="background-image:url(assets/images/background/login-register.jpg);">
            <div class="login-box card">
                <div class="card-body">
                    <!-- <form class="form-horizontal form-material" id="loginform" action="index.html"> -->
                    <form ngNativeValidate [formGroup]="forma" (ngSubmit)="cambiarContrasena()" class="form-horizontal form-material" id="loginform">
                        <h3 class="box-title m-b-20">Cambiar contraseña</h3>
                        <div class="form-group ">
                            <div class="col-xs-12">
                                <input formControlName="password" name="password" class="form-control" type="password" required placeholder="Contraseña">
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="col-xs-12">
                                <input formControlName="password2" name="password2" class="form-control" type="password" required placeholder="Confirma tu contraseña">
                            </div>
                        </div>
                        <div class="form-group text-center p-b-10">
                            <div class="col-xs-12">
                                <button class="btn btn-info btn-lg btn-block text-uppercase waves-effect waves-light" type="submit">Generar</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
    <!-- ============================================================== -->
    <!-- End Wrapper -->
    <!-- ============================================================== -->