import { Component, OnInit, ViewChild } from '@angular/core';

import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';

import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from '../services/service.index';
// Session
// import { SessionTimerService } from 'session-expiration-alert';

declare function init_plugins();

@Component({
  selector: 'jano-pages',
  templateUrl: './pages.component.html',
  styles: [
    `
      button {
        background: aqua;
        font-size: 24px;
      }
    `
  ]
})
export class PagesComponent implements OnInit {
  // alertAt = 15;
  // startTimer = true;

  // constructor(
  //   public sessionTimer: SessionTimerService
  // ) { }

  // ngOnInit() {
  //   init_plugins();
  // }

  // increase() {
  //   this.alertAt++;
  // }

  // toggletimer() {
  //   this.startTimer = !this.startTimer;
  // }

  cargando: boolean = true;
  idleState = 'No iniciada.';
  timedOut = false;
  lastPing?: Date = null;
  sessionTime: number;
  uncouple = true;

  public modalRef: BsModalRef;

  @ViewChild('childModal', { static: false }) childModal: ModalDirective;

  constructor(
    // public sessionTimer: SessionTimerService,
    // private permissionsService: NgxPermissionsService
    private idle: Idle,
    private keepalive: Keepalive,
    private router: Router,
    private modalService: BsModalService,
    private userService: UserService,
    public activatedRoute: ActivatedRoute
  ) {
    activatedRoute.params.subscribe(params => {
      this.uncouple = params.uncouple;
      // console.log('constructor: uncouple: ', this.uncouple);
    });

    this.userService.obtenerTiempoSesion().subscribe(res => {
      this.sessionTime = res;
      console.log('constructor: sessionTime: ', this.sessionTime);

      // const t = Math.round(Math.random() * 2);
      // console.log('constructor: t: ', t * 5);
      // idle.setIdle(this.sessionTime * 60); // 20mins
      idle.setIdle(this.sessionTime); // 20mins
    });

    // establece un tiempo de espera inactivo de 20mins (valor en segundos)

    // establece un tiempo de espera de 15 segundos. Después de 20mins y 15 segundos
    // e inactividad, el usuario se considerará sin actividad.
    idle.setTimeout(15); // 15 seg

    // establece las interrupciones predeterminadas, en este caso, cosas como clics, desplazamientos, toques del documento, navegación, etc
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onIdleEnd.subscribe(() => {
      this.idleState = 'Ya no está inactivo.';
      console.log(this.idleState);
      this.reset();
    });

    idle.onTimeout.subscribe(() => {
      this.childModal.hide();
      this.idleState = '¡Caducado!';
      this.timedOut = true;
      console.log(this.idleState);
      this.logout();
    });

    idle.onIdleStart.subscribe(() => {
      this.idleState = '¡Te has quedado inactivo!';
      console.log(this.idleState);
      this.childModal.show();
    });

    idle.onTimeoutWarning.subscribe(countdown => {
      this.idleState = '¡Tiempo de espera en ' + countdown + ' segundos!';
      console.log(this.idleState);
    });

    // establece el intervalo de ping en 15 segundos
    keepalive.interval(15);

    keepalive.onPing.subscribe(() => (this.lastPing = new Date()));

    this.userService.getUserLoggedIn().subscribe(userLoggedIn => {
      if (userLoggedIn) {
        idle.watch();
        this.timedOut = false;
      } else {
        idle.stop();
      }
    });
  }

  ngOnInit() {
    init_plugins();
  }

  reset() {
    this.idle.watch();
    // this.idleState = 'Continuar firmado...';
    this.timedOut = false;
  }

  hideChildModal(): void {
    this.childModal.hide();
  }

  stay() {
    this.childModal.hide();
    this.reset();
  }

  logout() {
    this.childModal.hide();
    this.userService.logout();
  }

  sidebarView() {
    // console.log("sidebarView: sidebarOnOff: ", sidebarOnOff);
    // this.sidebarOnOff = sidebarOnOff;
    // console.log("sidebarView: this.sidebarOnOff: ", sidebarOnOff);
    window.open('http://localhost:5000/#', '_blank');
  }
}
