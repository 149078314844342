import { Injectable } from '@angular/core';
import { Observable, Subject, timer } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { Clock } from '../../models/clock.model';

@Injectable({
  providedIn: 'root',
})
export class ClockService {
  clock: Observable<Date>;
  infodate$ = new Subject<Clock>();
  vr: Clock;
  ampm: string;
  hours: number;
  minute: string;
  weekday: string;
  months: string;

  constructor() {
    this.clock = timer(0, 1000).pipe(
      map(t => new Date()),
      shareReplay(1),
    );
  }

  getClockData(): Observable<Clock> {
    this.clock.subscribe(t => {
      this.hours = t.getHours() % 12;
      this.hours = this.hours ? this.hours : 12;
      this.vr = {
        hour: this.hours < 10 ? '0' + this.hours : this.hours.toString(),
        minutes:
          t.getMinutes() < 10
            ? '0' + t.getMinutes()
            : t.getMinutes().toString(),
        ampm: t.getHours() > 11 ? 'PM' : 'AM',
        dayandmonth: t
          .toLocaleString('es-MX', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          })
          // .toLocaleString('es-MX', { day: '2-digit', month: 'long' })
          .replace('.', '')
          .replace('-', ' '),
        dayofweek: t
          .toLocaleString('es-MX', {weekday: 'long'})
          .replace('.', ''),
        seconds:
          t.getSeconds() < 10
            ? '0' + t.getSeconds()
            : t.getSeconds().toString(),
      };
      this.infodate$.next(this.vr);
    });
    return this.infodate$.asObservable();
  }
}
