<!--<div class="clock">
  <p>{{dayandmonth}}</p>
  <p>{{dayofweek}}</p>
  <div class="hours">
    <div class="horas">
      <span class="mishoras">{{hour}}:{{minutes}}</span>
      <span class="ampm">{{ampm}}</span>
    </div>
  </div>
  <div></div>
  <span></span>
  <span class="segundos">{{seconds}}</span>
</div>-->

<div>{{dayandmonth}} {{hour}}:{{minutes}}:{{seconds}}</div>
