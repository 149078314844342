import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { ChangePasssword } from '../models/changePassword';
import { UserService } from 'src/app/services/service.index';
import { Router } from '@angular/router';

declare function init_plugins();

@Component({
  selector: 'jano-recover-password-request',
  templateUrl: './recover-password-request.component.html',
  styles: []
})
export class RecoverPasswordRequestComponent implements OnInit {
  forma: FormGroup;

  constructor(public service: UserService, public router: Router) {}

  ngOnInit() {
    init_plugins();

    this.forma = new FormGroup(
      {
        storeUserName: new FormControl(null, Validators.required),
        redId: new FormControl(null, Validators.required)
      }
      // ,solicitarCambioContrasena
      // { validators: this.sonIguales('password', 'password2') }
    );
  }

  solicitarCambioContrasena() {
    console.log('solicitarCambioContrasena: forma valida', this.forma.valid);

    if (this.forma.invalid) {
      Swal.fire({
        title: 'Importante!',
        text: 'Datos incorrectos, intente nuevamente!',
        type: 'warning',
        confirmButtonColor: '#FF0000',
        confirmButtonText: 'Cool!'
      });
    }

    const changePwd = {
      "storeUserName" : this.forma.value.storeUserName,
      "redId": this.forma.value.redId
    };
    console.log(changePwd);
    this.service.solicitarCambioContrasena(changePwd).subscribe(res => {
      // console.log('UserComponent -> guardar -> changePwd', changePwd);
      this.router.navigate(['/login']);
    });
  }
}
