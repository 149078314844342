import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PageEvent } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, NgForm } from '@angular/forms';
import { Tae } from '../../models/tae.model';
import { Balance } from '../../models/balance.model';
import { TaeService } from '../../services/sales/tae.service';
import { BalanceService } from '../../services/balance/balance.service';
import Swal from 'sweetalert2';
import { DOCUMENT } from '@angular/common';
// import { BASE_URL } from '../../config/config';
declare var $: any;

@Component({
  selector: 'jano-tae',
  templateUrl: './tae.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./tae.component.css'],
})
export class TaeComponent implements OnInit {
  taes: Tae[] = [];
  element: HTMLElement;
  amountList: string[] = [];
  defaultChoice = '';

  showImgCorrecto: boolean = false;
  showModalSuccess: boolean = false;
  phoneSuccess: string = '';
  saldoSuccess: string = '';
  referenciaSuccess: string = '';
  aprovacionSuccess: string = '';
  productoSuccess: string = '';

  radioGroupForm: FormGroup;
  tae: Tae = new Tae(
    0,
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    0,
    [],
    '',
    ''
  );
  filteredTae: Tae = new Tae(
    0,
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    0,
    [],
    '',
    ''
  );
  txnIdSelected: String;
  saldo: Balance = new Balance(0, '', 0, 0, '', '', '', 0, '', '');
  selectedStatus = 'ENABLED';

  pageSize = 10;
  pageNumber = 1;
  pageSizeOptions = [1, 5, 10, 50, 100];

  model = {
    left: true,
    middle: false,
    right: false,
  };

  model1 = 1;
  radioSelected: string;
  disabledmode: boolean = false;
  recarga: any = [];
  amount: any = 0;

  constructor(
    public service: TaeService,
    public serviceBalance: BalanceService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    @Inject(DOCUMENT) document,
    private modalService: NgbModal
  ) {
  }

  ngOnInit() {
    // ActivatedRoute.params.subscribe(params => {

    //   const search = params.search;
    //   console.log('constructor: search: ', search);
    //   // this.customerSearch = new CustomerSearch('', '', '', '', '', '', '');

    //   if (search) {
    // console.log(this.customerSearch);
    // this.titulo = 'Actualización de nuevo tae';
    this.cargar();
    this.obtenerSaldo().then(() => {
      console.log('TaeComponent: ngOnInit: Se obtuvo el saldo!!!')
    });
    // this.curpDisabled = true;
    // 1this.redIdDisabled = true;
    // } else {

    // }

    // });

    this.radioGroupForm = this.formBuilder.group({
      model: 1,
    });
  }

  onPaste(event: ClipboardEvent) {
    return false;
  }

  handlePage(e: PageEvent) {
    this.pageSize = e.pageSize;
    this.pageNumber = e.pageIndex + 1;
  }

  cargar() {
    this.service.cargar().subscribe((res: any) => {
      console.log('TaeComponent -> cargar -> res', res);
      const taestmp: Tae[] = res;
      // console.log('TaeComponent -> cargar -> taestmp', taestmp);

      this.taes = taestmp.map((tae) => {
        if (tae.txnId.toUpperCase() === 'TELCELSL') {
          tae.image = 'assets/images/websale/amigo-sl-logo.png';
        } else if (tae.txnId.toUpperCase() === 'TELCEL') {
          // tae.image = 'telcel-logo.png';
          tae.image = 'assets/images/websale/telcel-logo.jpg';
        } else if (tae.txnId.toUpperCase() === 'TELCELSLINT') {
          // tae.image = 'telcel-logo.png';
          tae.image = 'assets/images/websale/amigo-internet.png';
        } else if (tae.txnId.toUpperCase() === 'MOVISTAR') {
          // tae.image = 'movistar-logo.png';
          tae.image = 'assets/images/websale/movistar-logo.jpg';
        } else if (tae.txnId.toUpperCase() === 'UNEFON') {
          tae.image = 'assets/images/websale/unefon-logo.png';
        } else if (tae.txnId.toUpperCase() === 'ATT') {
          tae.image = 'assets/images/websale/att-logo.png';
        } else if (tae.txnId.toUpperCase() === 'VIRGIN') {
          tae.image = 'assets/images/websale/virgin-logo.png';
          // tae.image = 'virgin-logo.svg';
        } else if (tae.txnId.toUpperCase() === 'MOVIINT') {
          tae.image = 'assets/images/websale/movistar-internet.png';
          // tae.image = 'virgin-logo.svg';
        } else if (tae.txnId.toUpperCase() === 'MOVIAPP') {
          tae.image = 'assets/images/websale/movistar-app.png';
          // tae.image = 'virgin-logo.svg';
        } else if (tae.txnId.toUpperCase() === 'MOVIPAB') {
          tae.image = 'assets/images/websale/movistar_paquete_basico.PNG';
          // tae.image = 'virgin-logo.svg';
        } else if (tae.txnId.toUpperCase() === 'MOVIPAC') {
          tae.image = 'assets/images/websale/movistar_paquetes_conectados.PNG';
          // tae.image = 'virgin-logo.svg';
        } else if (tae.txnId.toUpperCase() === 'MOVIPRE') {
          tae.image = 'assets/images/websale/movistar_prepago.PNG';
          // tae.image = 'virgin-logo.svg';
        } else if (tae.txnId.toUpperCase() === 'BAIT') {
          tae.image = 'https://sicobrototal.com.mx/static/carriers/bait.png';
          // tae.image = 'virgin-logo.svg';
        } else if (tae.txnId.toUpperCase() === 'FREEDOM') {
          tae.image = 'https://sicobrototal.com.mx/static/carriers/freedompop.png';
          // tae.image = 'virgin-logo.svg';
        } else if (tae.txnId.toUpperCase() === 'OUI') {
          tae.image = 'https://sicobrototal.com.mx/static/carriers/oui.png';
          // tae.image = 'virgin-logo.svg';
        } else if (tae.txnId.toUpperCase() === 'TELCELILIM') {
          tae.image = 'https://sicobrototal.com.mx/static/carriers/ilimit.png';
          // tae.image = 'virgin-logo.svg';
        } else {
          tae.image = 'assets/images/websale/no-disponible-logo.png';
        }
        return tae;
      });
      // console.log('TaeComponent -> cargar -> taes', this.taes);
    });
  }

  setImage(image) {
    return '';
  }

  cargarMontos(tae: Tae) {
    // console.log('TaeComponent: cargarMontos: tae: ', tae);
    const tmp: Tae[] = this.taes.filter((amList) => amList.txnId === tae.txnId);
    this.filteredTae = tmp[0];
    // console.log('TaeComponent: cargarMontos: amountList: ', tmp[0].amountList);
  }

  guardarMonto(monto: string) {
    //this.headerComponent.cargando= true;
    // console.log('TaeComponent: guardarMonto: monto: ', monto);
    //this.headerComponent.cargando= false;
  }

  async obtenerSaldo() {
    try {
      const balance: any = await this.serviceBalance.obtener();
      console.log('TaeComponent -> obtenerSaldo -> balance', balance);

      const tmp: Balance[] = balance.filter((sl) => sl.productType === 'TAE');
      this.saldo = tmp[0];
      console.log('TaeComponent: obtenerSaldo: saldo: ', this.saldo);


      /*return this.serviceBalance.obtener().subscribe((saldo) => {
        console.log('TaeComponent -> obtenerSaldo -> saldo', saldo);

        //this.router.navigate(['/tae']);
        console.log('TaeComponent: cargarMontos: balanceLabel: ', tmp[0].balanceLabel);
      });*/
    } catch (e) {
      console.log('TaeComponent: obtenerSaldo: e: ', e);
      // this.ngxService.stop();
      // return alerts.serviceNotAvailable();
    }
  }

  async realizarVentaTAE(f: NgForm) {
    this.disabledmode = true;
    if (f.invalid) {
      Swal.fire({
        title: `Recarga`,
        html: `Verifique la información ingresada`,
        type: 'error',
        confirmButtonColor: '#FF0000',
        confirmButtonText: 'Aceptar!',
      });
      this.disabledmode = false;

      return;
    }

    const carrier = this.tae.txnId.length;
    // console.log("realizarVentaTAE: carrier: ", carrier); // this.radioGroupForm.value['model'];
    if (carrier === 0) {
      Swal.fire({
        title: `Recarga`,
        html: `Debe elegir un CARRIER`,
        type: 'error',
        confirmButtonColor: '#FF0000',
        confirmButtonText: 'Aceptar!',
      });
      this.disabledmode = false;

      return;
    }

    this.amount = this.radioGroupForm.value.model;
    if (this.amount === 1) {
      Swal.fire({
        title: `Recarga`,
        html: `Debe elegir un monto para ésta operación`,
        type: 'error',
        confirmButtonColor: '#FF0000',
        confirmButtonText: 'Aceptar!',
      });
      this.disabledmode = false;
      return;
    }

    const tel1 = this.tae.phone1;
    const tel2 = this.tae.phone2;
    if (tel1 !== tel2) {
      Swal.fire({
        title: `Recarga`,
        html: `Numeros de telefono son diferentes<br><small><b>T1: ${tel1}<br>T2: ${tel2}</b></small>`,
        type: 'error',
        confirmButtonColor: '#FF0000',
        confirmButtonText: 'Aceptar!',
      });
      this.disabledmode = false;
      return;
    }

    this.tae.amount = this.radioGroupForm.value.model * 100;
    this.tae.phone = tel1;
    const tmpTae = this.tae;
    // console.log("realizarVentaTAE: tmpTae: ", tmpTae);

    this.tae = new Tae(
      0,
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      0,
      [],
      '',
      ''
    );
    this.filteredTae = new Tae(
      0,
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      0,
      [],
      '',
      ''
    );
    // console.log("realizarVentaTAE: tae: ", this.tae);
    // console.log("realizarVentaTAE: filteredTae: ", this.filteredTae);

    // this.service.realizarVentaTAE(tmpTae).subscribe(recarga => {
    //   this.taes = [];
    //   this.cargar();
    //   this.obtenerSaldo();
    // });

    try {
      this.recarga = await this.service.realizarVentaTAE(tmpTae);
      // console.log('realizarPagoServicio: recarga: ', this.recarga);

      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: 'my-button btn-lg btn-block',
          cancelButton: 'my-button btn-lg btn-block',
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: ``,
          text: ``,
          // type: "error",
          width: '430px',
          html: `
          <div class="modal-recarga" *ngIf="showModalSuccess">
            <div class="form-recarga-exitosa">
              <div class="row" style="border-bottom: 1px solid #333333;">
                    <div class="col-md-12">
                        <div class="avatar text-center">
                            <img src="assets/images/websale/succsess-icon.png" alt="Avatar">
                        </div>
                    </div>
                    <div class="col-md-12">
                        <h2 class="text-center login-title "
                            style="color: #FF0000; font-size: 40px;margin: 10px 0 25px; font-family: 'Dom Diagonal BT';">¡Tu
                            recarga está lista!</h2>
                    </div>
                    <div class="col-md-12">
                        <div class="row" style="border-bottom: 1px solid #333333;">
                            <div class="col">
                                <div class="form-group" style="margin-bottom: 10px; font-family: 'Roboto Light'; font-size: 14px;">
                                    <p class="label-input text-center title-sction" style="margin: 0.5rem 0px;">Aprobación</p>
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-group" style="margin-bottom: 10px; font-family: 'Roboto Light'; font-size: 14px;">
                                    <p class="label-input text-center title-sction" style="margin: 0.5rem 0px;">${this.recarga.approvalCode}</p>
                                </div>
                            </div>
                        </div>
                        <div class="row" style="border-bottom: 1px solid #333333;">
                            <div class="col">
                                <div class="form-group" style="margin-bottom: 10px; font-family: 'Roboto Light'; font-size: 14px;">
                                    <p class="label-input text-center title-sction" style="margin: 0.5rem 0px;">Referencia</p>
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-group" style="margin-bottom: 10px; font-family: 'Roboto Light'; font-size: 14px;">
                                    <p class="label-input text-center title-sction" style="margin: 0.5rem 0px;">${this.recarga.janoRetrievalId}</p>
                                </div>
                            </div>
                        </div>
                        <div class="row" style="border-bottom: 1px solid #333333;">
                            <div class="col">
                                <div class="form-group" style="margin-bottom: 10px; font-family: 'Roboto Light'; font-size: 14px;">
                                    <p class="label-input text-center title-sction" style="margin: 0.5rem 0px;">Número Telefónico</p>
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-group" style="margin-bottom: 10px; font-family: 'Roboto Light'; font-size: 14px;">
                                    <p class="label-input text-center title-sction" style="margin: 0.5rem 0px;">${this.recarga.phone}</p>
                                </div>
                            </div>
                        </div>
                        <div class="row" style="border-bottom: 1px solid #333333;">
                            <div class="col">
                                <div class="form-group" style="margin-bottom: 10px; font-family: 'Roboto Light'; font-size: 14px;">
                                    <p class="label-input text-center title-sction" style="margin: 0.5rem 0px;">Producto</p>
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-group" style="margin-bottom: 10px; font-family: 'Roboto Light'; font-size: 14px;">
                                    <p class="label-input text-center title-sction" style="margin: 0.5rem 0px;">${this.recarga.productName}</p>
                                </div>
                            </div>
                        </div>
                        <div class="row" style="border-bottom: 1px solid #333333;">
                            <div class="col">
                                <div class="form-group" style="margin-bottom: 10px; font-family: 'Roboto Light'; font-size: 14px;">
                                    <p class="label-input text-center title-sction" style="margin: 0.5rem 0px;">Monto</p>
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-group" style="margin-bottom: 10px; font-family: 'Roboto Light'; font-size: 14px;">
                                    <p class="label-input text-center title-sction" style="margin: 0.5rem 0px;">$ ${this.amount}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
              `,
          confirmButtonColor: '#FF0000',
          showCancelButton: false,
          confirmButtonText: 'Realizar otra Recarga!!',
          cancelButtonText: 'Voucher!',
          reverseButtons: true,
          allowOutsideClick: false,
        })
        .then((result) => {
          console.log('realizarVentaTAE: result: ', result);
          if (result.value) {
            document.getElementById('printRecarga').click();
            // swalWithBootstrapButtons
            //   .fire({
            //     title: '',
            //     input: 'text',
            //     html: `
            //   <div class="modal-pago" *ngIf="showModalSuccess">
            //     <div class="form-pago-exitosa">
            //       <div class="row" style="border-bottom: 1px solid #333333;">
            //             <div class="col-md-12">
            //                 <div class="avatar text-center">
            //                     <img src="assets/images/websale/succsess-icon.png" alt="Avatar">
            //                 </div>
            //             </div>
            //             <div class="col-md-12">
            //                 <h2 class="text-center login-title "
            //                     style="color: #FF0000; font-size: 40px;margin: 10px 0 25px; font-family: 'Dom Diagonal BT';">¡Enviar Recibo!</h2>
            //             </div>
            //             <div class="col-md-12">
            //                 <div class="row">
            //                 </div>
            //             </div>
            //       </div>
            //     </div>
            //   </div>
            // `,
            //     inputAttributes: {
            //       autocapitalize: 'off'
            //     },
            //     showCancelButton: false,
            //     cancelButtonText: 'Cancelar',
            //     confirmButtonText: 'Enviar',
            //     showLoaderOnConfirm: true,
            //     preConfirm: email => {
            //       const data = {
            //         clientUUID: '',
            //         clientVersion: '',
            //         email: `${email}`,
            //         janoUUID: `${tmpPas.janoUUID}`
            //       };
            //       console.log('Confirm. data: ', JSON.stringify(data));
            //       const token: string = localStorage.getItem('token');
            //       return fetch(`${BASE_URL}/send-customer-receipt`, {
            //         method: 'POST',
            //         headers: {
            //           Authorization: `Bearer ${token}`,
            //           sessionToken: `${token}`,
            //           'Content-Type': 'application/json'
            //         },
            //         body: JSON.stringify(data)
            //       })
            //         .then(response => {
            //           console.log('RESPONSE: ', response);
            //           if (!response.ok) {
            //             console.log('RESPONSE ERROR: ', response);
            //             throw new Error(response.statusText);
            //           }
            //           console.log('RESPONSE OK: ', response);
            //           return response;
            //         })
            //         .catch(error => {
            //           Swal.showValidationMessage(`Request failed: ${error}`);
            //         });
            //     },
            //     allowOutsideClick: () => !Swal.isLoading()
            //   })
            //   .then(result => {
            //     console.log('RESULT: ', result);
            //     if (result.value) {
            //       swalWithBootstrapButtons.fire({
            //         // title: `${result.value.login}'s avatar`,
            //         title: 'Correo enviado!',
            //         confirmButtonColor: '#FF0000',
            //         confirmButtonText: 'Aceptar!!',
            //         allowOutsideClick: false
            //       });
            //     }
            //   });
          }
        });
    } catch (error) {
      console.log(error);
      Swal.fire({
        title: `Realizar Recarga Falló`,
        text: `${error.error.statusText}`,
        type: 'error',
        html: `
          <div id="demo">
            <small><b>Error: ${error.error.status}</b></small><br>
              <small><b>Mensaje: ${error.error.message}</b></small><br>
          </div>
        `,
        confirmButtonColor: '#FF0000',
        confirmButtonText: 'Aceptar!',
      });
    }
    this.taes = [];
    this.cargar();
    await this.obtenerSaldo();
    this.disabledmode = false;
    await this.router.navigate(['/tae']);
  }

  validaTextos() {
    let cadena = this.tae.phone1;
    let confCadena = this.tae.phone2;
    this.showImgCorrecto =
      cadena.length === 10 && confCadena.length === 10 && cadena === confCadena
        ? true
        : false;
  }

  siguienteCampo(event) {
    console.log(event);
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }

    // keytab(event)
    const element = event.currentTarget.nextElementSibling; // get the sibling element

    setTimeout(() => {
      // console.log('s');
    }, 10000);

    //event.srcElement.attributes.type.value = 'password'; // get the sibling element

    if (element == null) {
      // check if its null
      return;
    } else {
      if (element.value) {
        element.select();
      } else {
        element.focus();
      }
      // focus if not null
      //    // focus if not null
      //return false;
    }
    return true;
  }

  seleccionarCampo($event) {
    $event.target.select();
  }

  seleccionarRadio(tae: Tae) {
    console.log('seleccionarRadio: tae: ', tae);
    const controlName = 'mn-' + tae.txnId;
    console.log('seleccionarRadio: controlName: ', controlName);
    const elemento = document.getElementById(controlName).click(); // ..checked = true;
    console.log('seleccionarRadio: elemento: ', elemento);
  }
}
