<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<section id="wrapper">
    <div class="login-register login-sidebar" style="background-image:url(assets/images/background/login-register.jpg);">
        <div class="login-box card">
            <div class="card-body">

                <form ngNativeValidate [formGroup]="forma" (ngSubmit)="registrarUsuario()" class="form-horizontal form-material" id="loginform" action="index.html">
                    <a href="javascript:void(0)" class="text-center db"><img src="assets/images/logo-icon.png" alt="Home" /><br /><img src="assets/images/logo-text.png" alt="Home" /></a>
                    <h3 class="box-title m-t-40 m-b-0">Regístrate ahora</h3><small>Crea tu cuenta y disfruta</small>
                    <div class="form-group m-t-20">
                        <div class="col-xs-12">
                            <input formControlName="nombre" name="nombre" class="form-control" type="text" required placeholder="Nombre">
                        </div>
                    </div>
                    <div class="form-group ">
                        <div class="col-xs-12">
                            <input formControlName="appat" name="appat" class="form-control" type="text" required placeholder="Apellido Paterno">
                        </div>
                    </div>
                    <!-- <div class="form-group ">
                    <div class="col-xs-12">
                        <input formControlName="apmat" name="apmat" class="form-control" type="text" required placeholder="Apellido Materno">
                    </div>
                </div> -->
                    <div class="form-group ">
                        <div class="col-xs-12">
                            <input formControlName="usuario" name="usuario" class="form-control" type="text" required placeholder="Nombre de usuario">
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="col-xs-12">
                            <input formControlName="password" name="password" class="form-control" type="password" required placeholder="Contraseña">
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="col-xs-12">
                            <input formControlName="password2" name="password2" class="form-control" type="password" required placeholder="Confirma tu contraseña">
                        </div>
                    </div>
                    <div class="form-group" *ngIf="forma.errors?.sonIguales && !forma.pristine">
                        <p class="text-danger">
                            Las contraseñas deben ser iguales
                        </p>
                    </div>

                    <!-- {{ forma.errors | json }} -->
                    <div class="form-group row">
                        <div class="col-md-12">
                            <div class="checkbox checkbox-primary p-t-0">
                                <input formControlName="condiciones" name="condiciones" id="checkbox-signup" type="checkbox">
                                <label for="checkbox-signup"> Estoy de acuerdo con todos los <a
                                        href="#">Términos</a></label>
                            </div>
                        </div>
                    </div>
                    <div class="form-group text-center m-t-20">
                        <div class="col-xs-12">
                            <button class="btn btn-info btn-lg btn-block text-uppercase waves-effect waves-light" type="submit">Regístrate</button>
                        </div>
                    </div>
                    <div class="form-group m-b-0">
                        <div class="col-sm-12 text-center">
                            <p>¿Ya tienes una cuenta? <a routerLink="/login" class="text-info m-l-5"><b>Ingresa</b></a>
                            </p>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>
<!-- ============================================================== -->
<!-- End Wrapper -->
<!-- ============================================================== -->