import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { groupBy } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { UserService } from '../services/service.index';
import { User } from '../models/user.model';

declare function init_plugins();

// const Swal = require('sweetalert2');

@Component({
  selector: 'jano-register',
  templateUrl: './register.component.html',
  styleUrls: ['./login.component.css']
})
export class RegisterComponent implements OnInit {
  forma: FormGroup;

  constructor(public service: UserService) {}

  sonIguales(campo1: string, campo2: string) {
    return (group: FormGroup) => {
      const pwd = group.controls[campo1].value;
      const pwd2 = group.controls[campo2].value;

      if (pwd === pwd2) {
        return null;
      }

      return {
        sonIguales: true
      };
    };
  }

  ngOnInit() {
    init_plugins();

    this.forma = new FormGroup(
      {
        nombre: new FormControl(null, Validators.pattern('[a-zA-z ]*')),
        appat: new FormControl(null, Validators.pattern('[a-zA-z ]*')),
        apmat: new FormControl(null, Validators.pattern('[a-zA-z ]*')),
        usuario: new FormControl(null, Validators.required),
        password: new FormControl(null, Validators.required),
        password2: new FormControl(null, Validators.required),
        // correo: new FormControl(null, [Validators.required, Validators.email]),
        condiciones: new FormControl(false)
      },
      { validators: this.sonIguales('password', 'password2') }
    );

    this.forma.setValue({
      nombre: 'Test',
      appat: 'Appat',
      apmat: 'Apmat',
      usuario: 'user',
      password: '12345',
      password2: '123456',
      condiciones: true
    });
  }

  registrarUsuario() {
    if (this.forma.invalid) {
      if (!this.forma.value.condiciones) {
        console.log('Debe aceptar las condiciones');
        // Swal.fire("Importante!", "Debe aceptar las condiciones!", "warning");
        Swal.fire({
          title: 'Importante!',
          text: 'Debe aceptar las términos y condiciones!',
          type: 'warning',
          confirmButtonColor: '#FF0000',
          confirmButtonText: 'Cool!'
        });
      }
      return;
    }


    console.log('forma valida', this.forma.valid);
    console.log(this.forma.value);

    const usuario = new User(
      this.forma.value.nombre,
      this.forma.value.appat,
      this.forma.value.usuario,
      this.forma.value.password
    );

    // this.service.crearUsuario(usuario)
    //   .subscribe(resp => {
    //     console.log(resp);
    //   });
  }
}
