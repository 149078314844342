import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { UserService } from '../services/service.index';
import { User } from '../models/user.model';
import { Observable } from 'rxjs';
// import { Store } from '@ngrx/store';
// import { Permission } from '../models/permission.model';

// Session
import { NgxPermissionsService } from 'ngx-permissions';
import { HttpClient } from '@angular/common/http';
import { Store } from '../models/store.model';
import Swal from 'sweetalert2';

declare function init_plugins();

@Component({
  selector: 'jano-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  recuerdame: boolean = false;
  redId: string;
  storeId: string;
  storePassword: string;
  usuario: User;
  tienda: Store;
  // cargando: boolean = true;

  $isLoggedIn: Observable<boolean>;
  showPass: boolean = false;
  // disabledmode: boolean = false;

  constructor(
    public router: Router,
    public service: UserService,
    private permissionsService: NgxPermissionsService,
    private http: HttpClient
  ) {}

  ngOnInit() {
    init_plugins();

    this.redId = localStorage.getItem('redId') || '';
    if (this.redId.length > 0) {
      this.recuerdame = true;
    }
  }

  showPassword() {
    this.showPass = !this.showPass;
  }

  ingresar(forma: NgForm) {
    // this.cargando = false;
    this.service.disabledmode = true;
    console.log('disabledmode: ', this.service.disabledmode);
    if (forma.invalid) {
      return;
    }

    this.usuario = new User(
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      0,
      '',
      '',
      0
    );
    this.usuario.redId = forma.value.redId;
    this.usuario.storeUsername = forma.value.storeId;
    this.usuario.storePassword = forma.value.storePassword;
    console.log('ingresar: usuario: ', this.usuario);

    this.service.setLogueado(true);

    this.service
      .login(this.usuario, forma.value.recuerdame)
      .subscribe((user: any) => {
        console.log('Usuario firmado: ', user);
        // console.log('Usuario actionRequired: ', user.actionRequired);
        console.log(
          'Usuario webLoginActionRequired: ',
          user.webLoginActionRequired
        );

        // if (user.webLoginActionRequired === 'ACTIVATE_AFTER_CREATION') {

        //   this.router.navigate(['/create-password']);
        //   return;

        // }

        if (user.loginActionRequired === 'CHANGE_PASSWORD') {
          console.log('Usuario webLoginActionRequired: ENTRO');

          this.router.navigate(['/change-password']);
          this.service.disabledmode = false;
          return;
        }
        // user.loginActionRequired = 'USE_PASSWORD_EMAILED';
        console.log(
          'ingresar: user.loginActionRequired: ',
          user.loginActionRequired
        );

        console.log('ingresar: user.loginActionRequired: ', user.loginActionRequired);
        if (user.loginActionRequired === 'USE_PASSWORD_EMAILED') {
          // console.log('ingresar: USE_PASSWORD_EMAILED...');

          /*
          Bienvenido al nuevo sistema de Si Cobro Total de Grupo Zorro.
          Te hemos enviado un password temporal al correo electrónico que tenemos registrado jdhdfhxxxxxxx@hotmail.com; por favor verifícalo
          */
          //  const  correo = 'correo@correo.com';
          Swal.fire({
            title: `<img src="assets/images/websale/logo-sicobro-homepage.png" alt="" width="110" height="110">`,
            text: `Bienvenido al nuevo sistema de Si Cobro Total de Grupo Zorro.`,
            // type: 'info',
            html: `
           <b>Bienvenido al nuevo sistema <i>Si Cobro Total</i> de Grupo Zorro.</b><br><br>
           Te hemos enviado una contraseña <code>temporal</code> al correo electrónico que tenemos registrado: <b>${user.maskedEmail}</b>, por favor verifícalo<br>
           `,
            confirmButtonColor: '#FF0000',
            confirmButtonText: 'Aceptar!'
          });

          this.service.logout();
          this.router.navigate(['/login']);
          this.service.disabledmode = false;
          return;
        }
        this.service.disabledmode = false;

        this.router.navigate(['/tae']);
        console.log('Usuario webLoginActionRequired: DASHBOARD');
      });
  }
}
