import { Component, OnInit } from '@angular/core';
import { SidebarService } from '../../services/service.index';
import { UserService } from '../../services/service.index';
import { User } from '../../models/user.model';
import { NgxPermissionsService } from 'ngx-permissions';

@Component({
  selector: 'jano-sidebar',
  templateUrl: './sidebar.component.html',
  // styles: []
  styleUrls: ['./sidebar.component.css'],
})
export class SidebarComponent implements OnInit {
  user: User;

  constructor(
    public _sidebar: SidebarService,
    public service: UserService,
    private permissionsService: NgxPermissionsService) { }

  ngOnInit() {
    this.user = this.service.user;

    // console.log('SidebarComponent: ngOnInit: permissions: ', this.user);
    // console.log('SidebarComponent: ngOnInit: permissions: ', this.service.permissions);

    const permissions = this.permissionsService.getPermissions();
    // console.log('ngOnInit: permissions: ', permissions.size);
    this.permissionsService.permissions$.subscribe((perm) => {
      // console.log('ngOnInit: Permiso: ', perm);
    });

    this._sidebar.cargarMenu();
  }
}
