import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PasService } from '../../../services/sales/pas.service';
import Swal from 'sweetalert2';


@Component({
  selector: 'jano-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.css']
})
export class TermsComponent implements OnInit {

  constructor(
    public service: PasService,
    public router: Router,
  ) {
  }

  ngOnInit() {
    this.validarTerminos().then(() => console.log('Términos y condiciones'));
  }

  async validarTerminos() {
    // const respuesta: any = await this.service.validarTerminos();

    /*if (respuesta.accepted === false) {*/
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'my-button my-button2 btn-lg btn-block',
        cancelButton: 'my-button my-button2  btn-lg btn-block',
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: ``,
        text: ``,
        // type: "error",
        width: '992px',
        html: `
          <div class="modal-pago" *ngIf="showModalSuccess">
            <div class="form-pago-exitosa">
              <div class="row" style="border-bottom: 1px solid #333333;">
                <div class="col-md-12">
                  <div class="avatar text-center">
                    <img src="assets/images/websale/succsess-icon.png" alt="Avatar">
                  </div>
                </div>
                <div class="col-md-12">
                  <h2 class="text-center login-title "
                    style="color: #FF0000; font-size: 40px; margin: 10px 0 0px; font-family: 'Dom Diagonal BT';">¡Términos y Condiciones!</h2>
                </div>
                <!--<div class="col-md-12"><div class="row"></div></div>-->
                <div class="row" style="font-weight: 200;">
                  <div class="col-12">
                    <div class="card">
                      <div class="card-body">
                        <p style="font-size: 11px; text-align: justify">
                        Este contrato describe los términos y condiciones generales (en adelante únicamente “TERMINOS
                            YCONDICIONES”) aplicables al uso de los contenidos, productos y servicios ofrecidos a través
                            del sitio www.sicobrototal.com.mx (en adelante “SITIO WEB”), del cual es
                            titular Grupo Zorro Abarrotero, S. de R.L. de C.V. Cualquier persona que desee acceder o
                            hacer uso del sitio o los servicios que en él se ofrecen, podrá hacerlo sujetándose a los
                            presentes TÉRMINOS Y CONDICIONES, así como a las políticas y principios incorporados al
                            presente documento. En todo caso, cualquier persona que no acepte los presentes términos y
                            condiciones, deberá de abstenerse de utilizar el SITIO WEB.
                        </p>
                        <h4 class="card-title" style="font-size: 11px; text-align: justify;" id="1">I.- DEL OBJETO</h4>
                        <p style="font-size: 11px; text-align: justify">
                        El objeto de los presentes TÉRMINOS Y CONDICIONES es regular el acceso y utilización del
                            SITIO WEB, entendiendo por éste cualquier tipo de contenido, producto o servicio que se
                            encuentre a disipación del público en general dentro del dominio: www.sicobrototal.com.mx
                            EL TITULAR se reserva la facultad de modificar en cualquier momento y sin previo aviso, la
                            presentación, los contenidos, la funcionalidad, los productos, los servicios, y la
                            configuración que pudiera estar contenida en el SITIO WEB; en este sentido, el USUSARIO
                            reconoce y acepta que Grupo Zorro Abarrotero, S. de R.L. de C.V. en cualquier momento podrá
                            interrumpir, desactivar o cancelar cualquiera de los elementos que conforman el SITIO WEB o
                            el acceso a los mismos.
                            Al acceso al SITIO WEB por parte del USUSARIO tiene carácter libre y, por regla general es
                            gratuito sin que el USUSARIO tenga que proporcionar una contraprestación para poder
                            disfrutar de ello, salvo en los costos de conexión a internet suministrada por el proveedor
                            de este tipo de servicios que hubiera contratado el mismo USUARIO.
                            El acceso a parte de los contenidos y servicios del SITIO WEB podrá realizarse previa
                            suscripción o registro previo del USUARIO.
                            El SITIO WEB se encuentra dirigido exclusivamente a personas que cuenten con la mayoría de
                            edad (mayores de 18 años); en este sentido, Grupo Zorro Abarrotero, S. de R.L. de C.V.
                            declina cualquier responsabilidad por el incumplimiento de este requisito.
                            EL SITIO WEB está dirigido principalmente a USUARIOS residentes de los Estados Unidos
                            Mexicanos, por lo cual, Grupo Zorro Abarrotero, S. de R.L. de C.V. no asegura que el SITIO
                            WEB cumpla total o parcialmente con la legislación de otros, de forma que, si el USUSARIO
                            reside o tiene domicilio en otro país y decide acceder o utilizar el SITIO WEB lo hará bajo
                            sum propia responsabilidad y deberá asegurase de que tal acceso y navegación cumpla con la
                            legislación local que le es aplicable, no asumiendo Grupo Zorro Abarrotero, S. de R.L. de
                            C.V. ninguna responsabilidad que se pueda derivar de dicho acto.
                            Se hace del conocimiento del USUARIO que el TITULAR podrá administrar o gestionar el SITIO
                            WEB de manera directa o a través de un tercero, lo cual no modifica en ningún sentido lo
                            establecido en los presente TÉRMINOS Y CONDICIONES.
                        </p>

                        <h4 class="card-title" style="font-size: 11px; text-align: justify;" id="1">II.- DEL USUARIO.</h4>
                        <p style="font-size: 11px; text-align: justify">
                        El acceso o utilización del SITIO WEB, así como a los recursos habilitados para interactuar
                            entre los USUARIOS, o entre el USUARIO y EL TITULAR tales como medios para realizar
                            publicaciones o comentarios, confiere la condición de USUARIO del SITIO WEB, por lo que
                            quedará sujeto a los presentes TÉRMINOS Y CONDICIONES, así como a sus ulteriores
                            modificaciones, sin perjuicio de las aplicación de la legislación aplicable, por tanto, se
                            tendrán por aceptados desde el momento en el que se accede al SITIO WEB. Dada la reverencia
                            de lo anterior, se recomienda al USUARIO revisar las actualizaciones que se realicen a los
                            presentes TÉRMINOS Y CONDICIONES.
                            Es responsabilidad del USUARIO utilizar el SITIO WEB de acuerdo a la forma en que se
                            diseñado; en este sentido, que prohibida la utilización de cualquier tipo de cualquier tipo
                            de software que automatice la interacción o descarga de los contenidos o servicios
                            proporcionados a través del SITIO WEB de manera licita, sin contravenir lo dispuesto en los
                            presentes TÉRMINOS Y CONDICIONES, la moral o el orden público, y se abstendrá de realizar
                            cualquier acto que pueda suponer una afectación a los derechos de terceros, o perjudique de
                            algún modo el funcionamiento del SITIO WEB.
                            Así mismo, el usuario se compromete a proporcionar información lícita y veraz en los
                            formularios habilitados en el SITIO WEB, en los cuales el usuario tenga que proporcionar
                            ciertos datos o información para el acceso a algún contenido, producto o servicio ofrecido
                            por el propio SITIO WEB. En todo caso, el USUSARIO notificará de forma inmediata al TITULAR
                            acerca de cualquier hecho que permita suponer el uso indebido de la información registrada
                            en dichos formularios, tales como, robo extravío, o acceso no autorizado a cuenta y/o
                            contraseñas, con el fin de proceder a su inmediata cancelación.
                            Grupo Zorro Abarrotero, S. de R.L. de C.V. se reserva el derecho de retirar todos aquellos
                            comentarios y aportaciones que vulneren la ley, el respeto a la dignidad de la persona, que
                            sean discriminatorios, atender contra los derechos de terceros o el orden público, o bien,
                            que a su juicio no resulten adecuados para su publicación.
                            En cualquier caso, Grupo Zorro Abarrotero, S. de R.L. de C.V. no será responsable de las
                            opiniones vertidas por los USUARIOS a través de comentarios o publicaciones que estos
                            realicen.
                            El sólo acceso al SITIO WEB no supone el establecimiento de ningún tipo de relación entre el
                            TITULAR y el USUARIO.
                            Al tratarse de un SITIO WEB dirigido exclusivamente a personas que cuenten con la mayoría de
                            edad, el USUARIO manifiesta ser mayor de edad y disponer de la capacidad jurídica necesaria
                            para sujetarse a los presentes TÉRMINOS Y CONDICIONES.
                        </p>

                        <h4 class="card-title" style="font-size: 11px; text-align: justify;" id="1">III.- DEL ACCESO Y NAVEGACIÓN EL SITIO WEB.</h4>
                        <p style="font-size: 11px; text-align: justify">
                        EL TITULAR no garantiza de ningún modo la continuidad y disponibilidad de los contenidos,
                            productos o servicios ofrecidos a través del SITIO WEB, no obstante, el TITULAR llevará a
                            cabo las acciones que de acuerdo a sus posibilidades le permitan mantener el buen
                            funcionamiento del SITIO WEB, sin que esto suponga alguna responsabilidad de parte de Grupo
                            Zorro Abarrotero, S. de R.L. de C.V.
                            De igual forma Grupo Zorro Abarrotero, S. de R.L. de C.V. no será responsable ni garantiza
                            que software al que pueda accederse a través del SITIO WEB, se encuentre libre de errores,
                            software malicioso, o que pueda causar algún daño a nivel de software o hardware en el
                            equipo a través del cual el USUARIO accede al SITIO WEB.
                            El TITULAR tampoco se hace responsable de los daños que pudiesen ocasionarse por un uso
                            inadecuado del SITIO WEB. El ningún caso Grupo Zorro Abarrotero, S. de R.L. de C.V. será
                            responsable de las pérdidas, daños o perjuicios de cualquier tipo que surjan por el sólo
                            acceso o utilización del SITIO WEB.
                        </p>

                        <h4 class="card-title" style="font-size: 11px; text-align: justify;" id="1">IV.- POLÍTICA DE PRIVACIDAD Y PROTECCIÓN DE DATOS PERSONALES.</h4>
                        <p style="font-size: 11px; text-align: justify">
                        De conformidad con lo establecido en la Ley Federal de Protección de Datos Personales en
                            Posesión de Particulares, el TITULAR se compromete a adoptar las medidas necesarias que
                            estén a su alcance para asegurar la privacidad de los datos personales recabados de forma
                            que se garantice su seguridad, se evite su alteración, pérdida p tratamiento no autorizado.
                            Además, a efecto de dar cumplimiento a lo establecido de la Ley Federal de Protección de
                            Datos Personales en Posesión de Particulares, todo dato personal que sea recabado a través
                            del SITIO WEB, será tratado de conformidad con los principios de licitud, calidad,
                            finalidad, lealtad, y responsabilidad. Todo tratamiento de datos personales quedará sujeto
                            al consentimiento de su titular. En todo caso, la utilización de datos financieros y
                            personales, requerirán de autorización expresa de sus titulares, no obstante, esta podrá
                            darse a través de propio SITIO WEB utilizando los mecanismos habilitados para tal efecto y
                            en todo caso se dará mayor diligencia y cuidado a este tipo de datos. Lo mismo ocurrirá en
                            el caso de datos personales sensibles, considerando por estos aquellos que debido a una
                            utilización indebida puedan dar origen a discriminación o su divulgación conlleven un riesgo
                            para el titular.
                            En todo momento se procurará que los datos personales contenidos en las bases de datos o
                            archivos que en su caso se utilicen, sea pertinentes, correctos y actualizados para los
                            fines para los cuales fueron recabados.
                            El tratamiento de datos personales se limitará al cumplimiento de las finalidades previstas
                            en el Aviso de Privacidad el cual se encontrará disponible en la siguiente dirección
                            electrónica: _ www.sicobrototal.com.mx
                            El SITIO WEB podrá incluir hipervínculos o enlaces que permitan acceder a páginas de
                            terceros distintos Grupo Zorro Abarrotero, S. de R.L. de C.V. Los titulares de dichos sitios
                            web dispondrán de sus propias políticas de privacidad y protección de datos, por lo cual
                            Grupo Zorro Abarrotero, S. de R.L. de C.V. no asumen ningún tipo de responsabilidad para los
                            datos que sean facilitados por el USUSARIO a través de cualquier sitio distinto a
                            www.sicobrototal.com.mx
                            Grupo Zorro Abarrotero, S. de R.L. de C.V. se reserva el derecho a modificar su Política de
                            Privacidad, de acuerdo a sus necesidades o derivado de algún cambio en la legislación. El
                            acceso o utilización del SITIO WEB después de dichos cambios.
                            Por otra parte, el acceso al SITIO WEB puede implicar la utilización de cookies, las cuales,
                            son pequeñas cantidades de información que se almacenan el navegador utilizado por el
                            USUSARIO. Las cookies facilitan la navegación, la hacen más amigable, y no dañan el
                            dispositivo de navegación, para ello, pueden recabar información para ingresar al SITIO WEB,
                            como por ejemplo: la fecha y hora en la que accede al SITIO WEB, el tiempo que se ha hecho
                            uso de este, los sitios visitados antes y después del mismo, el número de páginas visitadas,
                            la dirección IP de la accede el usuario, la frecuencia de visitas, etc.
                            Este tipo de información será utilizada para mejorar el SITIO WEB, detectar errores, y
                            posibles necesidades que el USUSRIO pueda tener, lo anterior a efecto de ofrecer a los
                            USUARIOS servicios y contenidos de mejor calidad. En todo caso, la información que se
                            recopile será anónima y no se identificará a usuarios individuales.
                            En caso de que el USUARIO no desee que se recopile este tipo de información deberá
                            deshabilitar, rechazar, restringir y/o el uso de cookies en su navegador de internet. Los
                            procedimientos para realizar estas acciones pueden diferir de un navegador a otro; en
                            consecuencia, se sugiere revisar las instrucciones facilitadas por el desarrollador del
                            navegador. En el supuesto de que rechace el uso de cookies (total o parcialmente) el
                            USUSARIO podrá continuar haciendo uso del SITIO WEB, aunque podrían quedar deshabilitadas
                            algunas funciones del mismo.
                            Es posible que en el futuro estas políticas respecto a las cookies cambien o se actualicen,
                            por ello es recomendable revisar las actualizaciones que se realicen a los presentes
                            TÉRMINOS Y CONDICIONES, con el objeto de estar adecuadamente informado sobre cómo y para qué
                            utilizamos las cookies que se generen al ingresar o hacer uso del SITIO WEB.
                        </p>

                        <h4 class="card-title" style="font-size: 11px; text-align: justify;" id="1">V. POLÍTICAS DE ENLACES.</h4>
                        <p style="font-size: 11px; text-align: justify">
                        El SITIO WEB puede contener enlaces, contenidos, servicios o funciones, de otros sitios de
                            internet pertenecientes y/o gestionados por terceros, como por ejemplo imágenes, videos,
                            comentarios, motores de búsqueda, etc.
                            La utilización de estos enlaces, contenidos, servicios o funciones, tiene por objeto mejorar
                            la experiencia del USUSARIO al hacer uso del SITIO WEB, sin que pueda considerarse una
                            sugerencia, recomendación o invitación para hacer uso de sitios externos. Grupo Zorro
                            Abarrotero, S. de R.L. de C.V. en ningún caso revisará o controlará el contenido de los
                            sitios externos, de igual forma, no hace propios los productos, servicios, contenidos, y
                            cualquier otro material existente en los referidos sitios enlazados; por lo cual, tampoco se
                            garantiza la disponibilidad, exactitud, veracidad, validez o legalidad de los sitios
                            externos a los se puedan acceder a través del SITIO WEB. Asimismo, el TITULAR no asume
                            ninguna responsabilidad por los daños y perjuicios que pudieran producirse por el acceso o
                            uso, de los contenidos, productos y servicios disponibles en los sitios web no gestionados
                            por Grupo Zorro Abarrotero, S. de R.L. de C.V a los que se puede acceder mediante el SITIO
                            WEB.
                        </p>

                        <h4 class="card-title" style="font-size: 11px; text-align: justify;" id="1">VI.- POLÍTICA EN MATERIA DE PROPIEDAD INTELECTUAL E INDUSTRIAL.</h4>
                        <p style="font-size: 11px; text-align: justify">
                        Grupo Zorro Abarrotero, S. de R.L. de C.V. por sí o como parte cesionaria, es titular de
                            todos los derechos de propiedad intelectual e industrial del SITIO WEB, entendiendo por este
                            el código fuente que hace posible su funcionamiento así como las imágenes, archivo de audio
                            o video, logotipos, marcas, combinaciones de colores, estructuras, diseños y demás elementos
                            que se distinguen. Serán, por consiguiente, protegidas por la legislación mexicana en
                            materia de propiedad intelectual e industrial, así como por tratados internacionales
                            aplicables. Por consiguiente, queda expresamente prohibida la reproducción, distribución, o
                            difusión de los contenidos del SITIO WEB, con fines comerciales, en cualquier soporte y por
                            cualquier medio, sin la autorización de Grupo Zorro Abarrotero, S. de R.L. de C.V.
                            El USUARIO se compromete a respetar los derechos de propiedad intelectual e industrial del
                            TITULAR. No obstante, además de poder visualizar los elementos del SITIO WEB y que pudiera
                            imprimirlos, copiarlos o almacenarlos el USUSARIO los utilizará exclusivamente y
                            estrictamente para uso personal.
                            Por otro lado, el USUSARIO, se abstendrá de suprimir, alterar, o manipular cualquier
                            elemento, archivo, o contenido del SITIO WEB, y por ningún motivo realizará actos tendientes
                            a vulnerar la seguridad, los archivos o bases de datos que se encuentren protegidos, ya sea
                            a través de acceso restringido mediante un usuario y contraseña, o porque no cuente con los
                            permisos para visualizarlos, editarlos o manipularlos.
                            En caso de que el USUSARIO o en algún tercero consideren que cualquiera de los contenidos
                            del SITIO WEB suponga violación de los derechos de protección de la propiedad industrial o
                            intelectual, deberá comunicarlo inmediatamente a Grupo Zorro Abarrotero, S. de R.L. de C.V.,
                            a través de los datos de contacto disponibles en el propio SITIO WEB.
                        </p>

                        <h4 class="card-title" style="font-size: 11px; text-align: justify;" id="1">VII.- LEGISLACIÓN Y JURISDICCIÓN APLICABLE.</h4>
                        <p style="font-size: 11px; text-align: justify">
                        Grupo Zorro Abarrotero, S. de R.L. de C.V. se reserva la facultad de presentar las acciones
                            civiles o penales que se considere necesarias por la utilización indebida del SITIO WEB, sus
                            contenidos, productos o servicios, o por el incumplimiento de los presentes TÉRMINOS Y
                            CONDICIONES.
                            La relación entre el USUSARIO y Grupo Zorro Abarrotero, S. de R.L. de C.V. se regirá por la
                            legislación vigente en los Estados Unidos Mexicanos. De surgir cualquier controversia en
                            relación a la interpretación y/o aplicación de los presentes TÉRMINOS Y CONDICIONES, las
                            partes se someterán la jurisdicción de los tribunales de la Ciudad de México.
                        </p>
                      </div>
                      <div style="font-size: 10px; text-align: justify; margin-left: 22px; font-weight: 200;">
                       Contrato de Pago de Servicios.
                      </div>
                      <div style="font-size: 10px; text-align: justify; margin-left: 22px; font-weight: 200;">
                        Versión 1.0 | 18 de noviembre de 2020.
                      </div>
                    </div>
                  </div>
              </div>
                <div class="col-md-12"><div class="row"></div></div>
              </div>
            </div>
          </div>
          `,
        confirmButtonColor: '#FF0000',
        showCancelButton: false,
        confirmButtonText: 'He leído y acepto!',
        cancelButtonText: 'Regresar!',
        reverseButtons: true,
        allowOutsideClick: false,
      })
      .then(async (result) => {
        if (result.value) {
          const respuesta: any = await this.service.aceptarTerminos();
          this.router.navigateByUrl('/tae');
        } /*else {
          this.router.navigateByUrl('/tae');
        }*/
      })
    /*}*/
  }

}
