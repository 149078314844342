<section
  style="background: url('assets/images/websale/background-sales.png') no-repeat center center fixed; -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
       width:100%; height:100%; position:fixed; left:0;top:0;overflow:hidden;"
>
  <img
    src="assets/images/websale/logo-sicobro-homepage.png"
    style="position: absolute; left: 10%; top:30%;"
  />
  <div class="login-form">
    <form ngNativeValidate #f="ngForm" class="" (ngSubmit)="ingresar(f)">
      <!-- <div class="form-group center text-center logo">
                <img src="assets/images/websale/logo-zorro-homepage.png" alt="">
            </div> -->
      <!--<div class="avatar">-->
      <!--<img src="assets/images/logo-zorro1.png" alt="Avatar" height="90px" width="140px" >-->
      <!--</div>-->
      <h2
        class="text-center login-title "
        style="color: #FF0000; font-size: 40px;
    margin: 10px 0 25px; font-family: 'Dom Diagonal BT';"
      >
        Inicio de sesión
      </h2>
      <div class="col-xs-12">
        <div class="form-group" style="margin-bottom: 10px">
          <p class="label-input">
            Para poder acceder ingresa los siguientes datos
          </p>
        </div>
      </div>
      <div class="form-group m-t-10" style="margin-bottom: 12px">
        <div class="form-group" style="margin-bottom: 12px">
          <div class="col-xs-12">
            <!--<object type="image/svg+xml" data="assets/images/websale/store-id-icon.png" class="customfa">-->
            <!--&lt;!&ndash; fallback image in CSS &ndash;&gt;-->
            <!--</object>-->
            <!-- <img src="assets/images/websale/store-id-icon.png"
                        style="position: absolute;top: 38%;left: 13px;" /> -->
            <img
              src="assets/images/websale/store-id-icon.png"
              style="position: absolute;top: 38%;left: 13px;"
            />

            <input
              [ngModel]="redId"
              name="redId"
              type="text"
              class="form-control label-input"
              placeholder="N° RED/StoreID"
              required="required"
              style="stroke:40px  #333333 "
            />
          </div>
        </div>

        <div class="form-group" style="margin-bottom: 12px">
          <div class="col-xs-12">
            <!--<object type="image/svg+xml" data="assets/images/websale/user-icon.png" class="customfa">-->
            <!--&lt;!&ndash; fallback image in CSS &ndash;&gt;-->
            <!--</object>-->
            <img
              src="assets/images/websale/user-icon.png"
              style="position: absolute;top: 30%;left: 13px;"
            />

            <input
              [ngModel]="storeId"
              name="storeId"
              class="form-control label-input"
              type="text"
              placeholder="Usuario"
              required
            />
          </div>
        </div>

        <div class="form-group" style="margin-bottom: 12px">
          <div class="col-xs-12">
            <!--<object type="image/svg+xml" data="assets/images/websale/pass-icon.png" class="customfa">-->
            <!--&lt;!&ndash; fallback image in CSS &ndash;&gt;-->
            <!--</object>-->

            <img
              src="assets/images/websale/pass-icon.png"
              style="position: absolute;top: 30%;left: 13px;"
            />

            <input
              [ngModel]="storePassword"
              name="storePassword"
              class="form-control label-input"
              type="{{ showPass ? 'text' : 'password' }}"
              placeholder="Contraseña"
            />
            <span
              (click)="showPassword()"
              toggle="#password-field"
              [ngClass]="showPass ? 'fa-eye' : 'fa-eye-slash'"
              class="fa fa-fw field-icon .icon-left toggle-password"
            ></span>
          </div>
        </div>
        <div class="form-group m-t-20">
          <div class="custom-control custom-checkbox" style="padding-left: 0px">
            <input
              type="checkbox"
              class="custom-control-input"
              [(ngModel)]="recuerdame"
              name="recuerdame"
              id="checkbox-signup"
            />
            <label
              class="custom-control-label label-check"
              for="checkbox-signup"
              style=""
              >Recordarme</label
            >
          </div>
          <a
            routerLink="/recover-password-request"
            id="to-recover"
            class="pull-right label-check"
          >
            Reestablecer Contraseña
            <!--<object type="image/svg+xml" data="assets/images/websale/password-icon.png" class="customfa" style="position: relative; left: 1px; top: 5px;">-->
            <!--&lt;!&ndash; fallback image in CSS &ndash;&gt;-->
            <!--</object>-->

            <img
              src="assets/images/websale/password-icon.png"
              style="position: absolute; margin-top: 5px; margin-left: 10px;"
            />
          </a>
        </div>
        <div class="form-group m-t-40">
          <!--<button type="submit" class="btn btn-primary btn-lg btn-block" style="font-family: 'Roboto light';  font-size: 20px;">Ingresar</button>-->
          <!---->
          <button
            type="submit"
            class="my-button btn-lg btn-block"
            style="font-family: 'Dom Diagonal BT';  font-size: 30px; cursor: pointer;"
            [disabled]="service.disabledmode ? 'disabled' : ''"
          >
            Ingresar
          </button>
        </div>
      </div>
    </form>
    <!-- <div class="form-group m-t-20 center text-center">
            <img src="assets/images/websale/logo-zorro-homepage.png" alt="">
        </div> -->
  </div>
</section>

<!-- ============================================================== -->
<!-- End Wrapper -->
<!-- ============================================================== -->
<script type="text/javascript">
  $(function() {
    $('.preloader').fadeOut();
  });
  $(function() {
    $('[data-toggle="tooltip"]').tooltip();
  });
  // ==============================================================
  // Login and Recover Password
  // ==============================================================
  $('#to-recover').on('click', function() {
    $('#loginform').slideUp();
    $('#recoverform').fadeIn();
  });
</script>
