export class Balance {
  constructor(
    public balance: number,
    public balanceLabel: string,
    public customerId: number,
    public id: number,
    public name: string,
    public productType: string,
    public productTypeLabel: string,
    public storeId: number,
    public storeIdLabel: string,
    public storeName: string
  ) {}
}
