import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UserService } from '../services/service.index';
import Swal from 'sweetalert2';
import { User } from '../models/user.model';
import { Router } from '@angular/router';

declare function init_plugins();

@Component({
  selector: 'jano-create-password',
  templateUrl: './create-password.component.html',
  styles: ['./login.component.css']
})
export class CreatePasswordComponent implements OnInit {
  forma: FormGroup;

  constructor(public service: UserService, public router: Router) {}

  ngOnInit() {
    init_plugins();

    this.forma = new FormGroup(
      {
        password: new FormControl(null, Validators.required),
        password2: new FormControl(null, Validators.required)
      },
      { validators: this.sonIguales('password', 'password2') }
    );

    // this.forma.setValue({
    //   password: '12345',
    //   password2: '123456'
    // });
  }

  sonIguales(campo1: string, campo2: string) {
    return (group: FormGroup) => {
      const pwd = group.controls[campo1].value;
      const pwd2 = group.controls[campo2].value;
      console.log('sonIguales: campo1', pwd, ', campo2', pwd2);

      if (pwd === pwd2) {
        return null;
      }

      return {
        sonIguales: true
      };
    };
  }

  crearContrasena() {
    console.log('crearContrasena: forma valida', this.forma.valid);

    if (this.forma.invalid) {
      Swal.fire({
        title: 'Importante!',
        text: 'Datos incorrectos, intente nuevamente!',
        type: 'warning',
        confirmButtonColor: '#FF0000',
        confirmButtonText: 'Cool!'
      });

      return;
    }

    const usuario = new User('', this.forma.value.password);
    console.log(usuario);
    this.service.crearContrasena(usuario).subscribe(res => {
      // console.log('UserComponent -> guardar -> usuario', usuario);
      this.router.navigate(['/login']);
    });
  }
}
