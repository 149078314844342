export class Tae {
  constructor(
    public id?: number,
    public name?: string,
    public txnId?: string,
    public phone1?: string,
    public phone2?: string,
    public phone3?: string,
    public phone4?: string,
    public phone5?: string,
    public phone6?: string,
    public phone7?: string,
    public phone8?: string,
    public phone9?: string,
    public phone10?: string,
    public phone11?: string,
    public phone12?: string,
    public phone13?: string,
    public phone14?: string,
    public phone15?: string,
    public phone16?: string,
    public phone17?: string,
    public phone18?: string,
    public phone19?: string,
    public phone20?: string,
    public amount?: number,
    public amountList?: string[],
    public image?: string,
    public phone?: string
  ) { }
}
