import { Injectable, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { map, catchError } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import Swal from 'sweetalert2';
import { NgxPermissionsService } from 'ngx-permissions';

import { User } from '../../models/user.model';
import { ChangePasssword } from '../../models/changePassword';
import { Store } from '../../models/store.model';
import { StoreService } from '../store/store.service';
// import { BASE_URL } from '../../config/config';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  API_URL: any = environment.ENDPOINTS;

  user: User;
  tienda: Store;
  token: string;

  roleName: string;
  permissions: string[] = [];
  menu: any = [];
  disabledmode = false;

  private usuarioLogueado = new BehaviorSubject(this.estaLogueado());

  constructor(
    public http: HttpClient,
    public router: Router,
    // private permissionsService: NgxPermissionsService,
    public storeService: StoreService
  ) {
    this.cargarStorage();
  }

  estaLogueado() {
    let res = false;
    if (localStorage.getItem('token')) {
      res = true;
    }
    return res;
  }

  setLogueado(val: boolean) {
    this.usuarioLogueado.next(val);
  }

  cargarStorage() {
    if (localStorage.getItem('token')) {
      this.token = localStorage.getItem('token');
      // console.log('UserService -> cargarStorage -> token', this.token);
      this.user = JSON.parse(localStorage.getItem('user'));
      // console.log('UserService -> cargarStorage -> user', this.user);
      this.menu = JSON.parse(localStorage.getItem('menu'));
      // console.log('UserService -> cargarStorage -> menu', this.menu);
    } else {
      this.token = '';
      this.user = null;
      this.menu = [];
    }
  }

  guardarStorage(token: string, correo: string, user: User, menu: any) {
    localStorage.setItem('token', token);
    localStorage.setItem('email', correo);
    localStorage.setItem('user', JSON.stringify(user));
    localStorage.setItem('menu', JSON.stringify(menu));

    this.user = user;
    this.token = token;
    this.menu = menu;
  }

  login(usuario: User, recuerdame: boolean = false) {
    console.log('login: usuario: ', usuario);

    if (recuerdame) {
      localStorage.setItem('redId', usuario.redId);
      localStorage.setItem('storeUsername', usuario.storeUsername);
    } else {
      localStorage.removeItem('redId');
      localStorage.removeItem('storeUsername');
    }

    const url = `${this.API_URL.SALES}/login-backwardcompatible`;
    return (
      this.http
        // .post(url, { redId: usuario.redId, storeUsername: usuario.storeUsername, storePassword: usuario.storePassword })
        .post(url, usuario)
        .pipe(
          map((user: any) => {
            console.log('UserService: ----> login: usuario: ', user);
            this.user = user;
            const filteredMenu = this.obtenerMenu(this.user);
            this.guardarStorage(
              user.sessionToken,
              user.email,
              user,
              filteredMenu
            );
            return user;
          }),
          catchError(this.handleError<User[]>('Login', []))
        )
    );
    this.disabledmode = false;
  }

  obtenerMenu(permisos: any) {
    // debugger;

    const reportes = [];
    const ventas = [];

    console.log('obtenerMenu: permisos: ', permisos);
    if (permisos) {
      if (permisos.billpocketModuleEnabled) {
        // Cobro con tarjeta
        // console.log('obtenerMenu: TDD: ', permisos.billpocketModuleEnabled);
        reportes.push({
          titulo: 'Cobro con Tarjeta',
          url: '/tdd',
          permiso: 'USER_QUERY'
        });
      }

      if (permisos.taeModuleEnabled) {
        // Tiempo Aire
        // console.log('obtenerMenu: TAE: ', permisos.taeModuleEnabled);
        reportes.push({
          titulo: 'Tiempo Aire',
          url: '/taer',
          permiso: 'USER_QUERY'
        });
        ventas.push({
          titulo: 'Tiempo Aire',
          url: '/tae',
          permiso: 'USER_QUERY'
        });
      }

      if (permisos.servicePaymentModuleEnabled) {
        // Pago de servicios
        reportes.push({
          titulo: 'Pago de Servicios',
          url: '/pasr',
          permiso: 'USER_QUERY'
        });
        ventas.push({
          titulo: 'Pago de Servicios',
          url: '/pas',
          permiso: 'USER_QUERY'
        });
      }
    }

    const menu: any = [
      // {
      //   titulo: 'Dashboard',
      //   icono: 'mdi mdi-folder-lock-open',
      //   submenu: [
      //     { titulo: 'Ventas', url: '/dashboard', permiso: 'USER_QUERY' },
      //   ],
      // },
      {
        titulo: 'Reportes',
        icono: 'mdi mdi-developer-board',
        submenu: reportes //[
        // { titulo: 'Cobro con Tarjeta', url: '/tdd', permiso: 'USER_QUERY' },
        // { titulo: 'Tiempo Aire', url: '/taer', permiso: 'USER_QUERY' },

        //],
      },
      {
        titulo: 'Mis Ventas',
        icono: 'mdi mdi-shopping',
        submenu: ventas //[
        //   { titulo: 'Tiempo Aire', url: '/tae', permiso: 'USER_QUERY' },
        // ],
      }
    ];

    // menu.forEach(mnu => {
    //   mnu.submenu.shift(
    //     (mnu.submenu = mnu.submenu.filter(itm => {
    //       return permisos.includes(itm.permiso);
    //     })),
    //   );
    // });

    // const filteredMenu = menu.filter(mnu => mnu.submenu.length > 0);
    // return filteredMenu;
    return menu;
  }

  logout() {
    this.user = null;
    this.token = '';
    this.menu = [];

    // localStorage.removeItem('username');
    localStorage.removeItem('token');
    localStorage.removeItem('email');
    localStorage.removeItem('user');
    localStorage.removeItem('menu');
    this.setLogueado(false);
    this.router.navigate(['/login']);
  }

  cargar() {
    const url = `${this.API_URL.SALES}/users`;
    return this.http.get(url).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(this.handleError<User[]>('Cargar Usuarios', []))
    );
  }

  borrar(id: number): any {
    const url = `${this.API_URL.SALES}/user/${id}`; // BASE_URL + '/user/' + id;
    return this.http.delete(url).pipe(
      map(res => {
        Swal.fire({
          title: 'Borrado!',
          text: 'El usuario ha sido borrado.',
          type: 'success',
          confirmButtonColor: '#FF0000',
          confirmButtonText: 'Aceptar!'
        });
        return res;
      }),
      catchError(this.handleError<User[]>('Eliminar Usuario', []))
    );
  }

  guardar(usuario: User) {
    // console.log('UserService -> guardar -> usuario', usuario);

    let url = `${this.API_URL.SALES}/user`; // BASE_URL + '/user';
    if (usuario.id > 0) {
      // actualizar

      url += '/' + usuario.id;
      console.log('UserService -> guardar -> url', url);
      return this.http.put(url, usuario).pipe(
        map((res: any) => {
          console.log('UserService -> guardar -> res', res);

          if (usuario.id === this.user.id) {
            const usuarioDB: User = res.usuario;
            this.guardarStorage(
              this.token,
              usuario.email,
              usuarioDB,
              this.menu
            );
          }

          Swal.fire({
            title: 'Actualizado!',
            text: 'El usuario ' + usuario.name + ' ha sido actualizado.',
            type: 'success',
            confirmButtonColor: '#FF0000',
            confirmButtonText: 'Aceptar!'
          });
          return res;
        }),
        catchError(this.handleError<User[]>('Actualizar Usuario', []))
      );
    } else {
      // crear

      return this.http.post(url, usuario).pipe(
        map((res: any) => {
          console.log('UserService -> guardar -> res', res);
          Swal.fire({
            title: 'Creado!',
            text: 'El usuario ' + usuario.name + ' ha sido creado.',
            type: 'success',
            confirmButtonColor: '#FF0000',
            confirmButtonText: 'Aceptar!'
          });
          return res;
        }),
        catchError(this.handleError<User[]>('Nuevo Usuario', []))
      );
    }
  }

  obtener(id: number) {
    const url = `${this.API_URL.SALES}/user/${id}`; // BASE_URL + '/user/' + id;
    return this.http.get(url).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(this.handleError<User[]>('Obtener Usuario', []))
    );
  }

  actualizar(usuario: User) {
    const url = `${this.API_URL.SALES}/user/${usuario.id}`; // BASE_URL + '/user/' + usuario.id;
    return this.http.put(url, usuario).pipe(
      map((res: any) => {
        Swal.fire({
          title: 'Actualizado!',
          text: 'El usuario ' + usuario.name + ' ha sido actualizado.',
          type: 'success',
          confirmButtonColor: '#FF0000',
          confirmButtonText: 'Aceptar!'
        });
        return res;
      }),
      catchError(this.handleError<User[]>('Actualizar Usuario', []))
    );
  }

  crearContrasena(usuario: User) {
    const url = `${this.API_URL.SALES}/user/activation`; // BASE_URL + '/user/activation';
    return this.http.post(url, usuario).pipe(
      map((res: any) => {
        Swal.fire({
          title: 'Crear contraseña!',
          text:
            'Contraseña generada de forma exitosa. Ya puedes ingresar a Jano.',
          type: 'success',
          confirmButtonColor: '#FF0000',
          confirmButtonText: 'Aceptar!'
        });
        return res;
      }),
      catchError(this.handleError<User[]>('Crear Contraseña', []))
    );
  }

  solicitarCambioContrasena(changePwd: any) {
    const url = `${this.API_URL.SALES}/request-change-password`; // BASE_URL + '/request-change-password';
    return this.http.post(url, changePwd).pipe(
      map((res: any) => {
        Swal.fire({
          title: 'Cambio de contraseña',
          text:
            'Hemos enviado al correo registrado su código de activación. La aplicación lo solicitará para hacer el cambio de su contraseña!',
          type: 'warning',
          confirmButtonColor: '#FF0000',
          confirmButtonText: 'Aceptar!'
        }).then(result => {
          if (result.value) {
            return;
          }
        });
        return res;
      }),
      catchError(this.handleError<User[]>('Cambio de Contraseña', []))
    );
  }

  cambiarContrasena(changePwd: ChangePasssword) {
    const url = `${this.API_URL.SALES}/user/password`; // BASE_URL + '/user/password';
    return this.http.put(url, changePwd).pipe(
      map((res: any) => {
        Swal.fire({
          title: 'Cambio de contraseña!',
          text: 'Su contraseña ha sido actualizada con éxito.',
          type: 'success',
          confirmButtonColor: '#FF0000',
          confirmButtonText: 'Aceptar!'
        });
        return res;
      }),
      catchError(this.handleError<User[]>('Cambio de Contraseña', []))
    );
  }

  cambiarContrasenaS(nuevoPwd: string) {
    console.log('cambiarContrasenaS: nuevoPwd: ', nuevoPwd);
    const url = `${this.API_URL.SALES}/change-password`; // BASE_URL + '/change-password';
    return this.http.post(url, { password: nuevoPwd }).pipe(
      map((res: any) => {
        Swal.fire({
          title: 'Cambio de contraseña!',
          text: 'Su contraseña ha sido actualizada con éxito.',
          type: 'success',
          confirmButtonColor: '#FF0000',
          confirmButtonText: 'Aceptar!'
        });
        return res;
      }),
      catchError(this.handleError<User[]>('Cambio de Contraseña', []))
    );
  }

  obtenerTiempoSesion() {
    const url = `${this.API_URL.SALES}/session-time-in-minutes`; // BASE_URL + '/session-time-in-minutes';
    return this.http.get(url).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(this.handleError<User[]>('Obtener estatus', []))
    );
  }

  estatusDisponible() {
    const url = `${this.API_URL.SALES}/user/available-status`; // BASE_URL + '/user/available-status';
    return this.http.get(url).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(this.handleError<User[]>('Obtener estatus', []))
    );
  }

  getUserLoggedIn(): Observable<boolean> {
    return this.usuarioLogueado.asObservable();
  }

  /**
   * Maneja la operación Http que falla.
   * Permite a una operación continuar.
   * @param operation - Nombre de la operación que falló (Texto descriptivo)
   * @param result - Valor opciones que regresa el resultado como un Observable
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      // console.error('ESTE ES EL ERROR: ', error.error.message); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} Falló: ${error}`);
      // console.log(`${operation} Falló: ${error.error.message}`);
      let html = '';

      Swal.fire({
        title: `${operation} Falló`,
        text: `${error.error.message}`,
        type: 'error',
        confirmButtonColor: '#FF0000',
        confirmButtonText: 'Aceptar!'
      });

      this.disabledmode = false;

      // Let the app keep running by returning an empty result.
      // return of(result as T);
      return null;
    };
  }
}
