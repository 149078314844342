import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
// import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';
import { map, catchError } from 'rxjs/operators';
import { Store } from '../../models/store.model';
// import { BASE_URL } from '../../config/config';
import Swal from 'sweetalert2';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class StoreService {

  API_URL: any = environment.ENDPOINTS;

  store: Store;
  token: string;

  constructor(public http: HttpClient, public router: Router) { }

  cargar(customerId: number) {
    const url = `${this.API_URL.SALES}/customer/${customerId}/stores`; // BASE_URL + '/customer/' + customerId + '/stores';
    return this.http.get(url).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(this.handleError<Store[]>('Búsqueda de Tiendas', [])),
    );
  }

  borrar(customerId: number, storeId: number): any {
    const url = `${this.API_URL.SALES}/customer/${customerId}/store/${storeId}`; // BASE_URL + '/customer/' + customerId + '/store/' + storeId;
    return this.http.delete(url).pipe(
      map(res => {
        // Swal.fire('Borrado!', 'La tienda ha sido borrado.', 'success');
        Swal.fire({
          title: 'Borrado!',
          text: 'La tienda ha sido borrado.',
          type: 'success',
          confirmButtonColor: '#FF0000',
          confirmButtonText: 'Aceptar!',
        });
        return res;
      }),
      catchError(this.handleError<Store[]>('Eliminar Tienda', [])),
    );
  }

  /**
   *
   * @param id
   * @param tienda
   * @param origen 1 = Desde la interfaz de gestión, 2 = Cuando se registra un nuevo Cliente
   */
  guardar(id: number, tienda: Store, origen: number) {
    console.log('StoreService -> guardar -> tienda', tienda);

    let url = `${this.API_URL.SALES}/customer/${id}/store`; // BASE_URL + '/customer/' + id + '/store';
    if (tienda.id > 0) {
      // actualizar

      url += '/' + tienda.id;
      console.log('StoreService -> guardar -> url', url);
      return this.http.put(url, tienda).pipe(
        map((res: any) => {
          if (origen === 1) {
            Swal.fire({
              title: 'Actualización!',
              text: 'La tienda ' + tienda.name + ' ha sido actualizada.',
              type: 'success',
              confirmButtonColor: '#FF0000',
              confirmButtonText: 'Aceptar!',
            });
          }

          return res;
        }),
        catchError(this.handleError<Store[]>('Actualizar Tienda', [])),
      );
    } else {
      // crear

      return this.http.post(url, tienda).pipe(
        map((res: any) => {
          // console.log('StoreService -> guardar -> res', res);
          Swal.fire({
            title: 'Creado!',
            text: 'La tienda ' + tienda.name + ' ha sido creada.',
            type: 'success',
            confirmButtonColor: '#FF0000',
            confirmButtonText: 'Aceptar!',
          });
          return res;
        }),
        catchError(this.handleError<Store[]>('Nueva Tienda', [])),
      );
    }
  }

  obtener(customerId: number, storeId: number) {
    const url = `${this.API_URL.SALES}/customer/${customerId}/store/${storeId}`; // BASE_URL + '/customer/' + customerId + '/store/' + storeId;
    return this.http.get(url).pipe(
      map((res: any) => {
        // console.log('StoreService: obtener: tienda: ', res);
        return res;
      }),
      catchError(this.handleError<Store[]>('Obtener Tienda', [])),
    );
  }

  actualizar(id: number, tienda: Store) {
    const url = `${this.API_URL.SALES}/customer/${id}/store/${tienda.id}`; // BASE_URL + '/customer/' + id + '/store/' + tienda.id;
    return this.http.put(url, tienda).pipe(
      map((res: any) => {
        Swal.fire({
          title: 'Actualización!',
          text: 'La tienda ' + tienda.name + ' ha sido actualizada.',
          type: 'success',
          confirmButtonColor: '#FF0000',
          confirmButtonText: 'Aceptar!',
        });
        return res;
      }),
      catchError(this.handleError<Store[]>('Actualizar Tienda', [])),
    );
  }

  renovarContrasena(customerId: number, storeId: number) {
    const url = `${this.API_URL.SALES}/customer/${customerId}/store/${storeId}/restore-password`; // BASE_URL + '/customer/' + customerId + '/store/' + storeId + '/restore-password';

    return this.http.post(url, null).pipe(
      map((res: any) => {
        // console.log('StoreService -> guardar -> res', res);
        Swal.fire({
          title: 'Renovación de credenciales!',
          html:
            'Se ha enviado un email con la información<br>de sus nuevas <b>credenciales</b>.<br><br>Favor de dar seguimiento',
          type: 'success',
          confirmButtonColor: '#FF0000',
          confirmButtonText: 'Aceptar!',
        });
        return res;
      }),
      catchError(this.handleError<Store[]>('Renovar Credenciales', [])),
    );
  }

  estatusDisponible() {
    const url = `${this.API_URL.SALES}/customer/store/available-status`; // BASE_URL + '/customer/store/available-status';
    return this.http.get(url).pipe(
      map((res: any) => {
        return res;
      }),
    );
  }

  obtenerTienda() {

    const url = `${this.API_URL.SALES}/store`; // BASE_URL + '/store';
    return this.http.get(url).pipe(
      map((res: any) => {
        // console.log('StoreService: obtener: tienda: ', res);
        return res;
      }),
      catchError(this.handleError<Store[]>('Obtener Tienda', []))
    );

  }

  /**
   * Maneja la operación Http que falla.
   * Permite a una operación continuar.
   * @param operation - Nombre de la operación que falló (Texto descriptivo)
   * @param result - Valor opciones que regresa el resultado como un Observable
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      // console.error('ESTE ES EL ERROR: ', error.error.message); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} Falló: ${error}`);
      console.log(`${operation} Falló: ${error.error.message}`);

      Swal.fire({
        title: `${operation} Falló`,
        text: `${error.error.message}`,
        type: 'error',
        confirmButtonColor: '#FF0000',
        confirmButtonText: 'Aceptar!',
      });

      // Let the app keep running by returning an empty result.
      // return of(result as T);
      return null;
    };
  }
}
