import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { UserService } from '../user/user.service';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {

  constructor(
    public service: UserService,
    public router: Router
  ) { }

  canActivate() {// debugger;
    //const user = this._authService.decode();
    // console.log('Login -Guard-, estaLogueado: ', this.service.estaLogueado());

   // console.log(this.state.path);
    if (this.service.estaLogueado()) {
      console.log('LoginGuard -> canActivate -> Usuario firmado');
      return true;
    } else {
      console.log('LoginGuard -> canActivate -> Bloqueado por el GUARD');
      this.router.navigate(['/login']);
      return false;
    }

  }

}
