<!-- <div class="row animated fadeIn">
    <div class="col-sm-12">
        <div class="card">
            <div class="card-body">
                <li class="nav-item hidden-xs-down search-box">
                    <div class="app-search" style="display: block;">
                        <input type="text" class="form-control" placeholder="Buscar" [(ngModel)]="buscarTermino"> <a class="srh-btn"><!--i class="ti-close"></i-></a>
                        <input type="text" class="form-control" placeholder="Nombre de usuario" [(ngModel)]="buscarNombreUsuario"> <a class="srh-btn"><!--i class="ti-close"></i-></a>
                    </div>
                </li>
            </div>
        </div>
    </div>
</div> -->

<!-- ============================================================== -->
<!-- Preloader - style you can find in spinners.css                 -->
<!-- ============================================================== -->
<div class="preloader" *ngIf="cargando">
    <div class="loader">
        <div class="loader__figure"></div>
        <p class="loader__label">Cargando...</p>
    </div>
</div>

<!-- ============================================================== -->
<!-- Search - Can you search by some parameters                     -->
<!-- ============================================================== -->
<div class="row animated fadeIn" *ngIf="!cargando">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Búsqueda de usuarios</h4>
                <!-- <h6 class="card-subtitle">Escriba en cada campo lo que desee &amp; haga clic en <code>Aceptar</code></h6> -->
                <h6 class="card-subtitle">Escriba en cada campo lo que desee <code>Filtrar</code></h6>
                <br>
                <div class="row">
                    <div class="col-md-4">
                        <!-- <h5 class="m-t-30 m-b-10">Username</h5> -->
                        <div class="btn-group bootstrap-select">
                            <input type="text" [(ngModel)]="buscarNombreUsuario" id="username" name="username" class="form-control" placeholder="Username">
                        </div>
                    </div>
                    <div class="col-md-4">
                        <!-- <h5 class="m-t-30 m-b-10">Nombre</h5> -->
                        <div class="btn-group bootstrap-select">
                            <input type="text" [(ngModel)]="buscarNombre" id="name" name="name" class="form-control" placeholder="Nombre">
                        </div>
                    </div>
                    <div class="col-md-4">
                        <!-- <h5 class="m-t-30 m-b-10">Apellido</h5> -->
                        <div class="btn-group bootstrap-select">
                            <input type="text" [(ngModel)]="buscarApellido" id="buscarApellido" name="buscarApellido" class="form-control" placeholder="Apellido">
                        </div>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-md-4">
                        <!-- <h5 class="m-t-30 m-b-10">Apellido</h5> -->
                        <div class="btn-group bootstrap-select">
                            <input type="text" [(ngModel)]="buscarEmail" id="buscarEmail" name="buscarEmail" class="form-control" placeholder="Email">
                        </div>
                    </div>
                    <div class="col-md-4">
                        <!-- <h5 class="m-t-30 m-b-10">Estatus</h5> -->
                        <div class="btn-group bootstrap-select">
                            <select [(ngModel)]="buscarStatus" id="buscarStatus" name="buscarStatus" class="select2 form-control custom-select select2-hidden-accessible" style="width: 100%; height:36px;" tabindex="-1" aria-hidden="true">
                                <!-- <optgroup  label="Elija una opción"> -->
                                <option value="">Estatus</option>
                                <option *ngFor="let status of availableStatus" [value]="status.key.toLowerCase()">
                                    {{ status.name }}</option>
                                <!-- </optgroup> -->
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row animated fadeIn" *ngIf="!cargando">
    <div class="col-12">
        <div class="card">

            <div class="card-body" *ngIf="filteredUsers.length> 0;">
                <div class="text-right" *ngxPermissionsOnly="['USER_CREATE']">
                    <button [routerLink]="['/user', 'new']" type="button" class="btn waves-effect waves-light btn-rounded btn-danger"><i class="fa fa-plus"></i>
                        Nuevo Usuario
                    </button>
                </div>

                <h3 class="card-titil">Usuarios ( <small>{{ filteredUsers.length }}</small> )</h3>

                <table class="table table-hover">
                    <thead>
                        <tr>
                            <!-- <th>Id</th> -->
                            <th>Username</th>
                            <th>Nombre</th>
                            <th>Apellido</th>
                            <th>Correo</th>
                            <th>Role</th>
                            <th>Estatus</th>
                            <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let user of filteredUsers | paginate:pageSize:pageNumber">
                            <!-- <td>{{ user.id }}</td> -->
                            <td>{{ user.username }}</td>
                            <!-- <td>{{ user.name }} {{ user.lastname1 }} {{ user.lastname2 }}</td> -->
                            <td>{{ user.name }}</td>
                            <td>{{ user.lastname1 }}</td>
                            <td>{{ user.email }}</td>
                            <td>{{ user.roleName }}</td>
                            <td>{{ user.statusLabel }}</td>
                            <td>
                                <div *ngxPermissionsOnly="['USER_EDIT']" class="d-inline p-2">
                                    <button [routerLink]="['/user', user.id]" class="btn btn-primary" data-toggle="tooltip" data-placement="top" title="Editar Usuario" data-original-title="Editar Usuario"><i class="fa fa-edit"></i></button>
                                </div>
                                <div *ngxPermissionsOnly="['USER_DELETE']" class="d-inline p-2">
                                    <button (click)="borrar( user )" class="btn btn-danger" data-toggle="tooltip" data-placement="top" title="Eliminar Usuario" data-original-title="Eliminar Usuario"><i class="fa fa-trash-o"></i></button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <mat-paginator showFirstLastButtons="true" [length]="filteredUsers.length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" (page)="handlePage($event)">
                </mat-paginator>
            </div>
        </div>
    </div>
</div>