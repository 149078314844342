import { Component, OnInit } from '@angular/core';
// import { Tdd } from 'src/app/models/tdd.model';
// import { TddService } from 'src/app/services/reports/tdd.service';
import { NgbDate, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { PageEvent } from '@angular/material';
import { Tdd } from '../../models/tdd.model';
import { TddService } from '../../services/reports/tdd.service';
import { NgbDateParserFormatterEsMX } from '../../config/dateformat';

@Component({
  selector: 'jano-tdd',
  templateUrl: './tdd.component.html',
  providers: [
    {provide: NgbDateParserFormatter, useClass: NgbDateParserFormatterEsMX}
  ],
  styles: []
})
export class TddComponent implements OnInit {

  cargando: boolean = true;
  fechaInicial: NgbDate;
  fechaFinal: NgbDate;
  tdd: Tdd; // = new Tdd('', '');
  // tddArr: any[];
  tddArr: Tdd[] = [];

  pageSize: number = 10;
  pageNumber: number = 1;
  pageSizeOptions = [1, 5, 10, 50, 100];

  constructor(
    public service: TddService
  ) {
  }

  ngOnInit() {
    this.cargando = false;
  }

  buscarPorRangoFecha() {
    console.log('buscarPorRangoFecha: fechaInicial: ', this.fechaInicial, ', fechaFinal', this.fechaFinal);

    const fechaInicial = (this.fechaInicial.day <= 9 ? '0' + this.fechaInicial.day : this.fechaInicial.day)
      + '' + (this.fechaInicial.month <= 9 ? '0' + this.fechaInicial.month : this.fechaInicial.month)
      + '' + this.fechaInicial.year;
    const fechaFinal = (this.fechaFinal.day <= 9 ? '0' + this.fechaFinal.day : this.fechaFinal.day)
      + '' + (this.fechaFinal.month <= 9 ? '0' + this.fechaFinal.month : this.fechaFinal.month) + ''
      + this.fechaFinal.year;
    console.log('buscarPorRangoFecha: (F) fechaInicial: ', fechaInicial, ', fechaFinal', fechaFinal);

    this.cargando = true;
    this.service.buscarPorRangoFecha(fechaInicial, fechaFinal).subscribe((res: any) => {
      this.tddArr = res;
      console.log(this.tddArr);
      this.cargando = false;
    });
  }

  descargarReportePorFiltros() {
    // console.log('descargarReportePorFiltros: filtrosTelefonia: ', this.filtrosTelefonia);
    // console.log('descargarReportePorFiltros: fechaInicial: ', this.fechaInicial, ', fechaFinal', this.fechaFinal);

    const fechaInicial =
      (this.fechaInicial.day <= 9
        ? '0' + this.fechaInicial.day
        : this.fechaInicial.day) +
      '' +
      (this.fechaInicial.month <= 9
        ? '0' + this.fechaInicial.month
        : this.fechaInicial.month) +
      '' +
      this.fechaInicial.year;
    // this.filtrosTelefonia.startDate = fechaInicial;

    const fechaFinal =
      (this.fechaFinal.day <= 9
        ? '0' + this.fechaFinal.day
        : this.fechaFinal.day) +
      '' +
      (this.fechaFinal.month <= 9
        ? '0' + this.fechaFinal.month
        : this.fechaFinal.month) +
      '' +
      this.fechaFinal.year;
    // this.filtrosTelefonia.endDate = fechaFinal;

    // console.log('descargarReportePorFiltros: (F) filtrosTelefonia: ', this.filtrosTelefonia);
    // console.log('descargarReportePorFiltros: (F) fechaInicial: ', fechaInicial, ', fechaFinal', fechaFinal);

    this.cargando = true;
    this.service
      .descargarReportePorFiltros(fechaInicial, fechaFinal)
      .subscribe((res: any) => {
        // console.log('descargarReportePorFiltros: id archivo: ', res);

        window.open(res);
        this.cargando = false;
      });
  }

  handlePage(e: PageEvent) {
    this.pageSize = e.pageSize;
    this.pageNumber = e.pageIndex + 1;
  }
}
